/* Dashboard table */
#eodtable thead tr th {
  background: lightgrey;
  padding: 0.5rem;
  vertical-align: middle;
  border-color: inherit;
}

#eodtable tbody tr td {
  background: white;
  padding: 0.6rem;
  border-color: inherit;
}

.ant-calendar-picker-input.ant-input {
  line-height: 1.8;
  height: 39px;
}

.card-wrapper {
  border-radius: 20px !important;
}

.date-wrapper {
  border-style: none;
  width: 95px;
  margin-right: 5px;
}

.btn-primary {
  margin-top: 6px;
}

.excel-download-btn { 
  border: 1px solid green !important;
  background: green !important;
  color: #fff !important;
  width: 14% !important;
  font-size: 15px !important;
  height: 37px !important;
  border-radius: 6px !important;
  float: left !important;
  margin-top: 10px !important;
}

.excel-download-btn:hover {
  border: 1px solid green !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  color: green !important;
}
