@import "https://fonts.googleapis.com/css?family=Mada:400, 500, 600";
@import "../plugins/bootstrap/css/bootstrap.min.css";
@import "feather/css/feather.css";
@import "../plugins/jquery-scrollbar/css/perfect-scrollbar.css";
@import "../fonts/datta/datta-icon.css";
@import "../plugins/multi-select/css/multi-select.css";
@import "../plugins/select2/css/select2.min.css";

body {
  font-family: mada, sans-serif !important;
  font-size: 14px !important;
  color: #686c71 !important;
  font-weight: 400 !important;
  background: #eff3f6 !important;
  position: relative;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: middle !important;
  text-align: center;
  border-top: 1px solid #dee2e6;
}

*:focus {
  outline: none;
}

a:hover {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: #111;
  font-weight: 600;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 26px !important;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  color: #111;
  font-size: 16px;
}

p {
  font-size: 14px;
}

/* b,
strong {
	font-weight: 400;
} */
.page_c {
  width: 950px;
  float: right;
  margin: 66px;
}

.img-radius {
  border-radius: 50%;
  /* height: 40px; */
}

.page-header {
  background: 0 0;
  padding: 0;
}

.page-header h5 {
  margin-bottom: 5px;
  font-weight: 400;
}

.page-header .page-header-title+.breadcrumb {
  background: 0 0;
  padding: 0;
  margin-bottom: 0;
}

.page-header .page-header-title+.breadcrumb>.breadcrumb-item {
  line-height: 1;
}

.page-header .page-header-title+.breadcrumb>.breadcrumb-item a {
  color: #2196f3;
  font-weight: 400;
  font-size: 13px;
  font-style: italic;
}

.page-header .page-header-title+.breadcrumb>.breadcrumb-item:last-child a {
  color: #686c71;
  font-weight: 400;
}

.pcoded-main-container .page-header {
  margin-bottom: 30px;
}

.anim-rotate {
  -webkit-animation: anim-rotate 1s linear infinite;
  animation: anim-rotate 1s linear infinite;
}

@-webkit-keyframes anim-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes anim-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.anim-close-card {
  -webkit-animation: anim-close-card 1.4s linear;
  animation: anim-close-card 1.4s linear;
}

@-webkit-keyframes anim-close-card {
  100% {
    opacity: 0.3;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

p.text-muted {
  font-size: 13px;
}

.card {
  border-radius: 2px;
  box-shadow: 0 0 0 1px #e2e5e8;
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
}

.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #e2e5e8;
  padding: 4px 25px;
  position: relative;
}

.card .card-header h5 {
  margin-bottom: 0;
  color: #111;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}

.card {
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
}

.card .card-header.borderless {
  border-bottom: none;
}

.card .card-header.borderless h5:after {
  display: none;
}

.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  float: right;
  padding: 0;
  position: absolute;
}

.checkboxCol {
  border: 1px solid rgb(206, 212, 218);
  border-radius: 10px;
  margin: 1% 1% 1% 1%;
}

.btn-outline-dark:hover {
  color: #fff !important;
  background-color: #343a40;
  border-color: #343a40;
}

th {
  text-align: center !important;
  vertical-align: middle !important;
}

.complaint-box .rtf-table tbody td {
  text-align: justify !important;
}

.text {
  text-align: justify !important;
}

.inputEgMsg {
  background-color: green;
  color: #ffffff;
  border-radius: 10px;
  padding: 3px;
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}

.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}

.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}

.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: 0 0;
  box-shadow: none;
  color: #888;
}

.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}

.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}

.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: 0 0;
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 8px;
}

.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span {
  background-color: #888;
  height: 2px;
  border-radius: 5px;
}

.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:after,
.card .card-header .card-header-right .btn.dropdown-toggle.mobile-menu span:before {
  border-radius: 5px;
  height: 2px;
  background-color: #888;
}

.card .card-header .card-header-right .nav-pills {
  padding: 0;
  box-shadow: none;
  background: 0 0;
}

.card .card-footer {
  border-top: 1px solid #e2e5e8;
  background: 0 0;
  padding: 20px 25px;
}

.card .card-block,
.card .card-body {
  padding: 20px 25px;
}

.card .card-block-big {
  padding: 30px 25px;
}

.card.card-load {
  position: relative;
  overflow: hidden;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: #2196f3;
  font-size: 24px;
  align-items: center;
  display: flex;
}

.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

.scroll-y {
  z-index: 1027;
}

.dropdown-menu {
  padding: 10px 0;
  font-size: 14px;
}

.dropdown-menu>li {
  padding-left: 15px;
  padding-right: 15px;
}

.dropdown-menu>li>a {
  padding: 5px;
  color: #686c71;
}

.dropdown-menu>li>a i {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.dropdown-menu>li.active,
.dropdown-menu>li:active,
.dropdown-menu>li:focus,
.dropdown-menu>li:hover {
  background: #2196f3;
  color: #fff;
}

.dropdown-menu>li.active>a,
.dropdown-menu>li:active>a,
.dropdown-menu>li:focus>a,
.dropdown-menu>li:hover>a {
  background: 0 0;
  color: #fff;
}

.dropdown-menu>a.active,
.dropdown-menu>a:active,
.dropdown-menu>a:focus,
.dropdown-menu>a:hover {
  background: #2196f3;
  color: #fff;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

.bd-example-row {
  background: #eff3f6;
  padding: 15px;
  margin-bottom: 15px;
}

.bd-example-row .row>[class^="col-"],
.bd-example-row .row>.col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.bd-example-row .row+.row {
  margin-top: 1rem;
}

.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;
}

@media only screen and (max-height: 575px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

.modal {
  z-index: 1072;
}

.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}

.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media only screen and (max-height: 575px) {
  .highlight {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;
}

@media only screen and (max-height: 575px) {
  .bd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}

.highlight pre code {
  font-size: inherit;
  color: #333;
}

.datta-example {
  position: relative;
}

.datta-example .datta-example-btns {
  position: absolute;
  right: 30px;
  top: -10px;
  opacity: 0;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  transition: all 0.3s ease-in-out;
}

.datta-example .datta-example-btns .datta-example-btn {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  background: #2196f3;
  color: #fff;
  padding: 0.1875rem 0.3125rem;
  border-radius: 2px;
  white-space: nowrap;
  font-size: 11px;
  margin: 0 4px;
}

.datta-example .datta-example-btns .datta-example-btn.copy::before {
  content: "COPY";
}

.datta-example .datta-example-btns .datta-example-btn.copied {
  background: #4caf50 !important;
  color: #fff !important;
}

.datta-example .datta-example-btns .datta-example-btn.copied::before {
  content: "COPIED!";
}

.datta-example:hover .datta-example-btns {
  top: -21px;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
}

.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
  overflow: hidden !important;
}

.datta-example-modal-opened .datta-example-modal {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto;
}

.datta-example-modal-content>pre {
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.datta-example-modal-content>pre>code {
  padding: 0;
  background: 0 0;
  font-size: 16px;
}

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: #2196f3;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
}

.md-datta-example-modal-copy:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}

.md-datta-example-modal-copy.copied::before {
  content: "Copied to Clipboard Successfully ! . . .";
  position: absolute;
  display: block;
  right: 100%;
  margin-right: 10px;
  font-size: 14px;
  background: #4caf50;
  line-height: 24px;
  height: 24px;
  border-radius: 3px;
  padding: 0 6px;
  top: 50%;
  margin-top: -12px;
}

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.datta-example-modal-close:hover {
  color: #fff;
  opacity: 0.9;
}

.cui-bottom-spacer {
  height: 12rem;
}

.hljs {
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}

.hljs-link {
  text-decoration: underline;
}

.md-show.md-effect-12~.md-overlay {
  background: #2196f3;
}

.tooltip.in {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px;
}

.bootstrap-tagsinput {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: #f0f3f6;
}

.bootstrap-tagsinput .tag {
  padding: 5px 12px;
  border-radius: 2px;
  line-height: 37px;
  margin-top: 5px;
  margin-right: 5px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-right: -5px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\e8f6";
  padding: 0 2px;
  font-family: feather !important;
}

.ms-container {
  width: 100%;
}

.blockquote {
  border-left: 0.25rem solid #e2e5e8;
  padding: 0.5rem 1rem;
}

.blockquote.text-right {
  border-left: none;
  border-right: 0.25rem solid #e2e5e8;
}

.card .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: courier new, Courier, monospace;
  padding: 50px 0;
}

.ie-warning p {
  font-size: 17px;
}

.ie-warning h1 {
  color: #fff;
}

.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}

.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}

.ie-warning .iew-download>li {
  float: left;
  vertical-align: top;
}

.ie-warning .iew-download>li>a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}

.ie-warning .iew-download>li>a>div {
  margin-top: 10px;
}

.ie-warning .iew-download>li>a:hover {
  background-color: #eee;
}

@font-face {
  font-family: material icons;
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
  font-family: material icons;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

div.code-toolbar>.toolbar button,
div.code-toolbar>.toolbar span,
div.code-toolbar>.toolbar a {
  padding: 3px 0.9em !important;
  background: #04a9f5 !important;
  color: #fff !important;
  box-shadow: none !important;
}

pre[class*="language-"]:after,
pre[class*="language-"]:before {
  display: none;
}

.table-borderless tbody tr td,
.table-borderless tbody tr th {
  border: 0;
}

@media only screen and (max-width: 400px) {

  .lay-customizer .theme-color>a[data-value="reset"],
  .theme-color>a[data-value="reset"] {
    margin-top: 20px;
  }
}

.p-0 {
  padding: 0;
}

.p-t-0 {
  padding-top: 0;
}

.p-b-0 {
  padding-bottom: 0;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.m-0 {
  margin: 0;
}

.m-t-0 {
  margin-top: 0;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-l-0 {
  margin-left: 0;
}

.m-r-0 {
  margin-right: 0;
}

.p-5 {
  padding: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-r-50 {
  margin-right: 50px;
}

.text-c-magenta {
  color: magenta;
}

.text-c-dark-blue {
  color: #0000ff;
}

.bg-c-blue {
  background-color: #2196f3;
}

.text-c-blue {
  color: #2196f3;
}

.b-c-blue {
  border: 1px solid #2196f3;
}

.bg-c-red {
  background-color: #f44336;
}

.text-c-red {
  color: #f44336;
}

.b-c-red {
  border: 1px solid #f44336;
}

.bg-c-green {
  background-color: #4caf50;
}

.text-c-green {
  color: #4caf50;
}

.b-c-green {
  border: 1px solid #4caf50;
}

.bg-c-yellow {
  background-color: #ff9800;
}

.text-c-yellow {
  color: #ff9800;
}

.b-c-yellow {
  border: 1px solid #ff9800;
}

.bg-c-purple {
  background-color: #7759de;
}

.text-c-purple {
  color: #7759de;
}

.b-c-purple {
  border: 1px solid #7759de;
}

.bg-primary {
  background-color: #2196f3 !important;
}

.text-primary {
  color: #2196f3 !important;
}

.b-primary {
  border: 1px solid #2196f3 !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

.text-danger {
  color: #f44336 !important;
}

.b-danger {
  border: 1px solid #f44336 !important;
}

.bg-success {
  background-color: #4caf50 !important;
}

.text-success {
  color: #4caf50 !important;
}

.b-success {
  border: 1px solid #4caf50 !important;
}

.bg-warning {
  background-color: #ff9800 !important;
}

.text-warning {
  color: #ff9800 !important;
}

.b-warning {
  border: 1px solid #ff9800 !important;
}

.bg-info {
  background-color: #00acc1 !important;
}

.text-info {
  color: #00acc1 !important;
}

.b-info {
  border: 1px solid #00acc1 !important;
}

.bg-purple {
  background-color: #7759de !important;
}

.text-purple {
  color: #7759de !important;
}

.b-purple {
  border: 1px solid #7759de !important;
}

.b-primary {
  border: 1px solid #2196f3;
}

.border-bottom-primary td {
  border-bottom: 1px solid #2196f3;
}

.border-bottom-primary th {
  border-bottom: 1px solid #2196f3 !important;
}

.b-danger {
  border: 1px solid #f44336;
}

.border-bottom-danger td {
  border-bottom: 1px solid #f44336;
}

.border-bottom-danger th {
  border-bottom: 1px solid #f44336 !important;
}

.b-success {
  border: 1px solid #4caf50;
}

.border-bottom-success td {
  border-bottom: 1px solid #4caf50;
}

.border-bottom-success th {
  border-bottom: 1px solid #4caf50 !important;
}

.b-warning {
  border: 1px solid #ff9800;
}

.border-bottom-warning td {
  border-bottom: 1px solid #ff9800;
}

.border-bottom-warning th {
  border-bottom: 1px solid #ff9800 !important;
}

.b-info {
  border: 1px solid #00acc1;
}

.border-bottom-info td {
  border-bottom: 1px solid #00acc1;
}

.border-bottom-info th {
  border-bottom: 1px solid #00acc1 !important;
}

.b-purple {
  border: 1px solid #7759de;
}

.border-bottom-purple td {
  border-bottom: 1px solid #7759de;
}

.border-bottom-purple th {
  border-bottom: 1px solid #7759de !important;
}

.card-border-c-blue {
  border-top: 4px solid #2196f3;
}

.card-border-c-red {
  border-top: 4px solid #f44336;
}

.card-border-c-green {
  border-top: 4px solid #4caf50 !important;
}

.card-border-c-yellow {
  border-top: 4px solid #ff9800 !important;
}

.card-border-c-purple {
  border-top: 4px solid #7759de;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

.bg-facebook {
  background: #3c5a99;
}

.text-facebook {
  color: #3c5a99;
}

.bg-twitter {
  background: #42c0fb;
}

.text-twitter {
  color: #42c0fb;
}

.bg-dribbble {
  background: #ec4a89;
}

.text-dribbble {
  color: #ec4a89;
}

.bg-pinterest {
  background: #bf2131;
}

.text-pinterest {
  color: #bf2131;
}

.bg-youtube {
  background: #e0291d;
}

.text-youtube {
  color: #e0291d;
}

.bg-googleplus {
  background: #c73e2e;
}

.text-googleplus {
  color: #c73e2e;
}

.bg-instagram {
  background: #aa7c62;
}

.text-instagram {
  color: #aa7c62;
}

.bg-viber {
  background: #7b519d;
}

.text-viber {
  color: #7b519d;
}

.bg-behance {
  background: #0057ff;
}

.text-behance {
  color: #0057ff;
}

.bg-dropbox {
  background: #3380ff;
}

.text-dropbox {
  color: #3380ff;
}

.bg-linkedin {
  background: #0077b5;
}

.text-linkedin {
  color: #0077b5;
}

.wid-20 {
  width: 20px;
}

.hei-20 {
  height: 20px;
}

.wid-25 {
  width: 25px;
}

.hei-25 {
  height: 25px;
}

.wid-30 {
  width: 30px;
}

.hei-30 {
  height: 30px;
}

.wid-35 {
  width: 35px;
}

.hei-35 {
  height: 35px;
}

.wid-40 {
  width: 40px;
}

.hei-40 {
  height: 40px;
}

.wid-45 {
  width: 45px;
}

.hei-45 {
  height: 45px;
}

.wid-50 {
  width: 50px;
}

.hei-50 {
  height: 50px;
}

.wid-55 {
  width: 55px;
}

.hei-55 {
  height: 55px;
}

.wid-60 {
  width: 60px;
}

.hei-60 {
  height: 60px;
}

.wid-65 {
  width: 65px;
}

.hei-65 {
  height: 65px;
}

.wid-70 {
  width: 70px;
}

.hei-70 {
  height: 70px;
}

.wid-75 {
  width: 75px;
}

.hei-75 {
  height: 75px;
}

.wid-80 {
  width: 80px;
}

.hei-80 {
  height: 80px;
}

.wid-85 {
  width: 85px;
}

.hei-85 {
  height: 85px;
}

.wid-90 {
  width: 90px;
}

.hei-90 {
  height: 90px;
}

.wid-95 {
  width: 95px;
}

.hei-95 {
  height: 95px;
}

.wid-100 {
  width: 100px;
}

.hei-100 {
  height: 100px;
}

.wid-105 {
  width: 105px;
}

.hei-105 {
  height: 105px;
}

.wid-110 {
  width: 110px;
}

.hei-110 {
  height: 110px;
}

.wid-115 {
  width: 115px;
}

.hei-115 {
  height: 115px;
}

.wid-120 {
  width: 120px;
}

.hei-120 {
  height: 120px;
}

.wid-125 {
  width: 125px;
}

.hei-125 {
  height: 125px;
}

.wid-130 {
  width: 130px;
}

.hei-130 {
  height: 130px;
}

.wid-135 {
  width: 135px;
}

.hei-135 {
  height: 135px;
}

.wid-140 {
  width: 140px;
}

.hei-140 {
  height: 140px;
}

.wid-145 {
  width: 145px;
}

.hei-145 {
  height: 145px;
}

.wid-150 {
  width: 150px;
}

.hei-150 {
  height: 150px;
}

.b-wid-1 {
  border-width: 1px;
}

.b-wid-2 {
  border-width: 2px;
}

.b-wid-3 {
  border-width: 3px;
}

.b-wid-4 {
  border-width: 4px;
}

.b-wid-5 {
  border-width: 5px;
}

.b-wid-6 {
  border-width: 6px;
}

.b-wid-7 {
  border-width: 7px;
}

.b-wid-8 {
  border-width: 8px;
}

.b-brand {
  display: flex;
  align-items: center;
}

.b-brand .b-bg {
  background: #2196f3;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
}

.b-brand .b-title {
  margin-left: 10px;
  font-weight: 400;
  color: green;
  font-size: 16px;
}

.navbar-collapsed .b-brand .b-title {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-collapsed .mobile-menu {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-collapsed:hover .b-brand .b-title {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.navbar-collapsed:hover .mobile-menu {
  transition-delay: 0.3s;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  display: none;
}

.loader-bg .loader-track {
  position: relative;
  height: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.loader-bg .loader-track .loader-fill:after,
.loader-bg .loader-track .loader-fill:before {
  content: "";
  background: #2196f3;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
}

.loader-bg .loader-track .loader-fill:before {
  -webkit-animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.loader-bg .loader-track .loader-fill:after {
  -webkit-animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}

@-webkit-keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.pcoded-header {
  z-index: 1028;
  position: relative;
  display: flex;
  min-height: 70px;
  padding: 0;
  top: 0;
  background: #fff;
  color: rgba(36, 46, 62, 0.8);
  width: calc(100% - 264px);
  margin-left: 264px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 0 0 #e2e5e8;
}

.pcoded-header .m-header {
  display: none;
}

.pcoded-header .m-header .logo-dark,
.pcoded-header .m-header .logo-thumb {
  display: none;
}

.pcoded-header .input-group {
  background: 0 0;
}

.pcoded-header .input-group .input-group-text {
  margin-right: 0;
}

.pcoded-header .input-group .input-group-text,
.pcoded-header a,
.pcoded-header dropdown-toggle {
  color: rgba(36, 46, 62, 0.8);
}

.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header dropdown-toggle:hover {
  color: #242e3e;
}

.pcoded-header #mobile-header {
  display: none;
}

.pcoded-header .navbar-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: inline-block;
}

.pcoded-header .navbar-nav>li {
  line-height: 70px;
  display: inline-block;
  padding: 0 12px;
}

.pcoded-header .navbar-nav>li .nav-link {
  padding: 0;
}

.pcoded-header .navbar-nav>li:first-child {
  padding-left: 25px;
}

.pcoded-header .navbar-nav>li:last-child {
  padding-right: 40px;
}

.pcoded-header .mr-auto .dropdown-menu {
  margin-left: -20px;
}

.pcoded-header .ml-auto {
  float: right;
}

.pcoded-header .ml-auto .dropdown-menu {
  margin-right: -20px;
}

.pcoded-header .main-search .input-group {
  border-radius: 20px;
  padding: 0;
}

.pcoded-header .main-search .input-group .form-control,
.pcoded-header .main-search .input-group .input-group-text {
  background: 0 0;
  border: none;
  border-radius: 0;
  padding: 0;
}

.pcoded-header .main-search .input-group .search-close {
  display: none;
}

.pcoded-header .main-search .input-group .search-btn {
  border-radius: 50%;
  padding: 0;
  background: 0 0;
  border-color: transparent;
  box-shadow: none;
}

.pcoded-header .main-search .input-group .form-control {
  transition: all 0.3s ease-in-out;
  width: 0;
  font-size: 14px;
}

.pcoded-header .main-search .input-group .form-control:active,
.pcoded-header .main-search .input-group .form-control:focus,
.pcoded-header .main-search .input-group .form-control:hover,
.pcoded-header .main-search .input-group .search-btn:active,
.pcoded-header .main-search .input-group .search-btn:focus,
.pcoded-header .main-search .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}

.pcoded-header .main-search.open .input-group {
  background: #fff;
  border: 1px solid #e2e5e8;
  padding: 5px 5px 5px 20px;
}

.pcoded-header .main-search.open .input-group .search-close {
  display: flex;
}

.pcoded-header .main-search.open .input-group .search-btn {
  padding: 5px 7px;
  margin-left: 5px;
  background: #2196f3;
  border-color: #2196f3;
}

.pcoded-header .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header .main-search.open .input-group .form-control {
  transition: all 0.3s ease-in-out;
  width: 0;
}

.pcoded-header .main-search.open .input-group .form-control:active,
.pcoded-header .main-search.open .input-group .form-control:focus,
.pcoded-header .main-search.open .input-group .form-control:hover,
.pcoded-header .main-search.open .input-group .search-btn:active,
.pcoded-header .main-search.open .input-group .search-btn:focus,
.pcoded-header .main-search.open .input-group .search-btn:hover {
  outline: none;
  box-shadow: none;
}

.pcoded-header .dropdown .dropdown-toggle {
  line-height: 70px;
  display: inline-block;
  padding-right: 15px;
}

.pcoded-header .dropdown .dropdown-toggle:after {
  content: "\e842";
  font-family: feather;
  font-size: 15px;
  border: none;
  position: absolute;
  top: 0;
  left: 12px;
}

.pcoded-header .dropdown .dropdown-menu {
  position: absolute;
  min-width: 290px;
}

.pcoded-header .dropdown .dropdown-menu li {
  line-height: 1.2;
}

.pcoded-header .dropdown .dropdown-menu li a {
  padding: 10px;
  font-size: 14px;
}

.pcoded-header .dropdown .dropdown-menu li.active a,
.pcoded-header .dropdown .dropdown-menu li:active a,
.pcoded-header .dropdown .dropdown-menu li:focus a,
.pcoded-header .dropdown .dropdown-menu li:hover a {
  color: #686c71;
}

.pcoded-header .dropdown .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}

.pcoded-header .dropdown .notification a {
  color: #686c71;
}

.pcoded-header .dropdown .notification a:hover {
  color: #111;
}

.pcoded-header .dropdown .notification .noti-head {
  background: #242e3e;
  padding: 15px 20px;
}

.pcoded-header .dropdown .notification .noti-head h6 {
  color: #fff;
}

.pcoded-header .dropdown .notification .noti-head a {
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
}

.pcoded-header .dropdown .notification .noti-body {
  padding: 0;
  position: relative;
  height: 300px;
  overflow: scroll;
  overflow-x: hidden;
}

.pcoded-header .dropdown .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}

.pcoded-header .dropdown .notification .noti-body li {
  padding: 0px 20px;
  transition: all 0.3s ease-in-out;
}

.pcoded-header .dropdown .notification .noti-body li.n-title {
  padding-bottom: 0;
}

.pcoded-header .dropdown .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}

.pcoded-header .dropdown .notification .noti-body li.notification:hover {
  background: rgba(33, 150, 243, 0.1);
}

.pcoded-header .dropdown .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}

.pcoded-header .dropdown .notification .noti-body li p strong {
  color: #222;
}

.pcoded-header .dropdown .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}

.pcoded-header .dropdown .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
  background: #eff3f6;
}

.pcoded-header .dropdown .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}

.pcoded-header .dropdown .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.pcoded-header .dropdown .notification ul li {
  padding: 20px 15px;
}

.pcoded-header .dropdown .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}

.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #2196f3;
}

.pcoded-header .dropdown .profile-notification .pro-head img {
  width: 40px;
  margin-right: 10px;
}

.pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pcoded-header .dropdown .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}

.pcoded-header .dropdown .profile-notification .pro-body li a {
  color: #686c71;
  font-size: 14px;
  padding: 10px 20px;
}

.pcoded-header .dropdown .profile-notification .pro-body li a i {
  margin-right: 10px;
}

.pcoded-header .dropdown .profile-notification .pro-body li.active,
.pcoded-header .dropdown .profile-notification .pro-body li:active,
.pcoded-header .dropdown .profile-notification .pro-body li:focus,
.pcoded-header .dropdown .profile-notification .pro-body li:hover {
  background: rgba(33, 150, 243, 0.1);
}

.pcoded-header .dropdown .profile-notification .pro-body li.active>a,
.pcoded-header .dropdown .profile-notification .pro-body li:active>a,
.pcoded-header .dropdown .profile-notification .pro-body li:focus>a,
.pcoded-header .dropdown .profile-notification .pro-body li:hover>a {
  background: 0 0;
}

.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;
}

.mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

.mobile-menu span:after,
.mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

.mobile-menu span:after {
  top: 5px;
  width: 70%;
}

.mobile-menu span:before {
  top: -5px;
  width: 40%;
}

.mobile-menu:hover span {
  background-color: #fff;
}

.mobile-menu:hover span:after,
.mobile-menu:hover span:before {
  background-color: #fff;
}

.mobile-menu.on span {
  background-color: transparent;
}

.mobile-menu.on span:after,
.mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}

.mobile-menu.on span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
  transform: rotate(45deg) translate(4px, 4px);
}

.mobile-menu.on span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
  transform: rotate(-45deg) translate(3px, -3px);
}

.navbar-brand {
  background: #2196f3;
}

.pcoded-header:before,
.pcoded-main-container:before {
  content: "";
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar {
  display: block;
  z-index: 1029;
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 264px;
  height: 100%;
  top: 0;
  background: #242e3e;
  color: #b5bdca;
}

.pcoded-navbar ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.pcoded-navbar .scroll-div.navbar-content {
  height: calc(100% - 70px);
}

.pcoded-navbar .header-logo {
  position: relative;
  align-items: center;
  display: inline-flex;
  float: left;
  background:burlywood;
  height: 70px;
  text-align: center;
  width: 264px;
  margin-right: 0;
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 0 0 rgba(181, 189, 202, 0.2),
    7px 0 15px 0 rgba(69, 90, 100, 0.09);
}

.pcoded-navbar .header-logo .logo-dark {
  display: none;
}

.pcoded-navbar .header-logo .logo-thumb {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  opacity: 0;
  position: absolute;
  transition: unset;
}

.pcoded-navbar .header-logo+.scroll-div {
  float: left;
  display: inline-block;
}

.pcoded-navbar .navbar-content {
  box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
}

.pcoded-navbar .mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar .pcoded-badge {
  font-size: 75%;
  position: relative;
  right: 0;
  top: -1px;
  padding: 2px 7px;
  border-radius: 2px;
  display: inline-block;
  margin-left: 10px;
}

.pcoded-navbar .pcoded-inner-navbar {
  flex-direction: column;
}

.pcoded-navbar .pcoded-inner-navbar li {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li>a {
  text-align: left;
  padding: 7px 10px 7px 20px;
  margin: 5px 0 0;
  display: block;
  border-radius: 0;
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li>a .pcoded-mtext {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li>a>.pcoded-micon {
  font-size: 14px;
  padding: 4px 7px;
  margin-right: 2px;
  border-radius: 4px;
  width: 30px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  text-align: center;
}

.pcoded-navbar .pcoded-inner-navbar li>a>.pcoded-micon+.pcoded-mtext {
  position: relative;
  top: 0;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu>a:after {
  content: "\e844";
  font-family: feather;
  font-size: 13px;
  border: none;
  position: absolute;
  top: 13px;
  right: 20px;
  transition: 0.3s ease-in-out;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger>a:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  /* display: none */
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu a:after {
  top: 7px;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active>.pcoded-submenu {
  display: block;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active>a {
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a {
  text-align: left;
  padding: 7px 7px 7px 60px;
  margin: 0;
  display: block;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 35px;
  width: 5px;
  height: 5px;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu>li>a {
  padding: 7px 7px 7px 80px;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu>li>a:before {
  left: 55px;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 11px;
  font-weight: 400;
  padding: 25px 15px 15px 28px;
  text-transform: capitalize;
  position: relative;
}

.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption>label {
  margin-bottom: 0;
}

.pcoded-navbar .pcoded-inner-navbar li.disabled>a {
  cursor: default;
  opacity: 0.5;
}

.pcoded-navbar .pcoded-submenu {
  background: #202938;
  padding: 15px 0;
}

.pcoded-navbar a {
  color: mintcream;
}

.pcoded-navbar .navbar-content,
.pcoded-navbar .navbar-wrapper {
  width: 100%;
  height: 100%;
}

.pcoded-navbar.navbar-collapsed {
  width: 70px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed .header-logo {
  width: 70px;
  padding: 10px 20px;
}

.pcoded-navbar.navbar-collapsed .header-logo img {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: unset;
}

.pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 1;
  left: calc((70px / 2) - 20px);
}

.pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
}

.pcoded-navbar.navbar-collapsed .navbar-content.ps {
  overflow: visible;
}

.pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}

.pcoded-navbar.navbar-collapsed .pcoded-menu-caption>label {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 32px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(181, 189, 202, 0.5);
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li>a {
  padding: 7px 20px;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li>a>.pcoded-micon+.pcoded-mtext {
  position: absolute;
  top: 11px;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li>a>.pcoded-mtext {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-hasmenu>a:after {
  right: 12px;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu:after {
  content: "";
  position: absolute;
  top: 50px;
  left: calc(calc(70px / 2) + 2px);
  width: 1px;
  height: calc(100% - 50px);
  background: rgba(181, 189, 202, 0.2);
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu li a {
  color: transparent;
  white-space: nowrap;
}

.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 0;
}

.pcoded-navbar.navbar-collapsed~.pcoded-header {
  width: calc(100% - 70px);
}

.pcoded-navbar.navbar-collapsed~.pcoded-header,
.pcoded-navbar.navbar-collapsed~.pcoded-main-container {
  margin-left: 70px;
}

.pcoded-navbar.navbar-collapsed .pcoded-badge {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
  display: none;
  transition: all 0.15s ease-in-out;
}

.pcoded-navbar.navbar-collapsed:hover {
  width: 264px !important;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo {
  width: 264px;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo img {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo .logo-thumb {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  opacity: 0;
}

.pcoded-navbar.navbar-collapsed:hover .header-logo .mobile-menu {
  display: flex;
  right: 10px;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption>label {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: 0 0;
}

.pcoded-navbar.navbar-collapsed:hover .mobile-menu {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a>.pcoded-mtext {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a>.pcoded-mtext:after {
  opacity: 1;
  visibility: visible;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-hasmenu>a:after {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>a:after {
  -webkit-transform: rotateX(0deg) rotate(90deg);
  transform: rotateX(0deg) rotate(90deg);
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu:after {
  display: none;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu li a {
  color: inherit;
}

.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 1;
}

.pcoded-navbar .pcoded-menu-caption {
  color: #8090a5;
}

.pcoded-navbar .pcoded-inner-navbar li>a.active,
.pcoded-navbar .pcoded-inner-navbar li:focus>a,
.pcoded-navbar .pcoded-inner-navbar li:hover>a {
  color: #2196f3;
  text-decoration: none;
}

.pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  /* background: #2196f3 */
}

.pcoded-navbar .pcoded-inner-navbar>li.active:after,
.pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger:after {
  content: "";
  background-color: #2196f3;
  z-index: 1027;
  position: absolute;
  left: 0;
  top: 5px;
  width: 3px;
  height: calc(100% - 5px);
}

.pcoded-navbar .pcoded-inner-navbar>li.active>a,
.pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #1d2431;
  color: #fff;
}

.pcoded-navbar .pcoded-inner-navbar>.pcoded-menu-caption.active:after,
.pcoded-navbar .pcoded-inner-navbar>.pcoded-menu-caption.pcoded-trigger:after {
  display: none;
}

.pcoded-navbar.mob-open~.pcoded-header:before,
.pcoded-navbar.mob-open~.pcoded-main-container:before,
.pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
.pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navbar-content {
  position: relative;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: 0 0;
}

.ps__thumb-y {
  background: #aec2d1;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y:hover>.ps__thumb-y {
  background: #beceda;
}

.pcoded-navbar.theme-horizontal {
  display: block;
  height: 70px;
  width: 100%;
  z-index: 1023;
  box-shadow: none;
  position: fixed;
  top: 70px;
}

.pcoded-navbar.theme-horizontal .pcoded-badge {
  display: none;
}

.pcoded-navbar.theme-horizontal.top-nav-collapse {
  top: 0;
}

.pcoded-navbar.theme-horizontal.default,
.pcoded-navbar.theme-horizontal.default.top-nav-collapse {
  top: 70px;
}

.pcoded-navbar.theme-horizontal.header-hide {
  top: 0;
}

.pcoded-navbar.theme-horizontal.header-hide.top-nav-collapse {
  top: -70px;
}

.pcoded-navbar.theme-horizontal.header-hide~.pcoded-main-container {
  margin-top: 70px;
}

.pcoded-navbar.theme-horizontal .header-logo {
  display: none;
}

.pcoded-navbar.theme-horizontal .sidenav-horizontal-wrapper {
  display: flex;
  align-items: center;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-menu-caption {
  display: none;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li {
  display: inline-block;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li>a {
  margin: 0;
  padding: 8px 15px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li>a>.pcoded-mtext {
  position: relative;
  top: 0;
  margin-right: 5px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li>a:after {
  position: relative;
  top: 0;
  right: 0;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.active a,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger a,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li:hover a {
  box-shadow: none;
  color: #fff;
  background: 0 0;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  content: "\6d";
  position: absolute;
  bottom: -34px;
  left: 0;
  font-family: pct;
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  padding-left: calc(50% - 25px);
  color: #242e3e;
  text-shadow: 0 3px 4px rgba(69, 90, 100, 0.05);
  width: 100%;
  height: 40px;
  -webkit-transform: scaleX(1.2);
  transform: scaleX(1.2);
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active>.pcoded-submenu,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
  margin-top: 30px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger:after {
  content: "";
  background-color: #2196f3;
  z-index: 1027;
  position: absolute;
  left: 19px;
  top: auto;
  bottom: 5px;
  width: 23px;
  height: 2px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.edge>.pcoded-submenu {
  left: auto;
  right: 0;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu {
  position: relative;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  position: absolute;
  min-width: 250px;
  display: block;
  z-index: 1;
  top: 100%;
  list-style: outside none none;
  margin: 0;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  background: #fff;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu a {
  color: #242e3e;
  padding: 10px 20px 10px 30px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu a:before {
  left: 5px;
  top: 19px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li.active>a,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li.pcoded-trigger>a,
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li:hover>a {
  color: #2196f3;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu {
  position: absolute;
  min-width: 250px;
  z-index: 1;
  left: calc(100% + 10px);
  top: -10px;
  margin: 0 0 0 20px;
  border-radius: 4px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu:before {
  content: "\6a";
  position: absolute;
  top: 8px;
  left: -31px;
  font-family: pct;
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  color: #fff;
  width: 40px;
  height: 100%;
  text-shadow: -8px 0 13px rgba(62, 57, 107, 0.08);
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu a {
  color: #242e3e;
  padding: 10px 20px 10px 30px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu a:before {
  left: 5px;
  top: 19px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu.edge .pcoded-submenu {
  left: auto;
  margin: 0 20px 0 0;
  right: calc(100% + 10px);
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu.edge .pcoded-submenu:before {
  content: "\6b";
  left: auto;
  right: -21px;
  text-shadow: 8px 0 13px rgba(62, 57, 107, 0.08);
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.pcoded-navbar.theme-horizontal .navbar-content {
  display: flex;
}

.pcoded-navbar.theme-horizontal~.pcoded-header {
  position: fixed;
  top: 0;
  margin-left: 0;
  width: 100%;
}

.pcoded-navbar.theme-horizontal~.pcoded-header .b-title {
  color: #242e3e;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .b-title {
  color: #fff;
}

.pcoded-navbar.theme-horizontal~.pcoded-header .m-header {
  display: inline-flex;
  padding: 0 15px;
}

.pcoded-navbar.theme-horizontal~.pcoded-header .m-header .logo-main,
.pcoded-navbar.theme-horizontal~.pcoded-header .m-header .logo-thumb {
  display: none;
}

.pcoded-navbar.theme-horizontal~.pcoded-header .m-header .logo-dark {
  display: inline-block;
}

.pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu {
  display: none;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .m-header {
  display: inline-flex;
  padding: 0 15px;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .m-header .logo-dark,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .m-header .logo-thumb {
  display: none;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .m-header .logo-main {
  display: inline-block;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu {
  color: #686c71;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu a {
  color: #686c71;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li>a {
  color: #686c71;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li.active,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li:active,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li:focus,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li:hover {
  background: rgba(33, 150, 243, 0.1);
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li.active>a,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li:active>a,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li:focus>a,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown-menu>li:hover>a {
  background: 0 0;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] a,
.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown .notification .noti-body li.notification:hover {
  background: rgba(33, 150, 243, 0.1);
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #2196f3;
}

.pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}

.pcoded-navbar.theme-horizontal~.pcoded-main-container {
  margin-top: 140px;
  margin-left: 0;
}

.pcoded-content {
  position: relative;
  display: block;
  padding: 25px;
}

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  padding-top: 0;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  top: 0;
  margin-left: 264px;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .pcoded-header {
    background: #242e3e;
    color: #fff;
  }

  .pcoded-header .input-group-text,
  .pcoded-header a,
  .pcoded-header dropdown-toggle {
    color: #242e3e;
  }

  .pcoded-header .input-group-text:hover,
  .pcoded-header a:hover,
  .pcoded-header dropdown-toggle:hover {
    color: #2196f3;
  }

  .pcoded-header #mobile-header {
    display: none;
    right: 20px;
  }

  .pcoded-header>.collapse:not(.show),
  .pcoded-header .container>.collapse:not(.show) {
    display: flex;
    background: #fff;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    position: relative;
  }

  .pcoded-header>.collapse:not(.show) .mob-toggler:after,
  .pcoded-header .container>.collapse:not(.show) .mob-toggler:after {
    content: "\e89a";
    font-family: feather;
    font-size: 20px;
    color: #242e3e;
    position: absolute;
    right: 10px;
    top: 0;
    width: 40px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pcoded-header>.collapse:not(.show) .mr-auto,
  .pcoded-header .container>.collapse:not(.show) .mr-auto {
    height: 70px;
    display: flex;
    justify-content: center;
  }

  .pcoded-header>.collapse:not(.show) .ml-auto,
  .pcoded-header .container>.collapse:not(.show) .ml-auto {
    margin-left: auto;
    margin-right: 0 !important;
    display: none;
  }

  .pcoded-header .dropdown-menu,
  .pcoded-header .dropdown-menu a,
  .pcoded-header .search-close .input-group-text {
    color: #242e3e;
  }

  .pcoded-header .m-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 70px;
  }

  .pcoded-header .m-header .b-brand {
    margin-left: 20px;
  }

  .pcoded-header .m-header .mobile-menu {
    right: 20px;
  }

  .pcoded-header .full-screen {
    display: none;
  }

  .pcoded-header .navbar-nav>li:last-child {
    padding-right: 60px;
  }

  .pcoded-navbar {
    margin-left: -264px;
    position: absolute;
    height: 100%;
    box-shadow: none;
  }

  .pcoded-navbar .scroll-div.navbar-content {
    height: 100%;
  }

  .pcoded-navbar~.pcoded-header,
  .pcoded-navbar~.pcoded-main-container {
    margin-left: 0;
  }

  .pcoded-navbar~.pcoded-header {
    width: 100%;
  }

  .pcoded-navbar .navbar-brand {
    display: none;
  }

  .pcoded-navbar.mob-open {
    margin-left: 0;
  }

  .pcoded-navbar.mob-open .pcoded-main-container~.pcoded-header,
  .pcoded-navbar.mob-open~.pcoded-main-container {
    margin-left: 0;
  }

  .pcoded-main-container {
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .pcoded-header .main-search.open .input-group .search-close {
    display: none;
  }

  .pcoded-header .navbar-nav>li {
    padding: 0 8px;
  }

  .pcoded-header .navbar-nav.ml-auto>li:first-child {
    padding-left: 0;
  }

  .pcoded-header .navbar-nav>li:last-child {
    padding-right: 50px;
  }

  .pcoded-header .navbar-nav.mr-auto li.nav-item:not(.dropdown) {
    display: block;
    padding-right: 0;
  }

  .pcoded-header .navbar-nav.mr-auto li.nav-item:not(.dropdown) .search-close {
    display: none;
  }

  .pcoded-header .dropdown.show {
    position: static;
  }

  .pcoded-header .dropdown.show a:after {
    display: none;
  }

  .pcoded-header .dropdown.show:before {
    display: none;
  }

  .pcoded-header .dropdown .notification {
    width: 100%;
  }

  .pcoded-header .dropdown .dropdown-menu {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .header-chat.open,
  .header-user-list.open {
    width: 280px;
  }

  .pcoded-content {
    padding: 30px 15px;
  }

  .card {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .pcoded-navbar .header-logo {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed .header-logo img {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-menu-caption>label {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li>a>.pcoded-mtext {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-hasmenu>a:after {
    transition: none;
    transition-delay: 0;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-badge {
    transition: none;
  }

  .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
    transition-delay: 0;
  }
}

@media only screen and (max-width: 991px) {
  .pcoded-navbar.theme-horizontal {
    margin-left: 0;
  }

  .pcoded-navbar.theme-horizontal~.pcoded-header>.collapse:not(.show),
  .pcoded-navbar.theme-horizontal~.pcoded-header .container>.collapse:not(.show) {
    display: inline-flex;
    background: #fff;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  }

  .pcoded-navbar.theme-horizontal~.pcoded-header .b-title {
    color: #fff;
  }

  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu .pcoded-trigger>.pcoded-submenu,
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu .pcoded-trigger.edge>.pcoded-submenu {
    position: relative;
    left: 0;
    min-width: 100%;
    margin: 0;
    box-shadow: none;
  }

  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu .pcoded-trigger>.pcoded-submenu:before,
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu .pcoded-trigger.edge>.pcoded-submenu:before {
    display: none;
  }
}

.theme-horizontal .sidenav-horizontal-wrapper {
  flex: 1 1 auto;
  width: 0;
}

.sidenav:not(.sidenav-no-animation) .theme-horizontal .sidenav-horizontal-wrapper .sidenav-inner {
  transition: margin 0.2s;
}

.theme-horizontal .sidenav-horizontal-next,
.theme-horizontal .sidenav-horizontal-prev {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 2.25rem;
  background-color: #242e3e;
  z-index: 9;
}

.theme-horizontal .sidenav-horizontal-next::after,
.theme-horizontal .sidenav-horizontal-prev::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-top: 0;
}

.theme-horizontal .sidenav-horizontal-next.disabled,
.theme-horizontal .sidenav-horizontal-prev.disabled {
  cursor: default !important;
  opacity: 0;
}

.theme-horizontal .sidenav-horizontal-prev::after {
  border-right: 0;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.theme-horizontal .sidenav-horizontal-next::after {
  border-left: 0;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.theme-horizontal .sidenav-horizontal:after,
.theme-horizontal .sidenav-horizontal:before {
  content: "";
  background: #242e3e;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 5;
}

@media only screen and (max-width: 991px) {

  .theme-horizontal .sidenav-horizontal:after,
  .theme-horizontal .sidenav-horizontal:before {
    display: none;
  }
}

.theme-horizontal .sidenav-horizontal:before {
  left: 100%;
}

.theme-horizontal .sidenav-horizontal:after {
  right: 100%;
}

.theme-horizontal.menu-light .sidenav-horizontal:after,
.theme-horizontal.menu-light .sidenav-horizontal:before {
  background: #fff;
}

@-webkit-keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.menu-styler {
  z-index: 1029;
  position: relative;
  color: #686c71;
}

.menu-styler h5,
.menu-styler h6 {
  color: #111;
  font-weight: 400;
}

.menu-styler hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-styler .style-toggler>a {
  position: fixed;
  right: 0;
  top: 320px;
  transition: 0.3s ease-in-out;
}

.menu-styler .style-toggler>a:before {
  content: "";
  width: 45px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1001;
  background: #f44336;
  transition: 0.3s ease-in-out;
  border-radius: 3px 0 0 3px;
  box-shadow: 0 0 8px rgba(244, 67, 54, 0.9), 6px 0 8px rgba(0, 0, 0, 0.1);
}

.menu-styler .style-toggler>a:after {
  content: "\e849";
  top: 12px;
  font-size: 21px;
  position: absolute;
  right: 11px;
  font-family: feather;
  z-index: 1029;
  line-height: 1;
  color: #fff;
  transition: 0.3s ease-in-out;
}

@media all and (-ms-high-contrast: none) {
  .menu-styler .style-toggler>a:after {
    line-height: 0.5;
  }
}

.menu-styler .prebuild-toggler>.prebuild-group {
  position: fixed;
  transition: 0.3s ease-in-out;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: -100px;
  top: 485px;
}

.menu-styler .prebuild-toggler>.prebuild-group>a {
  color: #7759de;
  background: #fff;
  border: 1px solid #7759de;
  box-shadow: 0 0 2px rgba(119, 89, 222, 0.9), 6px 0 8px rgba(0, 0, 0, 0.1);
  padding: 13px 20px;
}

.menu-styler .prebuild-toggler>.prebuild-group>a:first-child {
  border-radius: 0 0 0 3px;
}

.menu-styler .prebuild-toggler>.prebuild-group>a:last-child {
  border-radius: 0 0 3px 0;
}

.menu-styler .prebuild-toggler>.prebuild-group>a.active {
  color: #fff;
  background: #7759de;
}

.menu-styler.open .style-toggler>a,
.menu-styler.prebuild-open .style-toggler>a {
  right: 400px;
  -webkit-animation: sh-shake 0s ease infinite;
  animation: sh-shake 0s ease infinite;
}

.menu-styler.open .style-toggler>a:after,
.menu-styler.prebuild-open .style-toggler>a:after {
  content: "\e847";
  -webkit-animation: anim-rotate 0s linear infinite;
  animation: anim-rotate 0s linear infinite;
}

.menu-styler.open .prebuild-toggler>.prebuild-group,
.menu-styler.prebuild-open .prebuild-toggler>.prebuild-group {
  right: calc(400px - 100px);
}

.menu-styler.open .style-block {
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.menu-styler.prebuild-open .style-block-prebuild {
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.menu-styler .style-block,
.menu-styler .style-block-prebuild {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: #fff;
  z-index: 1030;
  transition: 0.3s ease-in-out;
  padding: 25px 20px 20px;
}

.menu-styler .mst-scroll {
  height: calc(100vh - 175px);
  background-color: rgba(33, 150, 243, 0.03);
  padding: 0 15px;
}

.menu-styler label {
  margin-bottom: 3px;
}

.menu-styler .switch input[type="checkbox"]+.cr {
  top: 8px;
}

.menu-styler .radio input[type="radio"]+.cr {
  margin-right: 10px;
}

.menu-styler .radio input[type="radio"]+.cr:after,
.menu-styler .radio input[type="radio"]+.cr:before {
  margin-right: 3px;
}

.menu-styler h6 {
  position: relative;
  margin: 10px 0 15px;
}

.menu-styler h6:after {
  content: "";
  background-color: #2196f3;
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 40px;
  height: 2px;
}

.menu-styler .nav-pills {
  padding: 0;
  box-shadow: none;
}

.menu-styler .nav-pills li {
  width: calc(100% / 3);
}

.menu-styler .tab-content {
  padding: 15px;
  box-shadow: none;
  background-color: rgba(33, 150, 243, 0.03);
  border-top: 2px solid #2196f3;
}

.menu-styler .layout-block {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #e2e5e8;
  border-radius: 10px 10px 0 0;
  margin-bottom: 30px;
  border: 3px solid #5d5d5d;
  overflow: visible;
}

.menu-styler .layout-block .card-img-overlay {
  background: rgba(36, 46, 62, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.menu-styler .layout-block .card-img-overlay h5 {
  color: #fff;
}

.menu-styler .layout-block:hover .card-img-overlay {
  opacity: 1;
}

.menu-styler .layout-block img {
  border-radius: 7px 7px 0 0;
}

.menu-styler .layout-block:after,
.menu-styler .layout-block:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -25%;
  width: 50%;
}

.menu-styler .layout-block:after {
  height: 11px;
  z-index: 1;
  background: linear-gradient(to bottom, #283244, #55688a, #283244, #283244);
  border-radius: 0 0 25px 25px;
  -webkit-transform: scaleX(2.4);
  transform: scaleX(2.4);
}

.menu-styler .layout-block:before {
  height: 10px;
  z-index: 2;
  margin-top: 1px;
  border-radius: 0 0 15px 15px;
  -webkit-transform: scale(0.4, 0.3) translatey(-8px);
  transform: scale(0.4, 0.3) translatey(-8px);
  background: #9db6c8;
}

.menu-styler .layout-block .card-img-overlay {
  background: 0 0;
  opacity: 1;
  border-radius: 6px 6px 0 0;
  box-shadow: inset 3px 3px 8px 0 rgba(255, 255, 255, 0.2),
    inset 2px 2px 7px 0 rgba(255, 255, 255, 0.64), inset 0 -8px 0 0 #2e3a4e;
}

.menu-styler .layout-block .card-img-overlay h5 {
  background: #2196f3;
  padding: 5px 12px;
  border-radius: 3px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.menu-styler .layout-block .card-img-overlay:before {
  content: "";
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  border-bottom: 8px solid #555555c7;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transform: scalex(1.2);
  transform: scalex(1.2);
}

.menu-styler .layout-block:hover .card-img-overlay h5 {
  background: #f44336;
}

.menu-styler .prelayout-color .col-12 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

@media only screen and (max-width: 992px) {
  .menu-styler {
    display: none;
  }
}

@-webkit-keyframes sh-shake {

  0%,
  100%,
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  15%,
  3%,
  9% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  12%,
  20%,
  6% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes sh-shake {

  0%,
  100%,
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  15%,
  3%,
  9% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  12%,
  20%,
  6% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.lay-customizer .theme-color,
.theme-color {
  display: block;
  position: relative;
  padding: 5px 5px 0;
  margin-bottom: 10px;
}

.lay-customizer .theme-color>a,
.theme-color>a {
  position: relative;
  width: 45px;
  height: 30px;
  border-radius: 0;
  display: inline-block;
  margin-right: 8px;
  background: #eff3f6;
  overflow: hidden;
  box-shadow: 0 0 0 1px #e2e5e8;
}

.lay-customizer .theme-color>a span,
.theme-color>a span {
  width: 100%;
  position: absolute;
}

.lay-customizer .theme-color>a span:after,
.lay-customizer .theme-color>a span:before,
.theme-color>a span:after,
.theme-color>a span:before {
  content: "";
  height: 100%;
  position: absolute;
}

.lay-customizer .theme-color>a span:before,
.theme-color>a span:before {
  width: 30%;
  left: 0;
  background: #242e3e;
}

.lay-customizer .theme-color>a span:after,
.theme-color>a span:after {
  width: 70%;
  right: 0;
  background: #eff3f6;
}

.lay-customizer .theme-color>a>span:nth-child(1),
.theme-color>a>span:nth-child(1) {
  height: 30%;
  top: 0;
}

.lay-customizer .theme-color>a>span:nth-child(1):after,
.theme-color>a>span:nth-child(1):after {
  background: #fff;
}

.lay-customizer .theme-color>a>span:nth-child(2),
.theme-color>a>span:nth-child(2) {
  height: 75%;
  bottom: 0;
}

.lay-customizer .theme-color>a.active,
.theme-color>a.active {
  box-shadow: 0 0 0 3px #2196f3;
}

.lay-customizer .theme-color>a.active:before,
.theme-color>a.active:before {
  content: "\e8dc";
  top: -1px;
  font-size: 20px;
  position: absolute;
  left: 12px;
  font-family: feather;
  z-index: 1001;
  color: #2196f3;
  transition: 0.3s ease-in-out;
  text-shadow: 0 1px 3px rgba(33, 150, 243, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1);
}

.lay-customizer .theme-color>a.active:after,
.theme-color>a.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}

.lay-customizer .theme-color>a[data-value="menu-light"] span:before,
.theme-color>a[data-value="menu-light"] span:before {
  background: #fff;
}

.lay-customizer .theme-color>a[data-value="dark"] span:after,
.theme-color>a[data-value="dark"] span:after {
  background: #080a0e;
}

.lay-customizer .theme-color>a[data-value="reset"],
.theme-color>a[data-value="reset"] {
  background: #f44336;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  width: 135px;
  box-shadow: none;
}

.lay-customizer .theme-color.small>a,
.theme-color.small>a {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.lay-customizer .theme-color.small>a:before,
.theme-color.small>a:before {
  font-size: 15px;
  left: 5px;
  top: 1px;
}

.lay-customizer .theme-color.small>a>span,
.theme-color.small>a>span {
  display: none;
}

.lay-customizer .theme-color.header-color>a[data-value="header-blue"]>span:nth-child(1):after,
.theme-color.header-color>a[data-value="header-blue"]>span:nth-child(1):after {
  background: #2196f3;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-blue"],
.theme-color.header-color.small>a[data-value="header-blue"] {
  background: #2196f3;
}

.lay-customizer .theme-color.header-color>a[data-value="header-red"]>span:nth-child(1):after,
.theme-color.header-color>a[data-value="header-red"]>span:nth-child(1):after {
  background: #f44336;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-red"],
.theme-color.header-color.small>a[data-value="header-red"] {
  background: #f44336;
}

.lay-customizer .theme-color.header-color>a[data-value="header-purple"]>span:nth-child(1):after,
.theme-color.header-color>a[data-value="header-purple"]>span:nth-child(1):after {
  background: #7759de;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-purple"],
.theme-color.header-color.small>a[data-value="header-purple"] {
  background: #7759de;
}

.lay-customizer .theme-color.header-color>a[data-value="header-info"]>span:nth-child(1):after,
.theme-color.header-color>a[data-value="header-info"]>span:nth-child(1):after {
  background: #00acc1;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-info"],
.theme-color.header-color.small>a[data-value="header-info"] {
  background: #00acc1;
}

.lay-customizer .theme-color.header-color>a[data-value="header-dark"]>span:nth-child(1):after,
.theme-color.header-color>a[data-value="header-dark"]>span:nth-child(1):after {
  background: #242e3e;
}

.lay-customizer .theme-color.header-color.small>a[data-value="header-dark"],
.theme-color.header-color.small>a[data-value="header-dark"] {
  background: #242e3e;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-blue"]>span:nth-child(2):before,
.theme-color.navbar-color>a[data-value="navbar-blue"]>span:nth-child(2):before {
  background: #2196f3;
}

.lay-customizer .theme-color.navbar-color.small>a[data-value="navbar-blue"],
.theme-color.navbar-color.small>a[data-value="navbar-blue"] {
  background: #2196f3;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-red"]>span:nth-child(2):before,
.theme-color.navbar-color>a[data-value="navbar-red"]>span:nth-child(2):before {
  background: #f44336;
}

.lay-customizer .theme-color.navbar-color.small>a[data-value="navbar-red"],
.theme-color.navbar-color.small>a[data-value="navbar-red"] {
  background: #f44336;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-purple"]>span:nth-child(2):before,
.theme-color.navbar-color>a[data-value="navbar-purple"]>span:nth-child(2):before {
  background: #7759de;
}

.lay-customizer .theme-color.navbar-color.small>a[data-value="navbar-purple"],
.theme-color.navbar-color.small>a[data-value="navbar-purple"] {
  background: #7759de;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-info"]>span:nth-child(2):before,
.theme-color.navbar-color>a[data-value="navbar-info"]>span:nth-child(2):before {
  background: #00acc1;
}

.lay-customizer .theme-color.navbar-color.small>a[data-value="navbar-info"],
.theme-color.navbar-color.small>a[data-value="navbar-info"] {
  background: #00acc1;
}

.lay-customizer .theme-color.navbar-color>a[data-value="navbar-dark"]>span:nth-child(2):before,
.theme-color.navbar-color>a[data-value="navbar-dark"]>span:nth-child(2):before {
  background: #242e3e;
}

.lay-customizer .theme-color.navbar-color.small>a[data-value="navbar-dark"],
.theme-color.navbar-color.small>a[data-value="navbar-dark"] {
  background: #242e3e;
}

.lay-customizer .theme-color.navbar-gradient-color>a[data-value="navbar-gradient-blue"]>span:nth-child(2):before,
.theme-color.navbar-gradient-color>a[data-value="navbar-gradient-blue"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

.lay-customizer .theme-color.navbar-gradient-color>a[data-value="navbar-gradient-red"]>span:nth-child(2):before,
.theme-color.navbar-gradient-color>a[data-value="navbar-gradient-red"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

.lay-customizer .theme-color.navbar-gradient-color>a[data-value="navbar-gradient-purple"]>span:nth-child(2):before,
.theme-color.navbar-gradient-color>a[data-value="navbar-gradient-purple"]>span:nth-child(2):before {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

.lay-customizer .theme-color.navbar-gradient-color>a[data-value="navbar-gradient-info"]>span:nth-child(2):before,
.theme-color.navbar-gradient-color>a[data-value="navbar-gradient-info"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

.lay-customizer .theme-color.navbar-gradient-color>a[data-value="navbar-gradient-dark"]>span:nth-child(2):before,
.theme-color.navbar-gradient-color>a[data-value="navbar-gradient-dark"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

.lay-customizer .theme-color.brand-color>a[data-value="brand-blue"]>span:nth-child(1):before,
.theme-color.brand-color>a[data-value="brand-blue"]>span:nth-child(1):before {
  background: #2196f3;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-blue"],
.theme-color.brand-color.small>a[data-value="brand-blue"] {
  background: #2196f3;
}

.lay-customizer .theme-color.brand-color>a[data-value="brand-red"]>span:nth-child(1):before,
.theme-color.brand-color>a[data-value="brand-red"]>span:nth-child(1):before {
  background: #f44336;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-red"],
.theme-color.brand-color.small>a[data-value="brand-red"] {
  background: #f44336;
}

.lay-customizer .theme-color.brand-color>a[data-value="brand-purple"]>span:nth-child(1):before,
.theme-color.brand-color>a[data-value="brand-purple"]>span:nth-child(1):before {
  background: #7759de;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-purple"],
.theme-color.brand-color.small>a[data-value="brand-purple"] {
  background: #7759de;
}

.lay-customizer .theme-color.brand-color>a[data-value="brand-info"]>span:nth-child(1):before,
.theme-color.brand-color>a[data-value="brand-info"]>span:nth-child(1):before {
  background: #00acc1;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-info"],
.theme-color.brand-color.small>a[data-value="brand-info"] {
  background: #00acc1;
}

.lay-customizer .theme-color.brand-color>a[data-value="brand-dark"]>span:nth-child(1):before,
.theme-color.brand-color>a[data-value="brand-dark"]>span:nth-child(1):before {
  background: #242e3e;
}

.lay-customizer .theme-color.brand-color.small>a[data-value="brand-dark"],
.theme-color.brand-color.small>a[data-value="brand-dark"] {
  background: #242e3e;
}

.lay-customizer .theme-color.active-color>a[data-value="active-default"],
.theme-color.active-color>a[data-value="active-default"] {
  background: #2196f3;
}

.lay-customizer .theme-color.active-color>a[data-value="active-blue"],
.theme-color.active-color>a[data-value="active-blue"] {
  background: #2196f3;
}

.lay-customizer .theme-color.active-color>a[data-value="active-red"],
.theme-color.active-color>a[data-value="active-red"] {
  background: #f44336;
}

.lay-customizer .theme-color.active-color>a[data-value="active-purple"],
.theme-color.active-color>a[data-value="active-purple"] {
  background: #7759de;
}

.lay-customizer .theme-color.active-color>a[data-value="active-info"],
.theme-color.active-color>a[data-value="active-info"] {
  background: #00acc1;
}

.lay-customizer .theme-color.active-color>a[data-value="active-dark"],
.theme-color.active-color>a[data-value="active-dark"] {
  background: #263238;
}

.lay-customizer .theme-color.title-color>a[data-value="title-default"],
.theme-color.title-color>a[data-value="title-default"] {
  background: #fff;
}

.lay-customizer .theme-color.title-color>a[data-value="title-blue"],
.theme-color.title-color>a[data-value="title-blue"] {
  background: #2196f3;
}

.lay-customizer .theme-color.title-color>a[data-value="title-red"],
.theme-color.title-color>a[data-value="title-red"] {
  background: #f44336;
}

.lay-customizer .theme-color.title-color>a[data-value="title-purple"],
.theme-color.title-color>a[data-value="title-purple"] {
  background: #7759de;
}

.lay-customizer .theme-color.title-color>a[data-value="title-info"],
.theme-color.title-color>a[data-value="title-info"] {
  background: #00acc1;
}

.lay-customizer .theme-color.title-color>a[data-value="title-dark"],
.theme-color.title-color>a[data-value="title-dark"] {
  background: #263238;
}

.lay-customizer .theme-color.navbar-images>a,
.theme-color.navbar-images>a {
  height: 90px;
  background-size: cover;
  background-position: center center;
}

.lay-customizer .theme-color.navbar-images>a span,
.theme-color.navbar-images>a span {
  display: none;
}

.lay-customizer .theme-color.navbar-images>a.active:before,
.theme-color.navbar-images>a.active:before {
  top: 35px;
  content: "\e87c";
}

.lay-customizer .theme-color.navbar-images>a[data-value="navbar-image-1"],
.theme-color.navbar-images>a[data-value="navbar-image-1"] {
  background-image: url(../images/nav-bg/navbar-img-1.jpg);
}

.lay-customizer .theme-color.navbar-images>a[data-value="navbar-image-2"],
.theme-color.navbar-images>a[data-value="navbar-image-2"] {
  background-image: url(../images/nav-bg/navbar-img-2.jpg);
}

.lay-customizer .theme-color.navbar-images>a[data-value="navbar-image-3"],
.theme-color.navbar-images>a[data-value="navbar-image-3"] {
  background-image: url(../images/nav-bg/navbar-img-3.jpg);
}

.lay-customizer .theme-color.navbar-images>a[data-value="navbar-image-4"],
.theme-color.navbar-images>a[data-value="navbar-image-4"] {
  background-image: url(../images/nav-bg/navbar-img-4.jpg);
}

.lay-customizer .theme-color.navbar-images>a[data-value="navbar-image-5"],
.theme-color.navbar-images>a[data-value="navbar-image-5"] {
  background-image: url(../images/nav-bg/navbar-img-5.jpg);
}

.lay-customizer .theme-color.navbar-pattern>a,
.theme-color.navbar-pattern>a {
  background-size: auto;
}

.lay-customizer .theme-color.navbar-pattern>a span,
.theme-color.navbar-pattern>a span {
  display: none;
}

.lay-customizer .theme-color.navbar-pattern>a[data-value="navbar-pattern-1"],
.theme-color.navbar-pattern>a[data-value="navbar-pattern-1"] {
  background-image: url(../images/bg-images/1.jpg);
}

.lay-customizer .theme-color.navbar-pattern>a[data-value="navbar-pattern-2"],
.theme-color.navbar-pattern>a[data-value="navbar-pattern-2"] {
  background-image: url(../images/bg-images/2.jpg);
}

.lay-customizer .theme-color.navbar-pattern>a[data-value="navbar-pattern-3"],
.theme-color.navbar-pattern>a[data-value="navbar-pattern-3"] {
  background-image: url(../images/bg-images/3.jpg);
}

.lay-customizer .theme-color.navbar-pattern>a[data-value="navbar-pattern-4"],
.theme-color.navbar-pattern>a[data-value="navbar-pattern-4"] {
  background-image: url(../images/bg-images/4.jpg);
}

.lay-customizer .theme-color.navbar-pattern>a[data-value="navbar-pattern-5"],
.theme-color.navbar-pattern>a[data-value="navbar-pattern-5"] {
  background-image: url(../images/bg-images/5.jpg);
}

.lay-customizer .theme-color.navbar-pattern>a[data-value="navbar-pattern-6"],
.theme-color.navbar-pattern>a[data-value="navbar-pattern-6"] {
  background-image: url(../images/bg-images/6.jpg);
}

.lay-customizer .theme-color.bg-images>a,
.theme-color.bg-images>a {
  width: 95px;
  height: 80px;
  border-radius: 2px;
  background-size: cover;
  background-position: center center;
}

.lay-customizer .theme-color.bg-images>a span,
.theme-color.bg-images>a span {
  display: none;
}

.lay-customizer .theme-color.bg-images>a.active:before,
.theme-color.bg-images>a.active:before {
  top: 35px;
  content: "\e87c";
}

.pcoded-navbar.menu-light {
  background-color: #fff;
  color: #242e3e;
}

.pcoded-navbar.menu-light .header-logo {
  background-color: #242e3e;
}

.pcoded-navbar.menu-light .header-logo .logo-dark {
  display: none;
}

.pcoded-navbar.menu-light .header-logo .logo-main {
  display: block;
}

.pcoded-navbar.menu-light .sidenav-horizontal-next,
.pcoded-navbar.menu-light .sidenav-horizontal-prev {
  background: #fff;
}

.pcoded-navbar.menu-light .mobile-menu span {
  background-color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.menu-light .mobile-menu span:after,
.pcoded-navbar.menu-light .mobile-menu span:before {
  background-color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.menu-light .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.menu-light .pcoded-submenu {
  background: #fbfbfb;
}

.pcoded-navbar.menu-light a {
  color: #242e3e;
}

.pcoded-navbar.menu-light.navbar-collapsed .pcoded-menu-caption:after {
  background: rgba(0, 0, 0, 0.1);
}

.pcoded-navbar.menu-light.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: 0 0;
}

.pcoded-navbar.menu-light .pcoded-menu-caption {
  color: #242e3e;
}

.pcoded-navbar.menu-light .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #f1f1f1;
  color: #111;
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.active a,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger a,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li:hover a {
  color: #242e3e;
  background: 0 0;
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: #fff;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(1)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(2)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(3)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(4)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(5)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(6)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(7)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(8)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(9)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(10)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(11)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(12)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(13)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(14)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(15)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(16)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(17)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(18)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(19)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(20)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(21)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(22)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(23)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(24)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(25)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(26)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(27)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(28)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(29)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(30)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(31)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(32)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(33)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(34)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(35)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(36)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(37)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(38)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(39)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(40)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(41)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(42)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(43)>a>.pcoded-micon {
  color: #2196f3;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(44)>a>.pcoded-micon {
  color: #f44336;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(45)>a>.pcoded-micon {
  color: #01a9ac;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(46)>a>.pcoded-micon {
  color: #7759de;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(47)>a>.pcoded-micon {
  color: #00acc1;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li:nth-child(48)>a>.pcoded-micon {
  color: #ff9800;
}

.pcoded-navbar.icon-colored .pcoded-inner-navbar>li.pcoded-trigger>a>.pcoded-micon,
.pcoded-navbar.icon-colored .pcoded-inner-navbar>li.active>a>.pcoded-micon {
  color: #fff;
}

.pcoded-navbar.icon-colored.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a>.pcoded-micon,
.pcoded-navbar.icon-colored.menu-light .pcoded-inner-navbar>li.active>a>.pcoded-micon {
  color: #242e3e;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(1)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(2)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(3)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(4)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(5)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(6)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(7)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(8)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(9)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(10)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(11)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(12)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(13)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(14)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(15)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(16)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(17)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(18)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(19)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(20)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(21)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(22)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(23)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(24)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(25)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(26)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(27)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(28)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(29)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(30)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(31)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(32)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(33)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(34)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(35)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(36)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(37)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(38)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(39)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(40)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(41)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(42)>a {
  color: #ff9800;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(43)>a {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(44)>a {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(45)>a {
  color: #01a9ac;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(46)>a {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(47)>a {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.icon-colored .sidemenu>li:nth-child(48)>a {
  color: #ff9800;
}

.theme-horizontal~.pcoded-header {
  box-shadow: 0 1px 20px 0 rgba(63, 77, 103, 0.15);
}

@media only screen and (max-width: 991px) {
  .theme-horizontal~.pcoded-header {
    background: #242e3e;
  }

  .theme-horizontal~.pcoded-header .horizontal-dasktop {
    display: none;
  }

  .theme-horizontal~.pcoded-header .horizontal-mobile {
    display: inline-block;
  }
}

@media only screen and (max-width: 992px) {
  .theme-horizontal-dis~.pcoded-header .horizontal-dasktop {
    display: none;
  }

  .theme-horizontal-dis~.pcoded-header .horizontal-mobile {
    display: inline-block;
  }
}

.pcoded-header[class*="header-"] {
  box-shadow: 0 3px 10px 0 rgba(65, 72, 78, 0.02);
}

@media only screen and (min-width: 992px) {
  .pcoded-header[class*="header-"] .collapse:not(.show) {
    background: inherit;
  }

  .pcoded-header[class*="header-"] .page-header .breadcrumb-item+.breadcrumb-item::before,
  .pcoded-header[class*="header-"] .page-header .page-header-title+.breadcrumb>.breadcrumb-item a,
  .pcoded-header[class*="header-"] .page-header h5 {
    color: #fff;
  }
}

.pcoded-header.header-blue {
  background: #2196f3;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-blue .profile-notification li>a {
  color: #686c71;
}

.pcoded-header.header-blue .profile-notification li.active,
.pcoded-header.header-blue .profile-notification li:active,
.pcoded-header.header-blue .profile-notification li:focus,
.pcoded-header.header-blue .profile-notification li:hover {
  background: rgba(33, 150, 243, 0.1);
}

.pcoded-header.header-blue .profile-notification li.active>a,
.pcoded-header.header-blue .profile-notification li:active>a,
.pcoded-header.header-blue .profile-notification li:focus>a,
.pcoded-header.header-blue .profile-notification li:hover>a {
  background: 0 0;
}

.pcoded-header.header-blue .dropdown-menu {
  color: #686c71;
}

.pcoded-header.header-blue .dropdown-menu a {
  color: #686c71;
}

.pcoded-header.header-blue .dropdown-menu>li>a {
  color: #686c71;
}

.pcoded-header.header-blue .dropdown-menu>li.active,
.pcoded-header.header-blue .dropdown-menu>li:active,
.pcoded-header.header-blue .dropdown-menu>li:focus,
.pcoded-header.header-blue .dropdown-menu>li:hover {
  background: rgba(33, 150, 243, 0.1);
  color: #686c71;
}

.pcoded-header.header-blue .dropdown-menu>li.active>a,
.pcoded-header.header-blue .dropdown-menu>li:active>a,
.pcoded-header.header-blue .dropdown-menu>li:focus>a,
.pcoded-header.header-blue .dropdown-menu>li:hover>a {
  background: 0 0;
}

.pcoded-header.header-blue .input-group .input-group-text,
.pcoded-header.header-blue a,
.pcoded-header.header-blue dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-blue .input-group .input-group-text:hover,
.pcoded-header.header-blue a:hover,
.pcoded-header.header-blue dropdown-toggle:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {

  .pcoded-header.header-blue .input-group .input-group-text,
  .pcoded-header.header-blue a,
  .pcoded-header.header-blue dropdown-toggle {
    color: #242e3e;
  }

  .pcoded-header.header-blue .input-group .input-group-text:hover,
  .pcoded-header.header-blue a:hover,
  .pcoded-header.header-blue dropdown-toggle:hover {
    color: #11161e;
  }
}

.pcoded-header.header-blue .main-search .search-close>.input-group-text {
  color: #2196f3;
}

.pcoded-header.header-blue .main-search.open .input-group .search-btn {
  background: #2196f3;
  border-color: #2196f3;
}

.pcoded-header.header-blue .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header.header-blue .dropdown .notification .noti-head a {
  color: #fff;
}

.pcoded-header.header-blue .dropdown .notification .noti-body li.notification:hover {
  background: rgba(33, 150, 243, 0.1);
}

.pcoded-header.header-blue .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #2196f3;
}

.pcoded-header.header-blue .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}

.pcoded-header.header-blue .b-bg {
  background: #fff;
  color: #2196f3;
}

.pcoded-header.header-red {
  background: #f44336;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-red .profile-notification li>a {
  color: #686c71;
}

.pcoded-header.header-red .profile-notification li.active,
.pcoded-header.header-red .profile-notification li:active,
.pcoded-header.header-red .profile-notification li:focus,
.pcoded-header.header-red .profile-notification li:hover {
  background: rgba(244, 67, 54, 0.1);
}

.pcoded-header.header-red .profile-notification li.active>a,
.pcoded-header.header-red .profile-notification li:active>a,
.pcoded-header.header-red .profile-notification li:focus>a,
.pcoded-header.header-red .profile-notification li:hover>a {
  background: 0 0;
}

.pcoded-header.header-red .dropdown-menu {
  color: #686c71;
}

.pcoded-header.header-red .dropdown-menu a {
  color: #686c71;
}

.pcoded-header.header-red .dropdown-menu>li>a {
  color: #686c71;
}

.pcoded-header.header-red .dropdown-menu>li.active,
.pcoded-header.header-red .dropdown-menu>li:active,
.pcoded-header.header-red .dropdown-menu>li:focus,
.pcoded-header.header-red .dropdown-menu>li:hover {
  background: rgba(244, 67, 54, 0.1);
  color: #686c71;
}

.pcoded-header.header-red .dropdown-menu>li.active>a,
.pcoded-header.header-red .dropdown-menu>li:active>a,
.pcoded-header.header-red .dropdown-menu>li:focus>a,
.pcoded-header.header-red .dropdown-menu>li:hover>a {
  background: 0 0;
}

.pcoded-header.header-red .input-group .input-group-text,
.pcoded-header.header-red a,
.pcoded-header.header-red dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-red .input-group .input-group-text:hover,
.pcoded-header.header-red a:hover,
.pcoded-header.header-red dropdown-toggle:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {

  .pcoded-header.header-red .input-group .input-group-text,
  .pcoded-header.header-red a,
  .pcoded-header.header-red dropdown-toggle {
    color: #242e3e;
  }

  .pcoded-header.header-red .input-group .input-group-text:hover,
  .pcoded-header.header-red a:hover,
  .pcoded-header.header-red dropdown-toggle:hover {
    color: #11161e;
  }
}

.pcoded-header.header-red .main-search .search-close>.input-group-text {
  color: #f44336;
}

.pcoded-header.header-red .main-search.open .input-group .search-btn {
  background: #f44336;
  border-color: #f44336;
}

.pcoded-header.header-red .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header.header-red .dropdown .notification .noti-head a {
  color: #fff;
}

.pcoded-header.header-red .dropdown .notification .noti-body li.notification:hover {
  background: rgba(244, 67, 54, 0.1);
}

.pcoded-header.header-red .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #f44336;
}

.pcoded-header.header-red .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}

.pcoded-header.header-red .b-bg {
  background: #fff;
  color: #f44336;
}

.pcoded-header.header-purple {
  background: #7759de;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-purple .profile-notification li>a {
  color: #686c71;
}

.pcoded-header.header-purple .profile-notification li.active,
.pcoded-header.header-purple .profile-notification li:active,
.pcoded-header.header-purple .profile-notification li:focus,
.pcoded-header.header-purple .profile-notification li:hover {
  background: rgba(119, 89, 222, 0.1);
}

.pcoded-header.header-purple .profile-notification li.active>a,
.pcoded-header.header-purple .profile-notification li:active>a,
.pcoded-header.header-purple .profile-notification li:focus>a,
.pcoded-header.header-purple .profile-notification li:hover>a {
  background: 0 0;
}

.pcoded-header.header-purple .dropdown-menu {
  color: #686c71;
}

.pcoded-header.header-purple .dropdown-menu a {
  color: #686c71;
}

.pcoded-header.header-purple .dropdown-menu>li>a {
  color: #686c71;
}

.pcoded-header.header-purple .dropdown-menu>li.active,
.pcoded-header.header-purple .dropdown-menu>li:active,
.pcoded-header.header-purple .dropdown-menu>li:focus,
.pcoded-header.header-purple .dropdown-menu>li:hover {
  background: rgba(119, 89, 222, 0.1);
  color: #686c71;
}

.pcoded-header.header-purple .dropdown-menu>li.active>a,
.pcoded-header.header-purple .dropdown-menu>li:active>a,
.pcoded-header.header-purple .dropdown-menu>li:focus>a,
.pcoded-header.header-purple .dropdown-menu>li:hover>a {
  background: 0 0;
}

.pcoded-header.header-purple .input-group .input-group-text,
.pcoded-header.header-purple a,
.pcoded-header.header-purple dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-purple .input-group .input-group-text:hover,
.pcoded-header.header-purple a:hover,
.pcoded-header.header-purple dropdown-toggle:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {

  .pcoded-header.header-purple .input-group .input-group-text,
  .pcoded-header.header-purple a,
  .pcoded-header.header-purple dropdown-toggle {
    color: #242e3e;
  }

  .pcoded-header.header-purple .input-group .input-group-text:hover,
  .pcoded-header.header-purple a:hover,
  .pcoded-header.header-purple dropdown-toggle:hover {
    color: #11161e;
  }
}

.pcoded-header.header-purple .main-search .search-close>.input-group-text {
  color: #7759de;
}

.pcoded-header.header-purple .main-search.open .input-group .search-btn {
  background: #7759de;
  border-color: #7759de;
}

.pcoded-header.header-purple .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header.header-purple .dropdown .notification .noti-head a {
  color: #fff;
}

.pcoded-header.header-purple .dropdown .notification .noti-body li.notification:hover {
  background: rgba(119, 89, 222, 0.1);
}

.pcoded-header.header-purple .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #7759de;
}

.pcoded-header.header-purple .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}

.pcoded-header.header-purple .b-bg {
  background: #fff;
  color: #7759de;
}

.pcoded-header.header-info {
  background: #00acc1;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-info .profile-notification li>a {
  color: #686c71;
}

.pcoded-header.header-info .profile-notification li.active,
.pcoded-header.header-info .profile-notification li:active,
.pcoded-header.header-info .profile-notification li:focus,
.pcoded-header.header-info .profile-notification li:hover {
  background: rgba(0, 172, 193, 0.1);
}

.pcoded-header.header-info .profile-notification li.active>a,
.pcoded-header.header-info .profile-notification li:active>a,
.pcoded-header.header-info .profile-notification li:focus>a,
.pcoded-header.header-info .profile-notification li:hover>a {
  background: 0 0;
}

.pcoded-header.header-info .dropdown-menu {
  color: #686c71;
}

.pcoded-header.header-info .dropdown-menu a {
  color: #686c71;
}

.pcoded-header.header-info .dropdown-menu>li>a {
  color: #686c71;
}

.pcoded-header.header-info .dropdown-menu>li.active,
.pcoded-header.header-info .dropdown-menu>li:active,
.pcoded-header.header-info .dropdown-menu>li:focus,
.pcoded-header.header-info .dropdown-menu>li:hover {
  background: rgba(0, 172, 193, 0.1);
  color: #686c71;
}

.pcoded-header.header-info .dropdown-menu>li.active>a,
.pcoded-header.header-info .dropdown-menu>li:active>a,
.pcoded-header.header-info .dropdown-menu>li:focus>a,
.pcoded-header.header-info .dropdown-menu>li:hover>a {
  background: 0 0;
}

.pcoded-header.header-info .input-group .input-group-text,
.pcoded-header.header-info a,
.pcoded-header.header-info dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-info .input-group .input-group-text:hover,
.pcoded-header.header-info a:hover,
.pcoded-header.header-info dropdown-toggle:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {

  .pcoded-header.header-info .input-group .input-group-text,
  .pcoded-header.header-info a,
  .pcoded-header.header-info dropdown-toggle {
    color: #242e3e;
  }

  .pcoded-header.header-info .input-group .input-group-text:hover,
  .pcoded-header.header-info a:hover,
  .pcoded-header.header-info dropdown-toggle:hover {
    color: #11161e;
  }
}

.pcoded-header.header-info .main-search .search-close>.input-group-text {
  color: #00acc1;
}

.pcoded-header.header-info .main-search.open .input-group .search-btn {
  background: #00acc1;
  border-color: #00acc1;
}

.pcoded-header.header-info .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header.header-info .dropdown .notification .noti-head a {
  color: #fff;
}

.pcoded-header.header-info .dropdown .notification .noti-body li.notification:hover {
  background: rgba(0, 172, 193, 0.1);
}

.pcoded-header.header-info .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #00acc1;
}

.pcoded-header.header-info .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}

.pcoded-header.header-info .b-bg {
  background: #fff;
  color: #00acc1;
}

.pcoded-header.header-dark {
  background: #242e3e;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-dark .profile-notification li>a {
  color: #686c71;
}

.pcoded-header.header-dark .profile-notification li.active,
.pcoded-header.header-dark .profile-notification li:active,
.pcoded-header.header-dark .profile-notification li:focus,
.pcoded-header.header-dark .profile-notification li:hover {
  background: rgba(36, 46, 62, 0.1);
}

.pcoded-header.header-dark .profile-notification li.active>a,
.pcoded-header.header-dark .profile-notification li:active>a,
.pcoded-header.header-dark .profile-notification li:focus>a,
.pcoded-header.header-dark .profile-notification li:hover>a {
  background: 0 0;
}

.pcoded-header.header-dark .dropdown-menu {
  color: #686c71;
}

.pcoded-header.header-dark .dropdown-menu a {
  color: #686c71;
}

.pcoded-header.header-dark .dropdown-menu>li>a {
  color: #686c71;
}

.pcoded-header.header-dark .dropdown-menu>li.active,
.pcoded-header.header-dark .dropdown-menu>li:active,
.pcoded-header.header-dark .dropdown-menu>li:focus,
.pcoded-header.header-dark .dropdown-menu>li:hover {
  background: rgba(36, 46, 62, 0.1);
  color: #686c71;
}

.pcoded-header.header-dark .dropdown-menu>li.active>a,
.pcoded-header.header-dark .dropdown-menu>li:active>a,
.pcoded-header.header-dark .dropdown-menu>li:focus>a,
.pcoded-header.header-dark .dropdown-menu>li:hover>a {
  background: 0 0;
}

.pcoded-header.header-dark .input-group .input-group-text,
.pcoded-header.header-dark a,
.pcoded-header.header-dark dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-header.header-dark .input-group .input-group-text:hover,
.pcoded-header.header-dark a:hover,
.pcoded-header.header-dark dropdown-toggle:hover {
  color: #fff;
}

@media only screen and (max-width: 991px) {

  .pcoded-header.header-dark .input-group .input-group-text,
  .pcoded-header.header-dark a,
  .pcoded-header.header-dark dropdown-toggle {
    color: #242e3e;
  }

  .pcoded-header.header-dark .input-group .input-group-text:hover,
  .pcoded-header.header-dark a:hover,
  .pcoded-header.header-dark dropdown-toggle:hover {
    color: #11161e;
  }
}

.pcoded-header.header-dark .main-search .search-close>.input-group-text {
  color: #242e3e;
}

.pcoded-header.header-dark .main-search.open .input-group .search-btn {
  background: #242e3e;
  border-color: #242e3e;
}

.pcoded-header.header-dark .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}

.pcoded-header.header-dark .dropdown .notification .noti-head a {
  color: #fff;
}

.pcoded-header.header-dark .dropdown .notification .noti-body li.notification:hover {
  background: rgba(36, 46, 62, 0.1);
}

.pcoded-header.header-dark .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #242e3e;
}

.pcoded-header.header-dark .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}

.pcoded-header.header-dark .b-bg {
  background: #fff;
  color: #242e3e;
}

.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span,
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:after,
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:before {
  background: #fff;
}

.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu.on span {
  background: 0 0;
}

.pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg {
  background: #fff;
  color: #2196f3;
}

.pcoded-navbar.brand-blue .header-logo,
.pcoded-navbar[class*="navbar-"].brand-blue .header-logo {
  background: #2196f3;
}

.pcoded-navbar.brand-blue .header-logo .b-bg,
.pcoded-navbar[class*="navbar-"].brand-blue .header-logo .b-bg {
  background: #fff;
  color: #2196f3;
}

.pcoded-navbar.brand-red .header-logo,
.pcoded-navbar[class*="navbar-"].brand-red .header-logo {
  background: #f44336;
}

.pcoded-navbar.brand-red .header-logo .b-bg,
.pcoded-navbar[class*="navbar-"].brand-red .header-logo .b-bg {
  background: #fff;
  color: #f44336;
}

.pcoded-navbar.brand-purple .header-logo,
.pcoded-navbar[class*="navbar-"].brand-purple .header-logo {
  background: #7759de;
}

.pcoded-navbar.brand-purple .header-logo .b-bg,
.pcoded-navbar[class*="navbar-"].brand-purple .header-logo .b-bg {
  background: #fff;
  color: #7759de;
}

.pcoded-navbar.brand-info .header-logo,
.pcoded-navbar[class*="navbar-"].brand-info .header-logo {
  background: #00acc1;
}

.pcoded-navbar.brand-info .header-logo .b-bg,
.pcoded-navbar[class*="navbar-"].brand-info .header-logo .b-bg {
  background: #fff;
  color: #00acc1;
}

.pcoded-navbar.brand-dark .header-logo,
.pcoded-navbar[class*="navbar-"].brand-dark .header-logo {
  background: #242e3e;
}

.pcoded-navbar.brand-dark .header-logo .b-bg,
.pcoded-navbar[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: #2196f3;
  color: #fff;
}

.pcoded-navbar.brand-default .header-logo,
.pcoded-navbar[class*="navbar-"].brand-default .header-logo {
  background: #242e3e;
}

.pcoded-navbar.navbar-blue {
  background: #2196f3;
}

.pcoded-navbar.navbar-blue .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-blue .mobile-menu span:after,
.pcoded-navbar.navbar-blue .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-blue .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-blue .header-logo .b-bg {
  background: #2196f3;
}

.pcoded-navbar.navbar-blue .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-blue .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-blue .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-blue .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-blue .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-blue .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-blue .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-blue .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-blue .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-prev {
  background: #2196f3;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal:before {
  background: #2196f3;
}

.pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar>li>a {
  background: 0 0;
}

.pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar>li:after {
  background: rgba(255, 255, 255, 0.7);
}

.pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: #2196f3;
}

.pcoded-navbar.navbar-red {
  background: #f44336;
}

.pcoded-navbar.navbar-red .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-red .mobile-menu span:after,
.pcoded-navbar.navbar-red .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-red .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-red .header-logo .b-bg {
  background: #f44336;
}

.pcoded-navbar.navbar-red .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-red .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-red .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-red .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-red .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-red .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-red .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-red .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-red .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-red .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-red .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-prev {
  background: #f44336;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal:before {
  background: #f44336;
}

.pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar>li>a {
  background: 0 0;
}

.pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar>li:after {
  background: rgba(255, 255, 255, 0.7);
}

.pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: #f44336;
}

.pcoded-navbar.navbar-purple {
  background: #7759de;
}

.pcoded-navbar.navbar-purple .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-purple .mobile-menu span:after,
.pcoded-navbar.navbar-purple .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-purple .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-purple .header-logo .b-bg {
  background: #7759de;
}

.pcoded-navbar.navbar-purple .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-purple .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-purple .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-purple .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-purple .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-purple .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-purple .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-purple .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-purple .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-prev {
  background: #7759de;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal:before {
  background: #7759de;
}

.pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar>li>a {
  background: 0 0;
}

.pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar>li:after {
  background: rgba(255, 255, 255, 0.7);
}

.pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: #7759de;
}

.pcoded-navbar.navbar-info {
  background: #00acc1;
}

.pcoded-navbar.navbar-info .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-info .mobile-menu span:after,
.pcoded-navbar.navbar-info .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-info .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-info .header-logo .b-bg {
  background: #00acc1;
}

.pcoded-navbar.navbar-info .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-info .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-info .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-info .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-info .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-info .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-info .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-info .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-info .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-info .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-info .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-info .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-info .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-info .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-info.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-info.theme-horizontal .sidenav-horizontal-prev {
  background: #00acc1;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-info.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-info.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-info.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-info.theme-horizontal .sidenav-horizontal:before {
  background: #00acc1;
}

.pcoded-navbar.navbar-info.theme-horizontal .pcoded-inner-navbar>li>a {
  background: 0 0;
}

.pcoded-navbar.navbar-info.theme-horizontal .pcoded-inner-navbar>li:after {
  background: rgba(255, 255, 255, 0.7);
}

.pcoded-navbar.navbar-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: #00acc1;
}

.pcoded-navbar.navbar-dark {
  background: #242e3e;
}

.pcoded-navbar.navbar-dark .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-dark .mobile-menu span:after,
.pcoded-navbar.navbar-dark .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-dark .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-dark .header-logo .b-bg {
  background: #242e3e;
}

.pcoded-navbar.navbar-dark .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-dark .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-dark .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-dark .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-dark .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-dark .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-dark .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-dark .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-dark .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-prev {
  background: #242e3e;
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal:before {
  background: #242e3e;
}

.pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar>li>a {
  background: 0 0;
}

.pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar>li:after {
  background: rgba(255, 255, 255, 0.7);
}

.pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: #242e3e;
}

body.layout-1 .pcoded-navbar.navbar-blue .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-blue .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-blue .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-blue .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-red .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-red .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-red .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-red .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-purple .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-purple .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-purple .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-purple .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-info .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-info .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-info .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-info .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-dark .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-dark .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-dark .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-dark .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-blue .sidemenu li a {
  background: rgba(33, 150, 243, 0.15);
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-blue .sidemenu li.active a {
  background: #2196f3;
  color: #fff;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-blue .side-content {
  background: #2196f3;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-red .sidemenu li a {
  background: rgba(244, 67, 54, 0.15);
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-red .sidemenu li.active a {
  background: #f44336;
  color: #fff;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-red .side-content {
  background: #f44336;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-purple .sidemenu li a {
  background: rgba(119, 89, 222, 0.15);
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-purple .sidemenu li.active a {
  background: #7759de;
  color: #fff;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-purple .side-content {
  background: #7759de;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-info .sidemenu li a {
  background: rgba(0, 172, 193, 0.15);
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-info .sidemenu li.active a {
  background: #00acc1;
  color: #fff;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-info .side-content {
  background: #00acc1;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-dark .sidemenu li a {
  background: rgba(36, 46, 62, 0.15);
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-dark .sidemenu li.active a {
  background: #242e3e;
  color: #fff;
}

body.layout-9 .pcoded-navbar.theme-horizontal.navbar-dark .side-content {
  background: #242e3e;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-blue .navbar-nav .dropdown>a,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-blue .navbar-nav li>a {
  color: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-blue.brand-dark .header-logo,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-blue[class*="navbar-"].brand-dark .header-logo {
  background: 0 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-blue.brand-dark .header-logo .b-bg,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-blue[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: #fff;
  color: #2196f3;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-red .navbar-nav .dropdown>a,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-red .navbar-nav li>a {
  color: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-red.brand-dark .header-logo,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-red[class*="navbar-"].brand-dark .header-logo {
  background: 0 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-red.brand-dark .header-logo .b-bg,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-red[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: #fff;
  color: #f44336;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-purple .navbar-nav .dropdown>a,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-purple .navbar-nav li>a {
  color: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-purple.brand-dark .header-logo,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-purple[class*="navbar-"].brand-dark .header-logo {
  background: 0 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-purple.brand-dark .header-logo .b-bg,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-purple[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: #fff;
  color: #7759de;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-info .navbar-nav .dropdown>a,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-info .navbar-nav li>a {
  color: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-info.brand-dark .header-logo,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-info[class*="navbar-"].brand-dark .header-logo {
  background: 0 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-info.brand-dark .header-logo .b-bg,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-info[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: #fff;
  color: #00acc1;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-dark .navbar-nav .dropdown>a,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-dark .navbar-nav li>a {
  color: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-dark.brand-dark .header-logo,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-dark[class*="navbar-"].brand-dark .header-logo {
  background: 0 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal.navbar-dark.brand-dark .header-logo .b-bg,
body.layout-8 .pcoded-navbar.theme-horizontal.navbar-dark[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: #fff;
  color: #242e3e;
}

.pcoded-navbar.navbar-gradient-blue {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

.pcoded-navbar.navbar-gradient-blue .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-blue .mobile-menu span:after,
.pcoded-navbar.navbar-gradient-blue .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-blue .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-gradient-blue .header-logo .b-bg {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

.pcoded-navbar.navbar-gradient-blue .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-gradient-blue .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-gradient-blue .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-gradient-blue .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-gradient-blue.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-gradient-blue.theme-horizontal .sidenav-horizontal-prev {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-gradient-blue.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-gradient-blue.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-blue.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-gradient-blue.theme-horizontal .sidenav-horizontal:before {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

.pcoded-navbar.navbar-gradient-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-gradient-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

.pcoded-navbar.navbar-gradient-red {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

.pcoded-navbar.navbar-gradient-red .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-red .mobile-menu span:after,
.pcoded-navbar.navbar-gradient-red .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-red .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-gradient-red .header-logo .b-bg {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

.pcoded-navbar.navbar-gradient-red .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-gradient-red .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-gradient-red .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-gradient-red .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-gradient-red.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-gradient-red.theme-horizontal .sidenav-horizontal-prev {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-gradient-red.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-gradient-red.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-red.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-gradient-red.theme-horizontal .sidenav-horizontal:before {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

.pcoded-navbar.navbar-gradient-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-gradient-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

.pcoded-navbar.navbar-gradient-purple {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

.pcoded-navbar.navbar-gradient-purple .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-purple .mobile-menu span:after,
.pcoded-navbar.navbar-gradient-purple .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-purple .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-gradient-purple .header-logo .b-bg {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

.pcoded-navbar.navbar-gradient-purple .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-gradient-purple .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-gradient-purple .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-gradient-purple .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-gradient-purple.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-gradient-purple.theme-horizontal .sidenav-horizontal-prev {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-gradient-purple.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-gradient-purple.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-purple.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-gradient-purple.theme-horizontal .sidenav-horizontal:before {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

.pcoded-navbar.navbar-gradient-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-gradient-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

.pcoded-navbar.navbar-gradient-info {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

.pcoded-navbar.navbar-gradient-info .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-info .mobile-menu span:after,
.pcoded-navbar.navbar-gradient-info .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-info .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-gradient-info .header-logo .b-bg {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

.pcoded-navbar.navbar-gradient-info .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-gradient-info .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-gradient-info .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-gradient-info .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-gradient-info.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-gradient-info.theme-horizontal .sidenav-horizontal-prev {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-gradient-info.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-gradient-info.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-info.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-gradient-info.theme-horizontal .sidenav-horizontal:before {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

.pcoded-navbar.navbar-gradient-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-gradient-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

.pcoded-navbar.navbar-gradient-dark {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

.pcoded-navbar.navbar-gradient-dark .mobile-menu span {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-dark .mobile-menu span:after,
.pcoded-navbar.navbar-gradient-dark .mobile-menu span:before {
  background-color: #fff;
}

.pcoded-navbar.navbar-gradient-dark .mobile-menu.on span {
  background-color: transparent;
}

.pcoded-navbar.navbar-gradient-dark .header-logo .b-bg {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

.pcoded-navbar.navbar-gradient-dark .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}

.pcoded-navbar.navbar-gradient-dark .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar.navbar-gradient-dark .pcoded-menu-caption {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li.active>a,
.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar>li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.25);
}

.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.navbar-gradient-dark .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  box-shadow: none;
}

.pcoded-navbar.navbar-gradient-dark.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-gradient-dark.theme-horizontal .sidenav-horizontal-prev {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
  color: rgba(255, 255, 255, 0.8);
}

.pcoded-navbar.navbar-gradient-dark.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-gradient-dark.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}

.pcoded-navbar.navbar-gradient-dark.theme-horizontal .sidenav-horizontal:after,
.pcoded-navbar.navbar-gradient-dark.theme-horizontal .sidenav-horizontal:before {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

.pcoded-navbar.navbar-gradient-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.navbar-gradient-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

body.layout-1 .pcoded-navbar.navbar-gradient-blue .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-blue .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-blue .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-blue .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-red .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-red .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-red .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-red .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-purple .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-purple .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-purple .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-purple .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-info .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-info .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-info .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-info .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-dark .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(255, 255, 255, 0.3);
}

body.layout-1 .pcoded-navbar.navbar-gradient-dark .sidemenu li.active:after {
  background: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-dark .sidemenu li a {
  color: #fff;
}

body.layout-1 .pcoded-navbar.navbar-gradient-dark .sidemenu li a:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #2196f3;
}

.pcoded-navbar.active-blue[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #2196f3;
}

.pcoded-navbar.active-blue .pcoded-inner-navbar li>a.active,
.pcoded-navbar.active-blue .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.active-blue .pcoded-inner-navbar li:hover>a {
  color: #2196f3;
}

.pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #2196f3;
}

.pcoded-navbar.active-blue .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.active-blue .pcoded-inner-navbar>li.pcoded-trigger:after {
  background-color: #2196f3;
}

.pcoded-navbar.active-blue .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-blue .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}

.pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #f1f1f1;
  color: #111;
}

.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li:hover>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu:hover>a {
  background: 0 0;
}

.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.active>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li:hover>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.active>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li:hover>a {
  color: #2196f3;
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #2196f3;
}

.pcoded-navbar.active-red[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #f44336;
}

.pcoded-navbar.active-red .pcoded-inner-navbar li.active>a,
.pcoded-navbar.active-red .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.active-red .pcoded-inner-navbar li:hover>a {
  color: #f44336;
}

.pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #f44336;
}

.pcoded-navbar.active-red .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.active-red .pcoded-inner-navbar>li.pcoded-trigger:after {
  background-color: #f44336;
}

.pcoded-navbar.active-red .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-red .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}

.pcoded-navbar.active-red.menu-light .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-red.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #f1f1f1;
  color: #111;
}

.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li:hover>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu:hover>a {
  background: 0 0;
}

.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.active>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li:hover>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.active>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li:hover>a {
  color: #f44336;
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #2196f3;
}

.pcoded-navbar.active-purple[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #7759de;
}

.pcoded-navbar.active-purple .pcoded-inner-navbar li.active>a,
.pcoded-navbar.active-purple .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.active-purple .pcoded-inner-navbar li:hover>a {
  color: #7759de;
}

.pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #7759de;
}

.pcoded-navbar.active-purple .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.active-purple .pcoded-inner-navbar>li.pcoded-trigger:after {
  background-color: #7759de;
}

.pcoded-navbar.active-purple .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-purple .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}

.pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #f1f1f1;
  color: #111;
}

.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li:hover>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu:hover>a {
  background: 0 0;
}

.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.active>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li:hover>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.active>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li:hover>a {
  color: #7759de;
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #2196f3;
}

.pcoded-navbar.active-info[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #00acc1;
}

.pcoded-navbar.active-info .pcoded-inner-navbar li.active>a,
.pcoded-navbar.active-info .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.active-info .pcoded-inner-navbar li:hover>a {
  color: #00acc1;
}

.pcoded-navbar.active-info .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.active-info .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.active-info .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #00acc1;
}

.pcoded-navbar.active-info .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.active-info .pcoded-inner-navbar>li.pcoded-trigger:after {
  background-color: #00acc1;
}

.pcoded-navbar.active-info .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-info .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}

.pcoded-navbar.active-info.menu-light .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-info.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #f1f1f1;
  color: #111;
}

.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li:hover>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu:hover>a {
  background: 0 0;
}

.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.active>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li:hover>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.active>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-info.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li:hover>a {
  color: #00acc1;
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #2196f3;
}

.pcoded-navbar.active-dark[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active>a:before {
  color: #263238;
}

.pcoded-navbar.active-dark .pcoded-inner-navbar li.active>a,
.pcoded-navbar.active-dark .pcoded-inner-navbar li:focus>a,
.pcoded-navbar.active-dark .pcoded-inner-navbar li:hover>a {
  color: #263238;
}

.pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
.pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
.pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #263238;
}

.pcoded-navbar.active-dark .pcoded-inner-navbar>li.active:after,
.pcoded-navbar.active-dark .pcoded-inner-navbar>li.pcoded-trigger:after {
  background-color: #263238;
}

.pcoded-navbar.active-dark .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-dark .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
}

.pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: #f1f1f1;
  color: #111;
}

.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.active>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-trigger>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li:hover>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu:hover>a {
  background: 0 0;
}

.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.active>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li .pcoded-submenu>li:hover>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.active>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li.pcoded-trigger>a,
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu>li:hover>a {
  color: #263238;
}

body.layout-1 .pcoded-navbar.active-blue .sidemenu li.active {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar.active-blue .sidemenu li.active:after {
  background-color: #2196f3;
}

body.layout-1 .pcoded-navbar.active-red .sidemenu li.active {
  color: #f44336;
}

body.layout-1 .pcoded-navbar.active-red .sidemenu li.active:after {
  background-color: #f44336;
}

body.layout-1 .pcoded-navbar.active-purple .sidemenu li.active {
  color: #7759de;
}

body.layout-1 .pcoded-navbar.active-purple .sidemenu li.active:after {
  background-color: #7759de;
}

body.layout-1 .pcoded-navbar.active-info .sidemenu li.active {
  color: #00acc1;
}

body.layout-1 .pcoded-navbar.active-info .sidemenu li.active:after {
  background-color: #00acc1;
}

body.layout-1 .pcoded-navbar.active-dark .sidemenu li.active {
  color: #263238;
}

body.layout-1 .pcoded-navbar.active-dark .sidemenu li.active:after {
  background-color: #263238;
}

.pcoded-navbar.menupos-fixed {
  position: fixed;
  height: 100vh;
  min-height: 100vh;
}

.pcoded-navbar.menupos-fixed .scroll-div.navbar-content {
  height: calc(100% - 70px);
  min-height: calc(100% - 70px);
  background-color: cornsilk;
}

body.box-layout {
  position: static;
  background-image: url(../images/bg-images/5.jpg);
  background-size: auto;
  background-attachment: fixed;
}

body.box-layout .pcoded-header {
  position: relative;
}

body.box-layout .pcoded-header~.pcoded-main-container {
  padding-top: 0;
  background: #eff3f6;
}

body.box-layout .pcoded-navbar {
  position: fixed;
  align-content: space-around;
}

body.box-layout .pcoded-navbar.theme-horizontal~.pcoded-header {
  position: relative;
}

@media only screen and (max-width: 992px) {
  body.box-layout {
    position: relative;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    padding: 0;
  }
}

.pcoded-header.headerpos-fixed {
  position: fixed;
  top: 0;
  background-color: burlywood;
}

.pcoded-header.headerpos-fixed~.pcoded-main-container {
  padding-top: 70px;
}

@media only screen and (max-width: 991px) {
  .pcoded-header.headerpos-fixed~.pcoded-main-container {
    padding-top: 140px;
  }
}

.pcoded-navbar.drp-icon-style2 .pcoded-inner-navbar li.pcoded-hasmenu>a:after {
  content: "\e847";
}

.pcoded-navbar.drp-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu>a:after {
  content: "\e8b1";
}

.pcoded-navbar.drp-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger>a:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu:after {
  background: rgba(169, 183, 208, 0.05);
}

.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  font-family: feather;
  font-style: normal;
  padding-right: 4px;
  font-size: 13px;
  position: absolute;
  left: 35px;
  top: 10px;
  opacity: 1;
  visibility: visible;
  background: 0 0;
}

.pcoded-navbar[class*="menu-item-icon-style"].theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu>.pcoded-submenu>li>a:before {
  left: 5px;
}

.pcoded-navbar[class*="menu-item-icon-style"].theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu>.pcoded-submenu>li>.pcoded-submenu>li>a:before {
  top: 12px;
}

.pcoded-navbar.menu-item-icon-style2 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  content: "\e897";
}

.pcoded-navbar.menu-item-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  content: "\e83f";
}

.pcoded-navbar.menu-item-icon-style4 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  content: "\e856";
}

.pcoded-navbar.menu-item-icon-style5 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  content: "\e847";
}

.pcoded-navbar.menu-item-icon-style6 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  content: "\e844";
}

.pcoded-navbar[class*="navbar-image"] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.pcoded-navbar[class*="navbar-image"] .header-logo,
.pcoded-navbar[class*="navbar-image"] .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar[class*="navbar-image"] .pcoded-inner-navbar>li.active>a,
.pcoded-navbar[class*="navbar-image"] .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(25, 32, 43, 0.4);
}

.pcoded-navbar[class*="navbar-image"]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}

.pcoded-navbar.navbar-image-1 {
  background-image: url(../images/nav-bg/navbar-img-1.jpg);
}

.pcoded-navbar.navbar-image-2 {
  background-image: url(../images/nav-bg/navbar-img-2.jpg);
}

.pcoded-navbar.navbar-image-3 {
  background-image: url(../images/nav-bg/navbar-img-3.jpg);
}

.pcoded-navbar.navbar-image-4 {
  background-image: url(../images/nav-bg/navbar-img-4.jpg);
}

.pcoded-navbar.navbar-image-5 {
  background-image: url(../images/nav-bg/navbar-img-5.jpg);
}

.pcoded-navbar[class*="navbar-pattern"] {
  background-size: auto;
  background-repeat: repeat;
}

.pcoded-navbar[class*="navbar-pattern"] .header-logo,
.pcoded-navbar[class*="navbar-pattern"] .pcoded-submenu {
  background: 0 0;
}

.pcoded-navbar[class*="navbar-pattern"] .pcoded-inner-navbar>li.active>a,
.pcoded-navbar[class*="navbar-pattern"] .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(25, 32, 43, 0.4);
}

.pcoded-navbar[class*="navbar-pattern"]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
}

.pcoded-navbar.navbar-pattern-1 {
  background-image: url(../images/bg-images/1.jpg);
}

.pcoded-navbar.navbar-pattern-2 {
  background-image: url(../images/bg-images/2.jpg);
}

.pcoded-navbar.navbar-pattern-3 {
  background-image: url(../images/bg-images/3.jpg);
}

.pcoded-navbar.navbar-pattern-4 {
  background-image: url(../images/bg-images/4.jpg);
}

.pcoded-navbar.navbar-pattern-5 {
  background-image: url(../images/bg-images/5.jpg);
}

.pcoded-navbar.navbar-pattern-6 {
  background-image: url(../images/bg-images/6.jpg);
}

.pcoded-navbar.title-default .pcoded-inner-navbar .pcoded-menu-caption {
  color: #8090a5;
}

.pcoded-navbar.title-blue .pcoded-inner-navbar .pcoded-menu-caption {
  color: #2196f3;
}

.pcoded-navbar.title-red .pcoded-inner-navbar .pcoded-menu-caption {
  color: #f44336;
}

.pcoded-navbar.title-purple .pcoded-inner-navbar .pcoded-menu-caption {
  color: #7759de;
}

.pcoded-navbar.title-info .pcoded-inner-navbar .pcoded-menu-caption {
  color: #00acc1;
}

.pcoded-navbar.title-dark .pcoded-inner-navbar .pcoded-menu-caption {
  color: #263238;
}

.pcoded-navbar.caption-hide .pcoded-menu-caption {
  display: none;
}

.pcoded-navbar.header-hide~.pcoded-header {
  display: none;
}

.pcoded-navbar.header-hide~.pcoded-main-container {
  padding-top: 0;
}

.doc-img>a {
  position: relative;
  width: 130px;
  height: 80px;
  display: inline-block;
  background: #eff3f6;
  overflow: hidden;
  box-shadow: 0 0 0 1px #e2e5e8;
}

.doc-img>a span {
  width: 100%;
  position: absolute;
}

.doc-img>a span:after,
.doc-img>a span:before {
  content: "";
  height: 100%;
  position: absolute;
}

.doc-img>a span:before {
  width: 30%;
  left: 0;
  background: #242e3e;
}

.doc-img>a span:after {
  width: 70%;
  right: 0;
  background: #eff3f6;
}

.doc-img>a>span:nth-child(1) {
  height: 20%;
  top: 0;
}

.doc-img>a>span:nth-child(1):after {
  background: #fff;
}

.doc-img>a>span:nth-child(2) {
  height: 80%;
  bottom: 0;
}

.doc-img>a.active {
  border-color: #2196f3;
}

.doc-img>a.active:before {
  content: "\e83f";
  top: -4px;
  font-size: 20px;
  position: absolute;
  left: 10px;
  font-family: feather;
  z-index: 1001;
  color: #2196f3;
  transition: 0.3s ease-in-out;
  text-shadow: 0 3px 8px rgba(33, 150, 243, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1);
}

.doc-img>a.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
}

.doc-img>a[data-value="menu-light"] span:before {
  background: #fff;
}

.doc-img>a[data-value="dark"] span:after {
  background: #080a0e;
}

.doc-img.navbar-color>a[data-value="navbar-blue"]>span:before {
  background: #2196f3;
}

.doc-img.navbar-color>a[data-value="navbar-red"]>span:before {
  background: #f44336;
}

.doc-img.navbar-color>a[data-value="navbar-purple"]>span:before {
  background: #7759de;
}

.doc-img.navbar-color>a[data-value="navbar-info"]>span:before {
  background: #00acc1;
}

.doc-img.navbar-color>a[data-value="navbar-dark"]>span:before {
  background: #242e3e;
}

.doc-img.header-color>a[data-value="header-blue"]>span:nth-child(1):after {
  background: #2196f3;
}

.doc-img.header-color>a[data-value="header-red"]>span:nth-child(1):after {
  background: #f44336;
}

.doc-img.header-color>a[data-value="header-purple"]>span:nth-child(1):after {
  background: #7759de;
}

.doc-img.header-color>a[data-value="header-info"]>span:nth-child(1):after {
  background: #00acc1;
}

.doc-img.header-color>a[data-value="header-dark"]>span:nth-child(1):after {
  background: #242e3e;
}

.doc-img.brand-color>a[data-value="brand-blue"]>span:nth-child(1):before {
  background: #2196f3;
}

.doc-img.brand-color>a[data-value="brand-red"]>span:nth-child(1):before {
  background: #f44336;
}

.doc-img.brand-color>a[data-value="brand-purple"]>span:nth-child(1):before {
  background: #7759de;
}

.doc-img.brand-color>a[data-value="brand-info"]>span:nth-child(1):before {
  background: #00acc1;
}

.doc-img.brand-color>a[data-value="brand-dark"]>span:nth-child(1):before {
  background: #242e3e;
}

.doc-img.active-color>a[data-value="active-default"] {
  background: #2196f3;
}

.doc-img.active-color>a[data-value="active-blue"] {
  background: #2196f3;
}

.doc-img.active-color>a[data-value="active-red"] {
  background: #f44336;
}

.doc-img.active-color>a[data-value="active-purple"] {
  background: #7759de;
}

.doc-img.active-color>a[data-value="active-info"] {
  background: #00acc1;
}

.doc-img.active-color>a[data-value="active-dark"] {
  background: #263238;
}

.doc-img.title-color>a[data-value="title-default"] {
  background: #fff;
}

.doc-img.title-color>a[data-value="title-blue"] {
  background: #2196f3;
}

.doc-img.title-color>a[data-value="title-red"] {
  background: #f44336;
}

.doc-img.title-color>a[data-value="title-purple"] {
  background: #7759de;
}

.doc-img.title-color>a[data-value="title-info"] {
  background: #00acc1;
}

.doc-img.title-color>a[data-value="title-dark"] {
  background: #263238;
}

.doc-img.navbar-images>a {
  height: 130px;
  background-size: 37px;
  background-position: left top;
}

.doc-img.navbar-images>a span:before {
  background: 0 0;
}

.doc-img.navbar-images>a[data-value="navbar-image-1"] {
  background-image: url(../images/nav-bg/navbar-img-1.jpg);
}

.doc-img.navbar-images>a[data-value="navbar-image-2"] {
  background-image: url(../images/nav-bg/navbar-img-2.jpg);
}

.doc-img.navbar-images>a[data-value="navbar-image-3"] {
  background-image: url(../images/nav-bg/navbar-img-3.jpg);
}

.doc-img.navbar-images>a[data-value="navbar-image-4"] {
  background-image: url(../images/nav-bg/navbar-img-4.jpg);
}

.doc-img.navbar-images>a[data-value="navbar-image-5"] {
  background-image: url(../images/nav-bg/navbar-img-5.jpg);
}

.doc-img.navbar-images>a[data-value="navbar-image-6"] {
  background-image: url(../images/nav-bg/navbar-img-6.jpg);
}

.doc-img.navbar-gradient-color>a[data-value="navbar-gradient-blue"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

.doc-img.navbar-gradient-color>a[data-value="navbar-gradient-red"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

.doc-img.navbar-gradient-color>a[data-value="navbar-gradient-purple"]>span:nth-child(2):before {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

.doc-img.navbar-gradient-color>a[data-value="navbar-gradient-info"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

.doc-img.navbar-gradient-color>a[data-value="navbar-gradient-dark"]>span:nth-child(2):before {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

.doc-img.navbar-pattern>a {
  background-size: auto;
}

.doc-img.navbar-pattern>a span {
  display: none;
}

.doc-img.navbar-pattern>a[data-value="navbar-pattern-1"] {
  background-image: url(../images/bg-images/1.jpg);
}

.doc-img.navbar-pattern>a[data-value="navbar-pattern-2"] {
  background-image: url(../images/bg-images/2.jpg);
}

.doc-img.navbar-pattern>a[data-value="navbar-pattern-3"] {
  background-image: url(../images/bg-images/3.jpg);
}

.doc-img.navbar-pattern>a[data-value="navbar-pattern-4"] {
  background-image: url(../images/bg-images/4.jpg);
}

.doc-img.navbar-pattern>a[data-value="navbar-pattern-5"] {
  background-image: url(../images/bg-images/5.jpg);
}

.doc-img.navbar-pattern>a[data-value="navbar-pattern-6"] {
  background-image: url(../images/bg-images/6.jpg);
}

.nav-link.active.h-blue {
  background: #2196f3 !important;
}

.nav-link.active.h-red {
  background: #f44336 !important;
}

.nav-link.active.h-purple {
  background: #7759de !important;
}

.nav-link.active.h-info {
  background: #00acc1 !important;
}

.nav-link.active.h-dark {
  background: #242e3e !important;
}

@media all and (-ms-high-contrast: none) {
  .pcoded-header .navbar-nav>li {
    display: inline-flex;
  }

  .pcoded-header .navbar-nav>li.dropdown {
    display: inline-block;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu::after {
    left: 40px;
  }
}

body.layout-1 .pcoded-navbar .toggle-sidemenu {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #11161e;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 0 0;
}

@media only screen and (max-width: 991px) {
  body.layout-1 .pcoded-navbar .toggle-sidemenu {
    display: none;
  }

  body.layout-1 .pcoded-navbar.mob-open .toggle-sidemenu {
    display: flex;
  }
}

body.layout-1 .pcoded-navbar.navbar-collapsed .toggle-sidemenu {
  display: none;
}

body.layout-1 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu li a {
  white-space: normal;
}

body.layout-1 .pcoded-navbar .header-logo {
  padding: 10px 20px;

}

body.layout-1 .pcoded-navbar .sidemenu {
  box-shadow: inset -1px 0 0 0 rgba(181, 189, 202, 0.2);
}

body.layout-1 .pcoded-navbar .sidemenu li {
  position: relative;
}

body.layout-1 .pcoded-navbar .sidemenu li a {
  font-size: 22px;
  padding: 10px 24px;
  position: relative;
  cursor: pointer;
}

body.layout-1 .pcoded-navbar .sidemenu li a:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 60%;
  left: 20%;
  bottom: 0;
  border-bottom: 1px solid rgba(181, 189, 202, 0.2);
}

body.layout-1 .pcoded-navbar .sidemenu li a span {
  display: none;
}

body.layout-1 .pcoded-navbar .sidemenu li:last-child a:after {
  display: none;
}

body.layout-1 .pcoded-navbar .sidemenu li.active {
  color: #2196f3;
}

body.layout-1 .pcoded-navbar .sidemenu li.active:after {
  content: "";
  background-color: #2196f3;
  z-index: 1027;
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
  height: 100%;
}

body.layout-1 .pcoded-navbar .layout1-nav {
  display: flex;
  align-items: stretch;
  height: auto;
  min-height: 100%;
}

body.layout-1 .pcoded-navbar .side-content {
  flex: 1 1 0;
}

body.layout-1 .pcoded-navbar .side-content .sidelink {
  display: none;
}

body.layout-1 .pcoded-navbar .side-content .sidelink.active {
  display: block;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 20px;
  padding: 16px 15px 16px 18px;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-menu-caption:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 40px;
  left: 18px;
  bottom: 13px;
  border-bottom: 1px solid rgba(181, 189, 202, 0.2);
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a {
  padding: 7px 7px 7px 40px;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>a:before {
  left: 20px;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>.pcoded-submenu>li>a {
  padding: 7px 7px 7px 60px;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li>.pcoded-submenu>li>a:before {
  left: 40px;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar>li>a {
  padding: 12px 10px 12px 20px;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar>li>a .pcoded-micon {
  display: none;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar>li>a .pcoded-mtext {
  position: relative;
  top: 0;
}

body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar>li.active:after,
body.layout-1 .pcoded-navbar .side-content .sidelink .pcoded-inner-navbar>li.pcoded-trigger:after {
  display: none;
}

body.layout-1 .pcoded-navbar.hide-sidemenu .sidemenu {
  position: absolute;
  left: -70px;
}

body.layout-1 .pcoded-navbar.hide-sidemenu .toggle-sidemenu i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

body.layout-1 .pcoded-navbar.menu-light .toggle-sidemenu {
  background: #f1f1f1;
}

body.layout-1.box-layout .pcoded-navbar .toggle-sidemenu {
  display: none;
}

body.layout-2 .pcoded-navbar a {
  color: rgba(255, 255, 255, 0.85);
}

body.layout-2 .pcoded-navbar .pcoded-submenu {
  background-color: rgba(0, 0, 0, 0.05);
}

body.layout-2 .pcoded-navbar .pcoded-submenu .pcoded-submenu {
  background-color: transparent;
}

body.layout-2 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu:after {
  display: none;
}

body.layout-2 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a>.pcoded-micon+.pcoded-mtext {
  top: 25px;
}

body.layout-2 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu li a {
  color: rgba(255, 255, 255, 0.85);
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar .pcoded-menu-caption {
  display: none;
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar>li>a {
  padding: 20px 15px 20px 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-hasmenu>a:after {
  top: 25px;
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar>li.active:after,
body.layout-2 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger:after {
  display: none;
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar>li.active>a,
body.layout-2 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger>a {
  background-color: rgba(0, 0, 0, 0.08);
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar li.active>a,
body.layout-2 .pcoded-navbar .pcoded-inner-navbar li:focus>a,
body.layout-2 .pcoded-navbar .pcoded-inner-navbar li:hover>a {
  color: #fff;
}

body.layout-2 .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu>li.active>a:before,
body.layout-2 .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu>li:focus>a:before,
body.layout-2 .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu>li:hover>a:before {
  background: #fff;
}

body.layout-3 .pcoded-header {
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
}

body.layout-3 .pcoded-navbar.menu-light {
  box-shadow: inset -1px 0 0 0 rgba(100, 121, 143, 0.122);
}

body.layout-3 .pcoded-navbar.menu-light .pcoded-submenu {
  background: 0 0;
}

body.layout-3 .pcoded-navbar.menu-light .pcoded-inner-navbar>li.pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 20px;
  height: 1px;
  width: calc(100% - 40px);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
}

body.layout-3 .pcoded-navbar.menu-light .pcoded-inner-navbar>li.pcoded-menu-caption:first-child:after {
  display: none;
}

body.layout-3 .pcoded-navbar.menu-light .pcoded-inner-navbar>li>a {
  border-radius: 0 20px 20px 0;
  margin-right: 15px;
  padding: 4px 15px;
}

body.layout-3 .pcoded-navbar.menu-light .pcoded-inner-navbar>li>a:after {
  top: 8px;
  right: 15px;
}

body.layout-3 .pcoded-navbar.menu-light .pcoded-inner-navbar>li.active>a,
body.layout-3 .pcoded-navbar.menu-light .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(35, 183, 229, 0.15);
  color: #23b7e5;
}

body.layout-3 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li>a {
  margin-right: 8px;
}

body.layout-3 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar>li>a:after {
  top: 8px;
  right: 15px;
}

body.layout-3 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a>.pcoded-micon+.pcoded-mtext {
  top: 9px;
}

body.layout-3 .pcoded-navbar.active-blue .pcoded-inner-navbar>li.active>a,
body.layout-3 .pcoded-navbar.active-blue .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(33, 150, 243, 0.15);
  color: #2196f3;
}

body.layout-3 .pcoded-navbar.active-red .pcoded-inner-navbar>li.active>a,
body.layout-3 .pcoded-navbar.active-red .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(244, 67, 54, 0.15);
  color: #f44336;
}

body.layout-3 .pcoded-navbar.active-purple .pcoded-inner-navbar>li.active>a,
body.layout-3 .pcoded-navbar.active-purple .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(119, 89, 222, 0.15);
  color: #7759de;
}

body.layout-3 .pcoded-navbar.active-info .pcoded-inner-navbar>li.active>a,
body.layout-3 .pcoded-navbar.active-info .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(0, 172, 193, 0.15);
  color: #00acc1;
}

body.layout-3 .pcoded-navbar.active-dark .pcoded-inner-navbar>li.active>a,
body.layout-3 .pcoded-navbar.active-dark .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(38, 50, 56, 0.15);
  color: #263238;
}

body.layout-3 .card {
  box-shadow: 0 0 0 1px rgba(100, 121, 143, 0.122);
  transition: all 0.25s ease-in-out;
}

body.layout-3 .card:hover {
  box-shadow: 0 0 0 1px rgba(100, 121, 143, 0.122),
    0 2px 1px rgba(0, 0, 0, 0.05);
}

body.layout-4 {
  background: #2196f3;
  background-size: cover;
  background-attachment: fixed;
  background-position: top right;
  position: relative;
}

body.layout-4 .page-header-title+.breadcrumb>.breadcrumb-item:last-child a,
body.layout-4 .page-header-title h5,
body.layout-4 .pcoded-header .input-group .search-btn .input-group-text,
body.layout-4 .pcoded-header .navbar-nav>li>.dropdown>.dropdown-toggle,
body.layout-4 .pcoded-header .navbar-nav>li>.dropdown-toggle,
body.layout-4 .pcoded-header .navbar-nav>li>a {
  color: #fff;
}

body.layout-4 .mobile-menu span,
body.layout-4 .mobile-menu span:after,
body.layout-4 .mobile-menu span:before,
body.layout-4 .pcoded-navbar.menu-light .mobile-menu span,
body.layout-4 .pcoded-navbar.menu-light .mobile-menu span:after,
body.layout-4 .pcoded-navbar.menu-light .mobile-menu span:before {
  background: #fff;
}

body.layout-4 .breadcrumb-item+.breadcrumb-item::before,
body.layout-4 .page-header-title+.breadcrumb>.breadcrumb-item a {
  color: rgba(255, 255, 255, 0.8);
}

body.layout-4 .pcoded-content {
  background: #eff3f6;
}

body.layout-4 .navbar-brand,
body.layout-4 .pcoded-header.header-blue,
body.layout-4 .pcoded-navbar,
body.layout-4 .pcoded-navbar.menu-light,
body.layout-4 .pcoded-navbar.menu-light .header-logo {
  background: 0 0;
  box-shadow: none;
}

body.layout-4 .pcoded-navbar .navbar-content {
  background: #242e3e;
  border-radius: 0 3px 0 0;
  box-shadow: 0 0 20px 0 #242e3e;
}

body.layout-4.box-layout .pcoded-navbar .navbar-content {
  border-radius: 3px 3px 0 0;
}

body.layout-4 .main-body {
  min-height: calc(100vh - 200px);
}

body.layout-4 .pcoded-navbar.menu-light .navbar-content {
  background: #fff;
  box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
}

body.layout-4 .pcoded-navbar.mob-open~.pcoded-header:before,
body.layout-4 .pcoded-navbar.mob-open~.pcoded-main-container:before,
body.layout-4 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
body.layout-4 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
  background: 0 0;
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .b-brand {
  display: none;
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu {
  right: auto;
  left: 20px;
  transition-delay: 0s;
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu span {
  background: 0 0;
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span {
  background-color: transparent;
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:after,
body.layout-4 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:before {
  height: 2px;
  width: 100%;
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
  transform: rotate(45deg) translate(4px, 4px);
}

body.layout-4 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
  transform: rotate(-45deg) translate(3px, -3px);
}

body.layout-4 .pcoded-header {
  background: 0 0;
  box-shadow: none;
}

@media only screen and (max-width: 991px) {

  body.layout-4 .pcoded-header>.collapse:not(.show),
  body.layout-4 .pcoded-header .container>.collapse:not(.show) {
    background: 0 0;
    box-shadow: none;
  }
}

body.layout-4 .pcoded-content {
  margin-top: 120px;
}

body.layout-4 .pcoded-content .pcoded-inner-content {
  margin-top: -120px;
  min-height: calc(100vh + 120px);
}

body.layout-4 .pcoded-navbar .header-logo {
  background: 0 0;
}

body.layout-4 .pcoded-navbar.navbar-dark.brand-info .header-logo,
body.layout-4 .pcoded-navbar.navbar-dark[class*="navbar-"].brand-info .header-logo,
body.layout-4 .pcoded-navbar.navbar-dark .header-logo {
  background: 0 0;
}

body.layout-4 .pcoded-navbar.navbar-dark .navbar-content {
  background: #242e3e;
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 991px) {

  body.layout-4 .pcoded-header .collapse:not(.show) .mob-toggler:after,
  body.layout-4 .pcoded-header .navbar-nav>li>.dropdown>.dropdown-toggle,
  body.layout-4 .pcoded-header .navbar-nav>li>.dropdown-toggle,
  body.layout-4 .pcoded-header .navbar-nav>li>a {
    color: #fff;
  }

  body.layout-4 .pcoded-header .mobile-menu.on span {
    background: 0 0;
  }

  body.layout-4 .pcoded-header .mobile-menu.on span:after,
  body.layout-4 .pcoded-header .mobile-menu.on span:before {
    background: #fff;
  }
}

body.layout-5 {
  margin: 30px;
  background: rgba(33, 150, 243, 0.2);
}

body.layout-5 .pcoded-main-container {
  background: #eff3f6;
  border-radius: 0 0 10px 0;
  box-shadow: 1px 1px 0 0 #b7bfc6;
}

body.layout-5 .pcoded-navbar {
  border-radius: 10px 0 0 10px;
}

body.layout-5 .pcoded-navbar[class*="navbar-image"]:before {
  border-radius: 10px 0 0 10px;
}

body.layout-5 .pcoded-navbar .navbar-brand {
  border-radius: 10px 0 0 0;
}

body.layout-5 .pcoded-navbar .pcoded-inner-navbar>li.active:after,
body.layout-5 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger:after {
  display: none;
}

body.layout-5 .pcoded-navbar.mob-open~.pcoded-header:before,
body.layout-5 .pcoded-navbar.mob-open~.pcoded-main-container:before,
body.layout-5 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
body.layout-5 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
  background: 0 0;
}

body.layout-5 .pcoded-header {
  border-radius: 0 10px 0 0;
  box-shadow: 0 1px 0 0 #e2e5e8, 1px -1px 0 0 #b7bfc6;
}

@media only screen and (max-width: 992px) {
  body.layout-5 .pcoded-header {
    border-radius: 10px 10px 0 0;
  }

  body.layout-5 .pcoded-navbar {
    margin-left: -294px;
  }

  body.layout-5 .pcoded-navbar.mob-open {
    margin-left: 0;
  }

  body.layout-5 .pcoded-main-container {
    box-shadow: 1px 1px 0 0 #e2e5e8, -1px 0 0 0 #e2e5e8;
  }
}

@media only screen and (max-width: 575px) {
  body.layout-5 {
    margin: 0;
  }

  body.layout-5 .navbar-brand,
  body.layout-5 .pcoded-header,
  body.layout-5 .pcoded-main-container,
  body.layout-5 .pcoded-navbar {
    border-radius: 0;
  }
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu.on span {
  background: 0 0;
}

body.layout-6 .pcoded-header .m-header .mobile-menu#show-toggle,
body.layout-7 .pcoded-header .m-header .mobile-menu#show-toggle {
  right: 60px;
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header .m-header,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header .m-header {
  position: relative;
  width: 264px;
  align-self: stretch;
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .mobile-menu span,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .mobile-menu span {
  background: #fff;
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .mobile-menu span:after,
body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .mobile-menu span:before,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .mobile-menu span:after,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header[class*="header-"] .mobile-menu span:before {
  background: #fff;
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu {
  display: flex;
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu span,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu span {
  background: #242e3e;
}

body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu span:after,
body.layout-6 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu span:before,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu span:after,
body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  background-color: #242e3e;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

body.layout-6 .pcoded-navbar .scroll-div.navbar-content,
body.layout-7 .pcoded-navbar .scroll-div.navbar-content {
  height: 100%;
}

body.layout-6 .pcoded-navbar:not(.theme-horizontal),
body.layout-7 .pcoded-navbar:not(.theme-horizontal) {
  position: fixed;
  top: 0;
  left: -264px;
}

@media only screen and (max-width: 992px) {

  body.layout-6 .pcoded-navbar:not(.theme-horizontal),
  body.layout-7 .pcoded-navbar:not(.theme-horizontal) {
    position: absolute;
  }

  body.layout-6 .pcoded-navbar:not(.theme-horizontal) .navbar-content,
  body.layout-7 .pcoded-navbar:not(.theme-horizontal) .navbar-content {
    box-shadow: none;
  }
}

body.layout-6 .pcoded-navbar:not(.theme-horizontal).mob-open,
body.layout-7 .pcoded-navbar:not(.theme-horizontal).mob-open {
  left: 0;
}

body.layout-6 .pcoded-navbar:not(.theme-horizontal).mob-open~.pcoded-navbar.theme-horizontal:before,
body.layout-7 .pcoded-navbar:not(.theme-horizontal).mob-open~.pcoded-navbar.theme-horizontal:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media only screen and (max-width: 992px) {

  body.layout-6 .pcoded-navbar.mob-open-h,
  body.layout-7 .pcoded-navbar.mob-open-h {
    margin-left: 0;
    left: 0;
    width: 100%;
    top: 70px;
    height: calc(100vh - 70px);
  }
}

body.layout-7 .pcoded-navbar.theme-horizontal {
  width: calc(100% - 264px);
  margin-left: 264px;
}

body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header {
  width: calc(100% - 264px);
  margin-left: 264px;
}

body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-header .m-header {
  display: none;
}

body.layout-7 .pcoded-navbar.theme-horizontal~.pcoded-main-container {
  margin-left: 264px;
}

body.layout-7 .pcoded-navbar:not(.theme-horizontal) {
  left: 0;
}

body.layout-7 .pcoded-navbar:not(.theme-horizontal).navbar-collapsed~.pcoded-header,
body.layout-7 .pcoded-navbar:not(.theme-horizontal).navbar-collapsed~.pcoded-main-container,
body.layout-7 .pcoded-navbar:not(.theme-horizontal).navbar-collapsed~.theme-horizontal {
  margin-left: 70px;
  width: calc(100% - 70px);
}

body.layout-8 .pcoded-header {
  display: none;
}

@media only screen and (max-width: 991px) {
  body.layout-8 .pcoded-header {
    display: flex;
  }

  body.layout-8 .pcoded-header .collapse:not(.show) {
    display: flex;
    background: #fff;
  }
}

@media (max-width: 575px) {
  body.layout-8 .container {
    padding-left: 0;
    padding-right: 0;
  }
}

body.layout-8 .pcoded-navbar.theme-horizontal {
  top: 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal .mobile-menu {
  display: none;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-content {
  flex: 1 1 auto;
}

body.layout-8 .pcoded-navbar.theme-horizontal .header-logo {
  display: inline-flex;
  z-index: 1028;
}

body.layout-8 .pcoded-navbar.theme-horizontal~.pcoded-main-container {
  margin-top: 70px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-wrapper {
  display: flex;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification a {
  color: #686c71;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification a:hover {
  color: #111;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-head {
  background: #242e3e;
  padding: 15px 20px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-head h6 {
  color: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-head a {
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body {
  padding: 0;
  position: relative;
  height: 300px;
  width: 100%;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li {
  padding: 15px 20px;
  transition: all 0.3s ease-in-out;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li.n-title {
  padding-bottom: 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li.notification:hover {
  background: rgba(33, 150, 243, 0.1);
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li p strong {
  color: #222;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
  background: #eff3f6;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

body.layout-8 .pcoded-navbar.theme-horizontal .notification ul li {
  padding: 20px 15px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
  width: 290px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #2196f3;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-head img {
  width: 40px;
  margin-right: 10px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li a {
  color: #686c71;
  font-size: 14px;
  padding: 10px 20px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li a i {
  margin-right: 10px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li.active,
body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li:active,
body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li:focus,
body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li:hover {
  background: rgba(33, 150, 243, 0.1);
}

body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li.active>a,
body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li:active>a,
body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li:focus>a,
body.layout-8 .pcoded-navbar.theme-horizontal .profile-notification .pro-body li:hover>a {
  background: 0 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav {
  width: 264px;
  z-index: 1029;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav .dropdown-toggle:after {
  left: 0;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav .dropdown-toggle+.notification,
body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav .dropdown-toggle+.profile-notification {
  position: absolute;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav .dropdown>a,
body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav li>a {
  display: block;
  margin-right: 15px;
}

body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav>li,
body.layout-8 .pcoded-navbar.theme-horizontal .navbar-nav>li>a {
  line-height: 70px;
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .sidenav-horizontal:after,
body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .sidenav-horizontal:before {
  background: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo {
  background: #fff;
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .b-title {
  color: #242e3e;
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu span {
  background-color: rgba(36, 46, 62, 0.8);
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu span:after,
body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu span:before {
  background-color: rgba(36, 46, 62, 0.8);
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu:hover span {
  background-color: #242e3e;
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu:hover span:after,
body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu:hover span:before {
  background-color: #242e3e;
}

body.layout-8 .pcoded-navbar.theme-horizontal.menu-light .header-logo .mobile-menu.on span {
  background-color: transparent;
}

body.layout-9 {
  background: #ecf4fb;
}

body.layout-9 .container {
  padding-left: 0;
  padding-right: 0;
}

body.layout-9 .pcoded-navbar.theme-horizontal {
  left: 0;
  right: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: -120px;
  box-shadow: none;
  background-color: transparent;
}

body.layout-9 .pcoded-navbar.theme-horizontal .navbar-content {
  box-shadow: none;
}

body.layout-9 .pcoded-navbar.theme-horizontal .pcoded-inner-navbar>li:last-child>a {
  margin-right: 0;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidenav-horizontal-next,
body.layout-9 .pcoded-navbar.theme-horizontal .sidenav-horizontal-prev {
  display: none;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidenav-horizontal:after,
body.layout-9 .pcoded-navbar.theme-horizontal .sidenav-horizontal:before {
  display: none;
}

body.layout-9 .pcoded-navbar.theme-horizontal~.pcoded-header {
  left: 0;
  right: 0;
  margin-left: auto;
}

body.layout-9 .pcoded-navbar.theme-horizontal~.pcoded-header .m-header {
  padding-left: 0;
}

body.layout-9 .pcoded-navbar.theme-horizontal~.pcoded-main-container {
  margin-top: 240px;
}

body.layout-9 .pcoded-navbar.theme-horizontal~.pcoded-main-container .pcoded-wrapper {
  background: #fff;
}

body.layout-9 .pcoded-navbar.theme-horizontal.top-nav-collapse~.pcoded-header {
  min-height: 55px;
}

body.layout-9 .pcoded-navbar.theme-horizontal.top-nav-collapse~.pcoded-header .dropdown .dropdown-toggle,
body.layout-9 .pcoded-navbar.theme-horizontal.top-nav-collapse~.pcoded-header .navbar-nav>li {
  line-height: 55px;
}

body.layout-9 .pcoded-navbar.theme-horizontal .layout9-nav {
  width: 100%;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidemenu li {
  display: inline-block;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidemenu li a {
  padding: 15px 25px;
  border-radius: 5px 5px 0 0;
  color: #242e3e;
  background: rgba(36, 46, 62, 0.15);
  margin-right: 4px;
  cursor: pointer;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidemenu li a i {
  display: none;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidemenu li:last-child a {
  margin-right: 0;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidemenu li.active a {
  background: #242e3e;
  color: #b5bdca;
}

body.layout-9 .pcoded-navbar.theme-horizontal.l9-full-menu .sidemenu li {
  flex: 1 1 auto;
  text-align: center;
}

body.layout-9 .pcoded-navbar.theme-horizontal .side-content {
  width: 100%;
  background: #242e3e;
  box-shadow: 0 4px 2px -3px rgba(69, 90, 100, 0.09);
  height: 70px;
  display: flex;
  align-items: center;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidelink {
  display: none;
}

body.layout-9 .pcoded-navbar.theme-horizontal .sidelink.active {
  display: inline-block;
}

body.layout-9 .pcoded-navbar.theme-horizontal.menu-light .sidemenu li a {
  background: rgba(36, 46, 62, 0.05);
}

body.layout-9 .pcoded-navbar.theme-horizontal.menu-light .sidemenu li.active a {
  background: #fff;
  color: #242e3e;
}

body.layout-9 .pcoded-navbar.theme-horizontal.menu-light .side-content {
  background: #fff;
  box-shadow: 0 4px 2px -3px rgba(69, 90, 100, 0.09);
  height: 70px;
}

@media only screen and (max-width: 991px) {
  body.layout-9 .pcoded-navbar {
    padding: 0;
    height: auto;
    min-height: 100%;
  }

  body.layout-9 .pcoded-navbar .navbar-content,
  body.layout-9 .pcoded-navbar .navbar-wrapper {
    height: auto;
    min-width: 100%;
  }

  body.layout-9 .pcoded-navbar.mob-open~.pcoded-header:before,
  body.layout-9 .pcoded-navbar.mob-open~.pcoded-main-container:before,
  body.layout-9 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
  body.layout-9 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
    background: 0 0;
  }

  body.layout-9 .pcoded-navbar .sidemenu {
    display: none;
  }
}

body.layout-11 {
  background: #ecf4fb;
}

body.layout-11 .card {
  box-shadow: 0 0 15px 0 rgba(69, 90, 100, 0.09);
}

@media only screen and (min-width: 992px) {
  body.layout-11 .pcoded-navbar {
    background: 0 0;
  }

  body.layout-11 .pcoded-navbar .navbar-content {
    background: #242e3e;
    left: 25px;
    margin-top: 25px;
    box-shadow: 0 0 15px 0 rgba(69, 90, 100, 0.09);
  }

  body.layout-11 .pcoded-navbar~.pcoded-main-container {
    margin-left: 289px;
  }

  body.layout-11 .pcoded-navbar~.pcoded-header {
    box-shadow: 0 1px 20px 0 rgba(63, 77, 103, 0.15);
    width: calc(100% - 264px);
    margin-left: 264px;
  }
}

body.layout-11 .pcoded-navbar.menupos-fixed .scroll-div.navbar-content {
  height: calc(100% - 120px);
  min-height: calc(100% - 120px);
}

body.layout-11 .pcoded-navbar .header-logo {
  width: 264px;
  margin-right: 0;
  padding: 10px 25px;
  background: #fff;
  box-shadow: -10px 1px 20px 0 rgba(63, 77, 103, 0.15);
}

body.layout-11 .pcoded-navbar .header-logo .mobile-menu {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

body.layout-11 .pcoded-navbar .header-logo .mobile-menu span {
  background-color: rgba(36, 46, 62, 0.8);
}

body.layout-11 .pcoded-navbar .header-logo .mobile-menu span:after,
body.layout-11 .pcoded-navbar .header-logo .mobile-menu span:before {
  background-color: rgba(36, 46, 62, 0.8);
}

body.layout-11 .pcoded-navbar .header-logo .mobile-menu:hover span {
  background-color: #242e3e;
}

body.layout-11 .pcoded-navbar .header-logo .mobile-menu:hover span:after,
body.layout-11 .pcoded-navbar .header-logo .mobile-menu:hover span:before {
  background-color: #242e3e;
}

body.layout-11 .pcoded-navbar .header-logo .mobile-menu.on span {
  background-color: transparent;
}

body.layout-11 .pcoded-navbar .header-logo .b-brand .b-title {
  color: #242e3e;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 1;
}

body.layout-11 .pcoded-navbar .pcoded-inner-navbar>li.active>a,
body.layout-11 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: 0 0;
}

body.layout-11 .pcoded-navbar .pcoded-inner-navbar>li.active:after,
body.layout-11 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger:after {
  display: none;
}

body.layout-11 .pcoded-navbar.mob-open~.pcoded-header:before,
body.layout-11 .pcoded-navbar.mob-open~.pcoded-main-container:before,
body.layout-11 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
body.layout-11 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
  background: 0 0;
}

body.layout-11 .pcoded-navbar.navbar-collapsed~.pcoded-main-container {
  margin-left: 95px;
}

body.layout-11 .pcoded-navbar.menu-light .navbar-content {
  background: #fff;
}

body.layout-11 .pcoded-navbar.navbar-blue .navbar-content {
  background: #2196f3;
}

body.layout-11 .pcoded-navbar.navbar-red .navbar-content {
  background: #f44336;
}

body.layout-11 .pcoded-navbar.navbar-purple .navbar-content {
  background: #7759de;
}

body.layout-11 .pcoded-navbar.navbar-info .navbar-content {
  background: #00acc1;
}

body.layout-11 .pcoded-navbar.navbar-dark .navbar-content {
  background: #242e3e;
}

body.layout-11 .pcoded-navbar.navbar-gradient-blue .navbar-content {
  background: linear-gradient(to bottom, #7759de 0%, #2196f3 100%);
}

body.layout-11 .pcoded-navbar.navbar-gradient-red .navbar-content {
  background: linear-gradient(to bottom, #a445b2 0%, #d41872 52%, #ff0066 100%);
}

body.layout-11 .pcoded-navbar.navbar-gradient-purple .navbar-content {
  background: linear-gradient(to top, #b275f0 0%, #7575f0 100%);
}

body.layout-11 .pcoded-navbar.navbar-gradient-info .navbar-content {
  background: linear-gradient(to bottom, #01a9ac 0%, #01dbdf 100%);
}

body.layout-11 .pcoded-navbar.navbar-gradient-dark .navbar-content {
  background: linear-gradient(to bottom, #242e3e 0%, #3a4c67 100%);
}

body.layout-11 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span,
body.layout-11 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:after,
body.layout-11 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:before {
  background: #fff;
}

body.layout-11 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu.on span {
  background: 0 0;
}

body.layout-11 .pcoded-navbar[class*="brand-"]:not(.brand-default) .header-logo .b-brand .b-title {
  color: #fff;
}

body.layout-11 .pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg {
  background: #fff;
  color: #2196f3;
}

body.layout-11 .pcoded-navbar.brand-blue .header-logo,
body.layout-11 .pcoded-navbar.menu-light.brand-blue .header-logo,
body.layout-11 .pcoded-navbar[class*="navbar-"].brand-blue .header-logo {
  background: #2196f3;
}

body.layout-11 .pcoded-navbar.brand-red .header-logo,
body.layout-11 .pcoded-navbar.menu-light.brand-red .header-logo,
body.layout-11 .pcoded-navbar[class*="navbar-"].brand-red .header-logo {
  background: #f44336;
}

body.layout-11 .pcoded-navbar.brand-purple .header-logo,
body.layout-11 .pcoded-navbar.menu-light.brand-purple .header-logo,
body.layout-11 .pcoded-navbar[class*="navbar-"].brand-purple .header-logo {
  background: #7759de;
}

body.layout-11 .pcoded-navbar.brand-info .header-logo,
body.layout-11 .pcoded-navbar.menu-light.brand-info .header-logo,
body.layout-11 .pcoded-navbar[class*="navbar-"].brand-info .header-logo {
  background: #00acc1;
}

body.layout-11 .pcoded-navbar.brand-dark .header-logo,
body.layout-11 .pcoded-navbar.menu-light.brand-dark .header-logo,
body.layout-11 .pcoded-navbar[class*="navbar-"].brand-dark .header-logo {
  background: #242e3e;
}

body.layout-11 .pcoded-navbar[class*="navbar-image"] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

body.layout-11 .pcoded-navbar[class*="navbar-image"]:before {
  display: none;
}

body.layout-11 .pcoded-navbar[class*="navbar-image"] .navbar-content .pcoded-inner-navbar {
  position: relative;
}

body.layout-11 .pcoded-navbar[class*="navbar-image"] .navbar-content .pcoded-inner-navbar:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}

body.layout-11 .pcoded-navbar.navbar-image-1 .navbar-content {
  background-image: url(../images/nav-bg/navbar-img-1.jpg);
}

body.layout-11 .pcoded-navbar.navbar-image-2 .navbar-content {
  background-image: url(../images/nav-bg/navbar-img-2.jpg);
}

body.layout-11 .pcoded-navbar.navbar-image-3 .navbar-content {
  background-image: url(../images/nav-bg/navbar-img-3.jpg);
}

body.layout-11 .pcoded-navbar.navbar-image-4 .navbar-content {
  background-image: url(../images/nav-bg/navbar-img-4.jpg);
}

body.layout-11 .pcoded-navbar.navbar-image-5 .navbar-content {
  background-image: url(../images/nav-bg/navbar-img-5.jpg);
}

body.layout-11 .pcoded-navbar[class*="navbar-pattern"] {
  background-size: auto;
  background-repeat: repeat;
}

body.layout-11 .pcoded-navbar[class*="navbar-pattern"]:before {
  display: none;
}

body.layout-11 .pcoded-navbar[class*="navbar-pattern"] .pcoded-inner-navbar {
  position: relative;
}

body.layout-11 .pcoded-navbar[class*="navbar-pattern"] .pcoded-inner-navbar>li.active>a,
body.layout-11 .pcoded-navbar[class*="navbar-pattern"] .pcoded-inner-navbar>li.pcoded-trigger>a {
  background: rgba(25, 32, 43, 0.4);
}

body.layout-11 .pcoded-navbar[class*="navbar-pattern"] .pcoded-inner-navbar:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
}

body.layout-11 .pcoded-navbar.navbar-pattern-1 .navbar-content {
  background-image: url(../images/bg-images/1.jpg);
}

body.layout-11 .pcoded-navbar.navbar-pattern-2 .navbar-content {
  background-image: url(../images/bg-images/2.jpg);
}

body.layout-11 .pcoded-navbar.navbar-pattern-3 .navbar-content {
  background-image: url(../images/bg-images/3.jpg);
}

body.layout-11 .pcoded-navbar.navbar-pattern-4 .navbar-content {
  background-image: url(../images/bg-images/4.jpg);
}

body.layout-11 .pcoded-navbar.navbar-pattern-5 .navbar-content {
  background-image: url(../images/bg-images/5.jpg);
}

body.layout-11 .pcoded-navbar.navbar-pattern-6 .navbar-content {
  background-image: url(../images/bg-images/6.jpg);
}

@media only screen and (min-width: 992px) {
  body.layout-12 .pcoded-navbar {
    background: #fff;
    box-shadow: inset 0 1px 20px 0 rgba(63, 77, 103, 0.15);
  }

  body.layout-12 .pcoded-navbar:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(33, 150, 243, 0.1);
  }

  body.layout-12 .pcoded-navbar .navbar-content {
    box-shadow: none;
  }

  body.layout-12 .pcoded-navbar .pcoded-inner-navbar .pcoded-submenu {
    background: 0 0;
  }

  body.layout-12 .pcoded-navbar .pcoded-inner-navbar>li.active>a,
  body.layout-12 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger>a {
    background: 0 0;
  }

  body.layout-12 .pcoded-navbar .pcoded-inner-navbar>li.active:after,
  body.layout-12 .pcoded-navbar .pcoded-inner-navbar>li.pcoded-trigger:after {
    display: none;
  }

  body.layout-12 .pcoded-navbar~.pcoded-main-container {
    box-shadow: -20px 0 20px -20px rgba(204, 205, 206, 0.4);
    background: #fff;
  }

  body.layout-12 .pcoded-navbar.mob-open~.pcoded-header,
  body.layout-12 .pcoded-navbar.mob-open~.pcoded-main-container,
  body.layout-12 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header,
  body.layout-12 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container {
    margin-left: 264px;
  }

  body.layout-12 .pcoded-navbar.mob-open~.pcoded-header:before,
  body.layout-12 .pcoded-navbar.mob-open~.pcoded-main-container:before,
  body.layout-12 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
  body.layout-12 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
    background: 0 0;
  }

  body.layout-12 .pcoded-navbar.mob-open~.pcoded-header,
  body.layout-12 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header {
    width: calc(100% - 264px);
  }
}

body.layout-12 .pcoded-navbar .header-logo {
  margin-right: 0;
  background: 0 0;
  box-shadow: none;
}

body.layout-12 .pcoded-navbar .header-logo .mobile-menu span {
  background-color: rgba(36, 46, 62, 0.8);
}

body.layout-12 .pcoded-navbar .header-logo .mobile-menu span:after,
body.layout-12 .pcoded-navbar .header-logo .mobile-menu span:before {
  background-color: rgba(36, 46, 62, 0.8);
}

body.layout-12 .pcoded-navbar .header-logo .mobile-menu:hover span {
  background-color: #242e3e;
}

body.layout-12 .pcoded-navbar .header-logo .mobile-menu:hover span:after,
body.layout-12 .pcoded-navbar .header-logo .mobile-menu:hover span:before {
  background-color: #242e3e;
}

body.layout-12 .pcoded-navbar .header-logo .mobile-menu.on span {
  background-color: transparent;
}

body.layout-12 .pcoded-navbar .header-logo .b-brand .b-title {
  color: #242e3e;
}

body.layout-12 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span,
body.layout-12 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:after,
body.layout-12 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:before {
  background: #fff;
}

body.layout-12 .pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu.on span {
  background: 0 0;
}

body.layout-12 .pcoded-navbar[class*="brand-"]:not(.brand-default) .header-logo .b-brand .b-title {
  color: #fff;
}

body.layout-12 .pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg {
  background: #fff;
  color: #2196f3;
}

body.layout-12 .pcoded-navbar.brand-blue .header-logo,
body.layout-12 .pcoded-navbar.menu-light.brand-blue .header-logo,
body.layout-12 .pcoded-navbar[class*="navbar-"].brand-blue .header-logo {
  background: #2196f3;
}

body.layout-12 .pcoded-navbar.brand-blue:before {
  background: rgba(33, 150, 243, 0.1);
}

body.layout-12 .pcoded-navbar.brand-red .header-logo,
body.layout-12 .pcoded-navbar.menu-light.brand-red .header-logo,
body.layout-12 .pcoded-navbar[class*="navbar-"].brand-red .header-logo {
  background: #f44336;
}

body.layout-12 .pcoded-navbar.brand-red:before {
  background: rgba(244, 67, 54, 0.1);
}

body.layout-12 .pcoded-navbar.brand-purple .header-logo,
body.layout-12 .pcoded-navbar.menu-light.brand-purple .header-logo,
body.layout-12 .pcoded-navbar[class*="navbar-"].brand-purple .header-logo {
  background: #7759de;
}

body.layout-12 .pcoded-navbar.brand-purple:before {
  background: rgba(119, 89, 222, 0.1);
}

body.layout-12 .pcoded-navbar.brand-info .header-logo,
body.layout-12 .pcoded-navbar.menu-light.brand-info .header-logo,
body.layout-12 .pcoded-navbar[class*="navbar-"].brand-info .header-logo {
  background: #00acc1;
}

body.layout-12 .pcoded-navbar.brand-info:before {
  background: rgba(0, 172, 193, 0.1);
}

body.layout-12 .pcoded-navbar.brand-dark .header-logo,
body.layout-12 .pcoded-navbar.menu-light.brand-dark .header-logo,
body.layout-12 .pcoded-navbar[class*="navbar-"].brand-dark .header-logo {
  background: #242e3e;
}

body.layout-12 .pcoded-navbar.brand-dark:before {
  background: rgba(36, 46, 62, 0.1);
}

body.layout-13 .pcoded-main-container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 130px;
  left: 0;
  top: -1px;
  z-index: 1;
  background: #fff;
  box-shadow: inset 0 -1px 4px 0 rgba(63, 77, 103, 0.15);
}

body.layout-13 .pcoded-main-container .pcoded-wrapper {
  position: relative;
  z-index: 3;
}

body.layout-13 .pcoded-navbar .navbar-content {
  box-shadow: none;
}

body.layout-13 .pcoded-navbar[class*="navbar-"]~.pcoded-main-container .page-header .breadcrumb-item+.breadcrumb-item::before,
body.layout-13 .pcoded-navbar[class*="navbar-"]~.pcoded-main-container .page-header .page-header-title+.breadcrumb>.breadcrumb-item a {
  color: rgba(255, 255, 255, 0.8);
}

body.layout-13 .pcoded-navbar[class*="navbar-"]~.pcoded-main-container .page-header .page-header .page-header-title+.breadcrumb>.breadcrumb-item:last-child a,
body.layout-13 .pcoded-navbar[class*="navbar-"]~.pcoded-main-container .page-header h5 {
  color: #fff;
}

body.layout-13 .pcoded-navbar.navbar-blue~.pcoded-main-container:after {
  background: #2196f3;
}

body.layout-13 .pcoded-navbar.navbar-red~.pcoded-main-container:after {
  background: #f44336;
}

body.layout-13 .pcoded-navbar.navbar-purple~.pcoded-main-container:after {
  background: #7759de;
}

body.layout-13 .pcoded-navbar.navbar-info~.pcoded-main-container:after {
  background: #00acc1;
}

body.layout-13 .pcoded-navbar.navbar-dark~.pcoded-main-container:after {
  background: #242e3e;
}

@media only screen and (max-width: 992px) {

  body.layout-13 .pcoded-navbar.mob-open~.pcoded-header:before,
  body.layout-13 .pcoded-navbar.mob-open~.pcoded-main-container:before {
    z-index: 5;
  }
}

body.layout-14 .navbar-popup {
  position: absolute;
  top: 70px;
  left: 279px;
  width: 264px;
  height: auto;
  box-shadow: 0 0 15px 0 rgba(69, 90, 100, 0.09);
  display: none;
}

body.layout-14 .navbar-popup>.pcoded-submenu {
  border-radius: 5px;
  background: #242e3e;
}

body.layout-14 .navbar-popup:after {
  content: "\6a";
  position: absolute;
  top: 8px;
  left: -31px;
  font-family: pct;
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  color: #242e3e;
  width: 40px;
  height: 100%;
  text-shadow: -8px 0 13px rgba(62, 57, 107, 0.08);
  display: flex;
  align-items: flex-start;
}

body.layout-14 .navbar-popup.vedge:after {
  top: -8px;
  align-items: flex-end;
}

body.layout-14 .pcoded-navbar.navbar-collapsed .navbar-popup {
  left: 85px;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover {
  width: 70px !important;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header:before,
body.layout-14 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container:before {
  display: none;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .b-brand {
  display: none;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu {
  right: auto;
  left: 20px;
  transition-delay: 0s;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu span {
  background: 0 0;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span {
  background-color: transparent;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:after,
body.layout-14 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:before {
  height: 2px;
  width: 100%;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
  transform: rotate(45deg) translate(4px, 4px);
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .mobile-menu span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
  transform: rotate(-45deg) translate(3px, -3px);
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .header-logo {
  width: 70px;
  padding: 10px 20px;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .header-logo img {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: unset;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .header-logo .logo-thumb {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 1;
  left: calc((70px / 2) - 20px);
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .navbar-content.ps {
  overflow: visible;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption>label {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 32px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(181, 189, 202, 0.5);
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a {
  padding: 7px 20px;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a>.pcoded-micon+.pcoded-mtext {
  position: absolute;
  top: 11px;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li>a>.pcoded-mtext {
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-hasmenu>a:after {
  right: 12px;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu:after {
  content: "";
  position: absolute;
  top: 50px;
  left: calc(calc(70px / 2) + 2px);
  width: 1px;
  height: calc(100% - 50px);
  background: rgba(181, 189, 202, 0.2);
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu li a {
  color: transparent;
  white-space: nowrap;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar>li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 0;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header {
  width: calc(100% - 70px);
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover~.pcoded-header,
body.layout-14 .pcoded-navbar.navbar-collapsed:hover~.pcoded-main-container {
  margin-left: 70px;
}

body.layout-14 .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
  display: none;
  transition: all 0.15s ease-in-out;
}

body.layout-14 .pcoded-navbar.menu-light .navbar-popup>.pcoded-submenu {
  background: #fff;
}

body.layout-14 .pcoded-navbar.menu-light .navbar-popup:after {
  color: #fff;
}

body.layout-14 .pcoded-navbar.navbar-blue .navbar-popup>.pcoded-submenu {
  background: #2196f3;
}

body.layout-14 .pcoded-navbar.navbar-blue .navbar-popup:after {
  color: #2196f3;
}

body.layout-14 .pcoded-navbar.navbar-red .navbar-popup>.pcoded-submenu {
  background: #f44336;
}

body.layout-14 .pcoded-navbar.navbar-red .navbar-popup:after {
  color: #f44336;
}

body.layout-14 .pcoded-navbar.navbar-purple .navbar-popup>.pcoded-submenu {
  background: #7759de;
}

body.layout-14 .pcoded-navbar.navbar-purple .navbar-popup:after {
  color: #7759de;
}

body.layout-14 .pcoded-navbar.navbar-info .navbar-popup>.pcoded-submenu {
  background: #00acc1;
}

body.layout-14 .pcoded-navbar.navbar-info .navbar-popup:after {
  color: #00acc1;
}

body.layout-14 .pcoded-navbar.navbar-dark .navbar-popup>.pcoded-submenu {
  background: #242e3e;
}

body.layout-14 .pcoded-navbar.navbar-dark .navbar-popup:after {
  color: #242e3e;
}

.pcoded-navbar .card {
  background: rgba(255, 255, 255, 0.1);
  margin: 20px;
  box-shadow: 0 0 0 1px rgba(226, 229, 232, 0.15);
  color: rgba(255, 255, 255, 0.6);
}

.pcoded-navbar .card h6 {
  color: rgba(255, 255, 255, 0.6);
}

.pcoded-navbar .card .close {
  position: absolute;
  color: #b5bdca;
  position: absolute;
  top: 10px;
  right: 12px;
  opacity: 0.8;
  text-shadow: none;
}

.pcoded-navbar.menu-light .card {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px #eff3f6;
  color: rgba(0, 0, 0, 0.6);
}

.pcoded-navbar.menu-light .card h6 {
  color: #242e3e;
}

.pcoded-navbar.menu-light .card .close {
  color: #242e3e;
}

.footer-fab {
  position: fixed;
  bottom: 70px;
  right: 70px;
  z-index: 1028;
}

.footer-fab .b-bg {
  background: #f44336;
  box-shadow: 0 0 8px rgba(244, 67, 54, 0.9), 6px 0 8px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}

.footer-fab .fab-hover {
  position: absolute;
  bottom: 100%;
  right: 0;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transition: all 0.2 linear;
}

.footer-fab .fab-hover ul li {
  margin: 10px 0;
}

.footer-fab .fab-hover ul li a {
  position: relative;
}

.footer-fab .fab-hover ul li a:after {
  content: attr(data-text);
  position: absolute;
  padding: 4px 8px;
  background: #242e3e;
  color: #fff;
  border-radius: 4px;
  top: 5px;
  right: calc(100% + 8px);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.footer-fab .fab-hover ul li a:hover:after {
  opacity: 1;
}

.footer-fab .fab-hover ul li a.btn-success.btn-envato {
  background-color: #79b530;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-fab:hover .fab-hover {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.mrr-card {
  position: relative;
}

.mrr-card .bottom-chart {
  bottom: 0;
}

.card-body-big {
  padding: 2em;
}

.borderless-card {
  border-top: none;
}

.widget-main-card {
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(43, 43, 43, 0.2);
}

.counter-card-1 h3,
.counter-card-2 h3,
.counter-card-3 h3 {
  margin-bottom: 16px;
  font-weight: 600;
  color: #666;
}

.counter-card-1 [class*="card-"]>i,
.counter-card-2 [class*="card-"]>i,
.counter-card-3 [class*="card-"]>i {
  position: absolute;
  color: #2196f3;
  right: 30px;
  top: 30px;
  font-size: 28px;
}

.counter-card-1 span.f-right,
.counter-card-2 span.f-right,
.counter-card-3 span.f-right {
  font-size: 16px;
  font-weight: 600;
}

.counter-card-2 {
  border-top-color: #4caf50;
}

.counter-card-2 [class*="card-"]>i {
  color: #4caf50;
}

.counter-card-3 {
  border-top-color: #d6d6d6;
}

.counter-card-3 [class*="card-"]>i {
  color: #d6d6d6;
}

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}

.table-card .row-table:first-child {
  border-bottom: 1px solid #ddd;
}

.table-card .row-table .br {
  border-right: 1px solid #ccc;
}

.table-card .row-table i {
  font-size: 28px;
}

.table-card .row-table span {
  text-transform: uppercase;
  font-size: 12px;
}

.table-card .row-table h5 {
  display: block;
  margin-bottom: 0.3em;
  margin-right: 0;
}

.table-card .row-table>[class*="col-"] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}

.table-card .row-table>[class*="col-"] .row {
  display: flex;
  align-items: center;
}

.widget-primary-card.table-card,
.table-card.widget-purple-card {
  border-top: none;
  background-color: #2196f3;
  color: #fff;
}

.widget-primary-card.table-card h4,
.table-card.widget-purple-card h4,
.widget-primary-card.table-card h6,
.table-card.widget-purple-card h6 {
  color: #fff;
}

.widget-primary-card.table-card .row-table:first-child,
.table-card.widget-purple-card .row-table:first-child {
  border-bottom: none;
}

.widget-primary-card.table-card .row-table>[class*="col-"],
.table-card.widget-purple-card .row-table>[class*="col-"] {
  display: inline-block;
  vertical-align: middle;
}

.widget-primary-card.table-card .row-table>[class*="col-"]:first-child,
.table-card.widget-purple-card .row-table>[class*="col-"]:first-child {
  background-color: #0d8aee;
  text-align: center;
}

.widget-primary-card.table-card .row-table>[class*="col-"] .row,
.table-card.widget-purple-card .row-table>[class*="col-"] .row {
  display: flex;
  align-items: center;
}

.widget-purple-card.table-card {
  background-color: #7759de;
}

.widget-purple-card.table-card .row-table:first-child {
  border-bottom: none;
}

.widget-purple-card.table-card .row-table>[class*="col-"]:first-child {
  background-color: #6644da;
}

.social-widget-card {
  border-top: none;
  color: #fff;
}

.social-widget-card:hover i {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.social-widget-card i {
  position: absolute;
  right: 10px;
  top: 50px;
  font-size: 48px;
  opacity: 0.4;
  transition: all ease-in 0.3s;
}

.widget-profile-card-1 {
  position: relative;
  margin-bottom: 50px;
}

.widget-profile-card-1 .middle-user {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  bottom: -60px;
}

.widget-profile-card-1 .middle-user img {
  width: 115px;
  border-radius: 50%;
}

.widget-profile-card-1~.card-footer [class*="col-"] {
  border-right: 1px solid #fff;
}

.widget-profile-card-1~.card-footer [class*="col-"]:last-child {
  border: none;
}

@media only screen and (max-width: 767px) {
  .widget-profile-card-1~.card-footer [class*="col-"]:nth-child(2) {
    border: 0;
  }

  .widget-profile-card-1~.card-footer [class*="col-"]:last-child {
    margin-top: 10px;
  }
}

.widget-profile-card-2 img.user-img {
  width: 115px;
}

.widget-profile-card-2 a {
  color: #fff;
  transition: all ease-in 0.3s;
}

.widget-profile-card-2 a:hover {
  opacity: 0.7;
}

.widget-profile-card-3 {
  background-image: url(../images/widget/slider5.jpg);
  background-size: cover;
  padding: 50px 0;
  text-align: center;
}

.widget-profile-card-3 img {
  width: 120px;
  border-radius: 50%;
}

.profile-card {
  position: relative;
  min-height: 410px;
  overflow: hidden;
}

.profile-card img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-card .card-body {
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.72));
}

.profile-card .card-body h3 {
  font-weight: 600;
}

.profile-card~.card-footer [class*="col-"] {
  padding: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.57);
}

.profile-card~.card-footer [class*="col-"] h4 {
  font-size: 18px;
  font-weight: 600;
}

.profile-card~.card-footer [class*="col-"]:last-child {
  border: none;
}

.profile-card~.card-footer [class*="col-"] span {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .profile-card~.card-footer [class*="col-"]:nth-child(2) {
    border: none;
  }
}

.weather-bg-card {
  position: relative;
}

.weather-bg-card img {
  width: 100%;
}

.weather-bg-card .card-body {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.weather-bg-card h5 {
  color: #fff;
}

.weather-bg-card .card-body-big {
  position: absolute;
  top: 0;
}

.weather-bg-card .card-body-big span {
  color: #fff;
}

.weather-bg-card .weather-temp {
  font-size: 70px;
  color: #fff;
}

.weather-bg-card~.card-footer {
  border-top: none;
}

@media only screen and (max-width: 575px) {
  .weather-bg-card~.card-footer [class*="col-"] {
    margin-bottom: 10px;
  }
}

.blur-user-card {
  color: #fff;
  text-align: center;
  padding-top: 45px;
  background-image: url(../images/widget/blur-bg.png);
  background-size: cover;
}

.blur-user-card h3 {
  margin-top: 10px;
  font-size: 30px;
  font-weight: 600;
}

.blur-user-card p {
  font-size: 18px;
  font-weight: 300;
}

.blur-user-card .blur-footer [class*="col-"] {
  padding: 1em;
  border-right: 1px solid #fff;
  margin-top: 30px;
  margin-bottom: 30px;
}

.blur-user-card .blur-footer [class*="col-"]:last-child {
  border-right: none;
}

@media only screen and (max-width: 767px) {
  .blur-user-card .blur-footer [class*="col-"]:nth-child(2) {
    border: 0;
  }

  .blur-user-card .blur-footer [class*="col-"]:last-child {
    margin-top: 0;
    padding-top: 0;
  }
}

.widget-chat-box .card-header i {
  font-size: 17px;
  color: #263238;
  position: relative;
}

.widget-chat-box .card-header .pop-up:after {
  content: "";
  position: absolute;
  background-color: #f44336;
  height: 8px;
  width: 8px;
  border-radius: 50px;
  right: -3px;
}

.widget-chat-box .send-chat,
.widget-chat-box .receive-chat {
  position: relative;
  background: #b2dbfb;
  padding: 7px;
  border-radius: 0 10px 10px 10px;
  font-size: 13px;
  margin-bottom: 30px;
}

.widget-chat-box .send-chat:before,
.widget-chat-box .receive-chat:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border: 6px solid transparent;
  border-top: 6px solid #b2dbfb;
  border-right: 6px solid #b2dbfb;
  left: -12px;
  top: 0;
}

.widget-chat-box .send-chat .time,
.widget-chat-box .receive-chat .time {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.widget-chat-box .receive-chat {
  background-color: #2196f3;
  color: #fff;
  border-radius: 10px;
}

.widget-chat-box .receive-chat:before {
  display: none;
}

.widget-chat-box .receive-chat .time {
  color: #666;
}

.widget-chat-box .rc-10 {
  margin-bottom: 10px;
}

.table-card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.table-card .card-body .table>thead>tr>th {
  border-top: 0;
}

.table-card .card-body .table tr td:first-child,
.table-card .card-body .table tr th:first-child {
  padding-left: 25px;
}

.table-card .card-body .table tr td:last-child,
.table-card .card-body .table tr th:last-child {
  padding-right: 25px;
}

.table-card .card-body .table.without-header tr:first-child td {
  border-top: none;
}

.proj-progress-card .progress {
  height: 6px;
  overflow: visible;
  margin-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .proj-progress-card h6 {
    margin-top: 15px;
  }

  .proj-progress-card .progress {
    margin-bottom: 20px;
  }
}

.product-progress-card .progress {
  height: 6px;
}

.product-progress-card .pp-cont {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.product-progress-card .pp-cont:after {
  content: "";
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -15px;
}

.product-progress-card .pp-main>div:first-child .pp-cont:after {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .product-progress-card .pp-main>div:nth-child(3) .pp-cont:after {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .product-progress-card .pp-cont {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .product-progress-card .pp-cont:after {
    display: none;
  }
}

.new-cust-card img {
  width: 40px;
}

.new-cust-card h6 {
  margin-bottom: 0;
}

.new-cust-card .align-middle {
  position: relative;
}

.new-cust-card .align-middle .status {
  position: absolute;
  right: 0;
  top: 19px;
  font-size: 13px;
}

.new-cust-card .align-middle .status.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4caf50;
}

.table-card .row-table {
  display: flex;
  align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}

.table-card .row-table:first-child {
  border-bottom: 1px solid #ddd;
}

.table-card .row-table .br {
  border-right: 1px solid #ccc;
}

.table-card .row-table i {
  font-size: 28px;
}

.table-card .row-table span {
  text-transform: uppercase;
  font-size: 12px;
}

.table-card .row-table h5 {
  display: block;
  margin-bottom: 0.3em;
  margin-right: 0;
}

.table-card .row-table>[class*="col-"] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}

.table-card .row-table>[class*="col-"] .row {
  display: flex;
  align-items: center;
}

.latest-update-card .card-body {
  padding-top: 0;
}

.latest-update-card .card-body .latest-update-box {
  position: relative;
}

.latest-update-card .card-body .latest-update-box:after {
  content: "";
  position: absolute;
  background: #ebebeb;
  height: 100%;
  width: 3px;
  top: 0;
  left: 11px;
  z-index: 1;
}

.latest-update-card .card-body .latest-update-box .update-meta {
  z-index: 2;
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  padding: 7px 8px;
  font-size: 16px;
  color: #fff;
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon.bg-c-red {
  box-shadow: 0 0 0 4px rgba(244, 67, 54, 0.5);
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon.bg-c-blue {
  box-shadow: 0 0 0 4px rgba(33, 150, 243, 0.5);
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon.bg-c-green {
  box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.5);
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon.bg-c-yellow {
  box-shadow: 0 0 0 4px rgba(255, 152, 0, 0.5);
}

.latest-update-card .card-body .latest-update-box .update-meta .update-icon.ring {
  border-width: 3px;
  width: 15px;
  height: 15px;
  padding: 0;
  display: block;
  background: #fff;
}

.latest-update-card .card-body .latest-update-box .update-meta i.update-icon {
  margin-left: 4px;
  margin-right: 4px;
}

.latest-update-card .card-body .latest-update-box .update-meta img.update-icon {
  padding: 0;
  width: 40px;
  height: 40px;
}

.latest-update-card.update-card .card-body .latest-update-box:after {
  left: 19px;
}

.testimonial-card .progress {
  height: 5px;
}

.testimonial-card .review-block .cust-img {
  width: 40px;
  height: 40px;
}

.testimonial-card .review-block>div {
  padding-top: 15px;
  padding-bottom: 9px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.testimonial-card .review-block>div:hover {
  background-color: rgba(33, 150, 243, 0.1);
}

.feed-card h6 {
  margin-top: 7px;
}

.feed-card .feed-icon {
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 8px 9px;
}

.chat-card .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 10px;
  position: relative;
}

.chat-card .msg img {
  width: 60px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 10px;
}

.chat-card .received-chat .msg {
  background: rgba(33, 150, 243, 0.1);
  border-radius: 0 5px 5px 5px;
  box-shadow: 2px 3px 7px 0 rgba(43, 43, 43, 0.15);
}

.chat-card .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-bottom-color: rgba(33, 150, 243, 0.1);
}

.chat-card .send-chat,
.chat-card .widget-chat-box .receive-chat,
.widget-chat-box .chat-card .receive-chat {
  text-align: right;
}

.chat-card .send-chat .msg,
.chat-card .widget-chat-box .receive-chat .msg,
.widget-chat-box .chat-card .receive-chat .msg {
  background: rgba(76, 175, 80, 0.1);
  border-radius: 5px 0 5px 5px;
  box-shadow: -2px 3px 7px 0 rgba(43, 43, 43, 0.15);
}

.chat-card .send-chat .msg:after,
.chat-card .widget-chat-box .receive-chat .msg:after,
.widget-chat-box .chat-card .receive-chat .msg:after {
  content: "";
  position: absolute;
  right: -7px;
  top: -7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 7px solid transparent;
  border-right-color: rgba(76, 175, 80, 0.1);
}

.proj-t-card {
  position: relative;
  overflow: hidden;
}

.proj-t-card .pt-badge {
  color: #fff;
  margin-bottom: 0;
  display: inline-block;
  padding: 60px 50px 20px 20px;
  border-radius: 50%;
  position: absolute;
  top: -45px;
  right: -35px;
  transition: all 0.3s ease-in-out;
}

.comp-card i {
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  padding: 17px 0;
  font-size: 18px;
}

.prod-p-card i {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  padding: 11px 0;
  font-size: 18px;
}

.ticket-card .lbl-card {
  border-radius: 50px;
  padding: 5px 15px;
  color: #fff;
  display: inline-block;
}

.analytic-card .analytic-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 17px 0;
  border-radius: 50%;
  background-color: #fff;
}

.social-res-card .progress {
  height: 6px;
}

.product-progress-card .progress {
  height: 6px;
}

.product-progress-card .pp-cont {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.product-progress-card .pp-cont:after {
  content: "";
  background: #d2d2d2;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -15px;
}

.select2-container {
  width: 100% !important;
}

.user-card .cover-img-block {
  position: relative;
}

.user-card .cover-img-block .change-cover {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 5;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.user-card .cover-img-block .change-cover .dropdown-toggle {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 20px;
}

.user-card .cover-img-block .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
}

.user-card .cover-img-block:hover .change-cover,
.user-card .cover-img-block:hover .overlay {
  opacity: 1;
}

.user-card .user-about-block {
  margin-top: -30px;
}

.user-card .user-about-block img {
  box-shadow: 0 0 0 5px #fff;
}

.user-card .user-about-block .certificated-badge {
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #fff;
  padding: 5px 3px;
}

.user-card .user-about-block .certificated-badge .bg-icon {
  font-size: 22px;
}

.user-card .user-about-block .certificated-badge .front-icon {
  font-size: 11px;
  position: absolute;
  top: 11px;
  left: 9px;
}

.user-card .hover-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 46, 62, 0.92);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.user-card:hover .hover-data {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.user-card .change-profile .dropdown-toggle::after {
  display: none;
}

.user-card .change-profile .profile-dp {
  position: relative;
  overflow: hidden;
  padding: 5px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.user-card .change-profile .profile-dp .overlay {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  opacity: 0;
  z-index: 1;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
}

.user-card .change-profile .profile-dp .overlay span {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 0 5px;
}

.user-card .change-profile .profile-dp:hover .overlay {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .user-card .user-about-block {
    margin-top: -10px;
  }

  .user-card .wid-80 {
    width: 60px;
  }

  .user-card .change-profile .profile-dp {
    width: 90px;
    height: 90px;
  }

  .user-card.user-card-2.shape-right .cover-img-block {
    -webkit-clip-path: none;
    clip-path: none;
  }

  .user-card .cover-img-block .change-cover .dropdown-toggle {
    padding: 5px 7px;
  }
}

.user-card-2 .cover-img-block {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 80%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 80%);
}

.user-card-2.shape-right .cover-img-block {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
}

.user-card-2.shape-center .cover-img-block {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0% 80%);
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 50% 100%, 0% 80%);
}

.user-card-3 .certificated-badge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #fff;
  padding: 5px 3px;
}

.user-card-3 .certificated-badge .bg-icon {
  font-size: 30px;
}

.user-card-3 .certificated-badge .front-icon {
  font-size: 14px;
  position: absolute;
  top: 14px;
  left: 13px;
}

.user-card-3 .social-top-link {
  position: absolute;
  top: 20px;
  left: 20px;
}

.user-card-3.social-hover .social-top-link {
  top: 20px;
  left: 20px;
  overflow: hidden;
}

.user-card-3.social-hover .social-top-link .btn,
.user-card-3.social-hover .social-top-link li {
  margin-left: -100px;
  transition: all 0.2s ease-in-out;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(1),
.user-card-3.social-hover .social-top-link li:nth-child(1) {
  transition-delay: 0.05s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(2),
.user-card-3.social-hover .social-top-link li:nth-child(2) {
  transition-delay: 0.1s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(3),
.user-card-3.social-hover .social-top-link li:nth-child(3) {
  transition-delay: 0.15s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(4),
.user-card-3.social-hover .social-top-link li:nth-child(4) {
  transition-delay: 0.2s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(5),
.user-card-3.social-hover .social-top-link li:nth-child(5) {
  transition-delay: 0.25s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(6),
.user-card-3.social-hover .social-top-link li:nth-child(6) {
  transition-delay: 0.3s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(7),
.user-card-3.social-hover .social-top-link li:nth-child(7) {
  transition-delay: 0.35s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(8),
.user-card-3.social-hover .social-top-link li:nth-child(8) {
  transition-delay: 0.4s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(9),
.user-card-3.social-hover .social-top-link li:nth-child(9) {
  transition-delay: 0.45s;
}

.user-card-3.social-hover .social-top-link .btn:nth-child(10),
.user-card-3.social-hover .social-top-link li:nth-child(10) {
  transition-delay: 0.5s;
}

.user-card-3.social-hover:hover .social-top-link .btn,
.user-card-3.social-hover:hover .social-top-link li {
  margin-left: 0;
}

.rating-bar .br-theme-bars-1to10 .br-readonly a.br-active,
.rating-bar .br-theme-bars-1to10 .br-readonly a.br-selected {
  background-color: #4caf50;
}

.rating-bar .br-theme-bars-1to10 .br-widget {
  height: 30px;
}

.rating-bar .br-theme-bars-1to10 .br-widget a {
  background-color: rgba(57, 181, 74, 0.2);
  width: 8px;
}

.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd;
}

.statstic-data-card .active.carousel-item-right,
.statstic-data-card .carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.statstic-data-card .active.carousel-item-left,
.statstic-data-card .carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.statstic-data-card .card-body {
  border-top: 2px solid transparent;
}

.statstic-data-card .card-body.level-down {
  border-color: #f44336;
}

.statstic-data-card .card-body.level-up {
  border-color: #4caf50;
}

.ck-content strong {
  font-weight: 600;
}

.stastic-slider-full-card .carousel-item {
  transition: transform 12s linear, -webkit-transform 12s linear;
}

.dt-button-collection {
  padding: 0;
  margin: 0;
}

.docs-options .dropdown-menu>li:active,
.docs-options .dropdown-menu>li:hover {
  color: #111 !important;
}

.bootstrap-tagsinput input {
  color: #fff;
}

.popover {
  z-index: 1020;
}

.support-bar [class*="col"] {
  border-right: 1px solid #fff;
}

.support-bar [class*="col"]:last-child {
  border: 0;
}

.support-bar1 [class*="col"] {
  border-right: 1px solid #e2e5e8;
}

.support-bar1 [class*="col"]:last-child {
  border: 0;
}

.custom-select,
.form-control {
  background: #eff3f6;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;
}

.custom-select:focus,
.form-control:focus {
  background: #eff3f6;
}

.custom-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #aaaeb3;
  opacity: 1;
}

.custom-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #aaaeb3;
}

.custom-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #aaaeb3;
}

.input-group {
  background: #eff3f6;
}

.input-group .form-control {
  padding: 8px 20px;
}

.input-group .input-group-text {
  padding-left: 15px;
  padding-right: 15px;
  background: 0 0;
}

.input-group .input-group-text i {
  font-size: 16px;
}

.input-group .custom-file-label {
  margin-bottom: 0;
}

.input-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.cust-file-button .custom-file-label {
  height: calc(2.25rem + 8px);
  line-height: 2.5;
}

.cust-file-button .custom-file-label::after {
  padding: 0.775rem 0.75rem;
  height: 2.65rem;
}

.custom-select {
  height: calc(2.25rem + 9px);
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}

.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors .form-control {
  padding: 6px 12px 5px 44px;
}

.select2-container {
  width: 100% !important;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
}

.custom-file-label {
  height: calc(1.5em + 0.75rem + 5px);
}

.custom-file-label:after {
  height: calc(1.5em + 0.75rem + 2.5px);
}

.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}

.checkbox input[type="checkbox"] {
  margin: 0;
  display: none;
  width: 22px;
}

.checkbox input[type="checkbox"]+.cr {
  padding-left: 0;
}

.checkbox input[type="checkbox"]+.cr:before {
  content: "\e83f";
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 3px;
  font-size: 15px;
  font-family: feather;
  font-weight: 400;
  line-height: 19px;
  vertical-align: bottom;
  text-align: center;
  background: #fff;
  color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.checkbox input[type="checkbox"]:checked+.cr:before {
  background: #1dd5d2;
  border-color: #1dd5d2;
  color: #fff;
}

.checkbox input[type="checkbox"].disabled+.cr,
.checkbox input[type="checkbox"]:disabled+.cr {
  opacity: 0.5;
}

.checkbox input[type="checkbox"].disabled+.cr:before,
.checkbox input[type="checkbox"]:disabled+.cr:before {
  cursor: not-allowed;
}

.checkbox.checkbox-fill input[type="checkbox"]+.cr:after {
  content: "";
  width: 22.5px;
  height: 22.5px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 2px;
  vertical-align: bottom;
  text-align: center;
  background: 0 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 8.5px;
  left: 3px;
}

.checkbox.checkbox-fill input[type="checkbox"]+.cr:before {
  opacity: 0;
  content: "\e840";
  font-size: 27px;
  background: 0 0;
}

.checkbox.checkbox-fill input[type="checkbox"]:checked+.cr:after {
  opacity: 0;
}

.checkbox.checkbox-fill input[type="checkbox"]:checked+.cr:before {
  opacity: 1;
  background: 0 0;
  color: #1dd5d2;
  border-color: transparent;
}

.checkbox.checkbox-primary input[type="checkbox"]:checked+.cr:before {
  background: #2196f3;
  border-color: #2196f3;
  color: #fff;
}

.checkbox.checkbox-fill.checkbox-primary input[type="checkbox"]:checked+.cr:before {
  background: 0 0;
  color: #2196f3;
  border-color: transparent;
}

.checkbox.checkbox-danger input[type="checkbox"]:checked+.cr:before {
  background: #f44336;
  border-color: #f44336;
  color: #fff;
}

.checkbox.checkbox-fill.checkbox-danger input[type="checkbox"]:checked+.cr:before {
  background: 0 0;
  color: #f44336;
  border-color: transparent;
}

.checkbox.checkbox-success input[type="checkbox"]:checked+.cr:before {
  background: #4caf50;
  border-color: #4caf50;
  color: #fff;
}

.checkbox.checkbox-fill.checkbox-success input[type="checkbox"]:checked+.cr:before {
  background: 0 0;
  color: #4caf50;
  border-color: transparent;
}

.checkbox.checkbox-warning input[type="checkbox"]:checked+.cr:before {
  background: #ff9800;
  border-color: #ff9800;
  color: #fff;
}

.checkbox.checkbox-fill.checkbox-warning input[type="checkbox"]:checked+.cr:before {
  background: 0 0;
  color: #ff9800;
  border-color: transparent;
}

.checkbox.checkbox-info input[type="checkbox"]:checked+.cr:before {
  background: #00acc1;
  border-color: #00acc1;
  color: #fff;
}

.checkbox.checkbox-fill.checkbox-info input[type="checkbox"]:checked+.cr:before {
  background: 0 0;
  color: #00acc1;
  border-color: transparent;
}

.checkbox.checkbox-purple input[type="checkbox"]:checked+.cr:before {
  background: #7759de;
  border-color: #7759de;
  color: #fff;
}

.checkbox.checkbox-fill.checkbox-purple input[type="checkbox"]:checked+.cr:before {
  background: 0 0;
  color: #7759de;
  border-color: transparent;
}

.checkbox .cr {
  cursor: pointer;
}

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}

.radio input[type="radio"] {
  margin: 0;
  display: none;
  width: 22px;
}

.radio input[type="radio"]+.cr {
  padding-left: 0;
}

.radio input[type="radio"]+.cr:after,
.radio input[type="radio"]+.cr:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: bottom;
  background: #fff;
  color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.radio input[type="radio"]+.cr:before {
  width: 22px;
  height: 22px;
  border: 2px solid #e9eaec;
}

.radio input[type="radio"]+.cr:after {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  left: 5px;
}

.radio input[type="radio"]:checked+.cr:before {
  border-color: #2196f3;
}

.radio input[type="radio"]:checked+.cr:after {
  background: #2196f3;
}

.radio input[type="radio"]:disabled+.cr {
  opacity: 0.5;
  cursor: not-allowed;
}

.radio input[type="radio"]:disabled+.cr:after,
.radio input[type="radio"]:disabled+.cr:before {
  cursor: not-allowed;
}

.radio.radio-fill input[type="radio"]+.cr:after {
  width: 18px;
  height: 18px;
  top: 9px;
  left: 2px;
}

.radio.radio-primary input[type="radio"]:checked+.cr:before {
  border-color: #2196f3;
}

.radio.radio-primary input[type="radio"]:checked+.cr:after {
  background: #2196f3;
}

.radio.radio-danger input[type="radio"]:checked+.cr:before {
  border-color: #f44336;
}

.radio.radio-danger input[type="radio"]:checked+.cr:after {
  background: #f44336;
}

.radio.radio-success input[type="radio"]:checked+.cr:before {
  border-color: #4caf50;
}

.radio.radio-success input[type="radio"]:checked+.cr:after {
  background: #4caf50;
}

.radio.radio-warning input[type="radio"]:checked+.cr:before {
  border-color: #ff9800;
}

.radio.radio-warning input[type="radio"]:checked+.cr:after {
  background: #ff9800;
}

.radio.radio-info input[type="radio"]:checked+.cr:before {
  border-color: #00acc1;
}

.radio.radio-info input[type="radio"]:checked+.cr:after {
  background: #00acc1;
}

.radio.radio-purple input[type="radio"]:checked+.cr:before {
  border-color: #7759de;
}

.radio.radio-purple input[type="radio"]:checked+.cr:after {
  background: #7759de;
}

.radio .cr {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .radio input[type="radio"]+.cr::after {
    top: 12px;
  }
}

.custom-controls-stacked .radio input[type="radio"]+.cr:after {
  top: 15px;
}

.label {
  padding: 4px 10px;
  min-height: auto;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}

.label.label-primary {
  background: #2196f3;
  color: #fff;
}

.label.label-danger {
  background: #f44336;
  color: #fff;
}

.label.label-success {
  background: #4caf50;
  color: #fff;
}

.label.label-warning {
  background: #ff9800;
  color: #fff;
}

.label.label-info {
  background: #00acc1;
  color: #fff;
}

.label.label-purple {
  background: #7759de;
  color: #fff;
}

.label-primary {
  background: #2196f3;
  color: #fff;
}

.label-danger {
  background: #f44336;
  color: #fff;
}

.label-success {
  background: #4caf50;
  color: #fff;
}

.label-warning {
  background: #ff9800;
  color: #fff;
}

.label-info {
  background: #00acc1;
  color: #fff;
}

.label-purple {
  background: #7759de;
  color: #fff;
}

.table td,
.table th {
  border-top: 1px solid #e2e5e8;
  white-space: normal;
  padding: 1.05rem 0.75rem;
}

.table thead th {
  border-bottom: 1px solid #e2e5e8;
  font-size: 13px;
  color: #111;
  background: #eff3f6;
  text-transform: uppercase;
}

.table tbody+tbody {
  border-top: 2px solid #e2e5e8;
}

.table.table-dark thead th {
  background-color: #11171a;
}

.table.dataTable[class*="table-"] thead th {
  background: #eff3f6;
}

.table-bordered {
  border: 1px solid #e2e5e8;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e2e5e8;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(33, 150, 243, 0.03);
}

.table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.03);
}

.table .thead-dark th {
  color: #fff;
  background-color: #263238;
  border-color: #11171a;
}

.table-dark {
  color: #fff;
  background-color: #263238;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #11171a;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #222d32;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #1c2429;
}

.table-dark thead th {
  color: #fff;
}

table.dataTable.fixedHeader-floating {
  top: 0 !important;
}

@media screen and (max-width: 992px) {
  table.dataTable.fixedHeader-floating {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}

.footable .pagination>.active>a,
.footable .pagination>.active>span {
  background-color: #2196f3;
  border-color: #2196f3;
}

.footable .pagination>.active>a:focus,
.footable .pagination>.active>a:hover,
.footable .pagination>.active>span:focus,
.footable .pagination>.active>span:hover {
  background-color: #2196f3;
  border-color: #2196f3;
}

.footable .pagination>li>a,
.footable .pagination>li>span {
  color: #222;
}

.footable-details.table,
.footable.table {
  margin-bottom: 0;
}

table.footable>tfoot>tr.footable-paging>td>span.label {
  margin-bottom: 0;
}

table.footable-paging-center>tfoot>tr.footable-paging>td {
  padding-bottom: 0;
}

.table-columned>tbody>tr>td {
  border: 0;
  border-left: 1px solid #e2e5e8;
}

.table-columned>tbody>tr>th {
  border: 0;
}

.table.table-xl td,
.table.table-xl th {
  padding: 1.25rem 2rem;
}

.table.table-lg td,
.table.table-lg th {
  padding: 0.9rem 2rem;
}

.table.table-de td,
.table.table-de th {
  padding: 0.75rem 2rem;
}

.table.table-sm td,
.table.table-sm th {
  padding: 0.6rem 2rem;
}

.table.table-xs td,
.table.table-xs th {
  padding: 0.4rem 2rem;
}

#row-delete .selected,
#row-select .selected {
  background-color: #2196f3;
  color: #fff;
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background: rgba(33, 150, 243, 0.12);
}

table.dataTable tbody>tr.selected td.select-checkbox:after,
table.dataTable tbody>tr.selected th.select-checkbox:after,
table.dataTable tbody>tr>.selected td.select-checkbox:after,
table.dataTable tbody>tr>.selected th.select-checkbox:after {
  text-shadow: 1px 1px #e4f2fe, -1px -1px #e4f2fe, 1px -1px #e4f2fe,
    -1px 1px #e4f2fe;
}

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
}

@media (min-width: 1200px) {
  .auth-wrapper .container {
    max-width: 1140px;
  }
}

.auth-wrapper .saprator {
  position: relative;
  margin: 8px 0;
}

.auth-wrapper .saprator span {
  background: #fff;
  position: relative;
  padding: 0 10px;
  z-index: 5;
  font-size: 20px;
}

.auth-wrapper .saprator:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e2e5e8;
  z-index: 1;
}

.auth-wrapper a,
.auth-wrapper p>a {
  color: #111;
  font-weight: 600;
}

.auth-wrapper .btn-auth-gen .btn-icon {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
}

.auth-wrapper .btn-auth-gen .btn-icon small {
  font-size: 15px;
}

.auth-wrapper .input-group {
  background: 0 0;
}

.auth-wrapper .card {
  margin-bottom: 0;
  padding: 8px;
}

.auth-wrapper .card .card-body {
  padding: 20px 25px 20px 40px;
}

.auth-wrapper>div {
  z-index: 5;
}

.auth-wrapper .auth-content {
  position: relative;
  padding: 15px;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}

.auth-wrapper.offline {
  background-image: none;
}

.auth-wrapper.offline:before {
  display: none;
}

.auth-wrapper.error {
  background: #f44336;
}

.auth-wrapper.error .container {
  position: relative;
  max-width: 800px;
  margin: 0 auto 5%;
  padding: 5%;
  text-align: center;
  color: #fff;
}

.auth-wrapper .card-body .carousel-indicators {
  margin: 30px 0;
  bottom: 0;
}

.auth-wrapper .card-body .carousel-indicators li {
  width: 50px;
  background-color: #eff3f6;
}

.auth-wrapper .card-body .carousel-indicators li.active {
  background-color: #2196f3;
}

.auth-wrapper .img-logo-overlay {
  position: absolute;
  top: 40px;
  left: 50px;
}

.aut-bg-img {
  background-image: url(../images/auth-bg-big-main.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.aut-bg-img-side p {
  line-height: 2;
}

.aut-bg-img .custom-select,
.aut-bg-img .form-control,
.aut-bg-img-side .custom-select,
.aut-bg-img-side .form-control {
  background: 0 0;
}

.aut-bg-img .auth-content,
.aut-bg-img-side .auth-content {
  padding-top: 90px;
  padding-bottom: 90px;
}

.blur-bg-images {
  background-image: url(../images/blur-bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blur-bg-images:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.auth-prod-slider .blur-bg-images:after {
  background: #242e3e;
}

.auth-prod-slider .auth-prod-slidebg {
  height: 600px;
  width: 100%;
}

.auth-prod-slider .auth-prod-slidebg.bg-1 {
  background-image: linear-gradient(to right,
      rgba(119, 89, 222, 0.9) 0%,
      rgba(33, 150, 243, 0.9) 100%);
}

.auth-prod-slider .auth-prod-slidebg.bg-2 {
  background-image: linear-gradient(to right,
      rgba(33, 150, 243, 0.9) 0%,
      rgba(0, 172, 193, 0.9) 100%);
}

.auth-prod-slider .auth-prod-slidebg.bg-3 {
  background-image: linear-gradient(to right,
      rgba(0, 172, 193, 0.9) 0%,
      rgba(33, 150, 243, 0.9) 100%);
}

.auth-prod-slider .auth-prod-slidebg.bg-4 {
  background-image: linear-gradient(to right,
      rgba(33, 150, 243, 0.9) 0%,
      rgba(119, 89, 222, 0.9) 100%);
}

.auth-prod-slider .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  transition: all 0.35s ease-in-out;
  border-top: none;
  border-bottom: none;
}

.auth-prod-slider .carousel-indicators li.active {
  width: 20px;
  background: #2196f3;
}

.auth-prod-slider .carousel-caption {
  bottom: auto;
  top: 50px;
}

.auth-prod-slider .carousel-caption img {
  border-radius: 5px;
  border: 4px solid #fff;
}

.auth-prod-slider .carousel-caption h5 {
  color: #fff;
}

.btn {
  padding: 6px 12px;
  border-radius: 0.25rem;
  font-size: 14px;
  /*margin-bottom: 5px;*/
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.btn>i {
  margin-right: 12px;
}

.btn-square {
  border-radius: 0;
}

.btn.disabled {
  cursor: not-allowed;
  opacity: 0.55;
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn .spinner-border {
  margin-right: 5px;
}

.dropdown-toggle::after {
  top: 16px;
  right: 2px;
  position: absolute;
}

.dropdown-toggle-split {
  padding: 8px 10px;
}

.dropdown-toggle-split:after {
  left: 0;
}

.btn-icon,
.drp-icon {
  width: 40px;
  height: 40px;
  padding: 10px 12px;
}

.btn-icon>i,
.drp-icon>i {
  margin-right: 0;
}

.drp-icon.dropdown-toggle:after {
  display: none;
}

.drp-icon+.dropdown-menu {
  margin-left: -10px;
}

.btn-lg {
  padding: 12px 25px;
  border-radius: 0.35rem;
}

.btn-sm {
  padding: 3px 12px;
  border-radius: 0.15rem;
}

.btn-group .btn,
.btn-group-vertical .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.shadow-1 {
  box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16);
}

.shadow-2 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}

.shadow-3 {
  box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26);
}

.shadow-4 {
  box-shadow: 0 16px 28px 0 rgba(62, 57, 107, 0.3);
}

.shadow-5 {
  box-shadow: 0 20px 24px 0 rgba(62, 57, 107, 0.36);
}

.btn-primary {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #128ff2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0c83e2;
  border-color: #0c7cd5;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0c7cd5;
  border-color: #0b75c9;
}

.btn-secondary {
  color: #fff;
  background-color: #748892;
  border-color: #6c808a;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #62747d;
  border-color: #5d6e76;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d6e76;
  border-color: #57676f;
}

.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #47a44b;
}

.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}

.btn-info {
  color: #fff;
  background-color: #00acc1;
  border-color: #009eb2;
}

.btn-info:hover {
  color: #fff;
  background-color: #008a9b;
  border-color: #007f8e;
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #007f8e;
  border-color: #007381;
}

.btn-warning {
  color: #fff;
  background-color: #ff9800;
  border-color: #f08f00;
}

.btn-warning:hover {
  color: #fff;
  background-color: #d98100;
  border-color: #cc7a00;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc7a00;
  border-color: #bf7200;
}

.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f33527;
}

.btn-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  cursor: not-allowed;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}

.btn-light {
  color: #37474f;
  background-color: #eceff1;
  border-color: #e3e8eb;
}

.btn-light:hover {
  color: #37474f;
  background-color: #d6dce1;
  border-color: #cfd6db;
}

.btn-light.disabled,
.btn-light:disabled {
  color: #37474f;
  background-color: #eceff1;
  border-color: #eceff1;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  color: #37474f;
  background-color: #cfd6db;
  border-color: #c7d0d6;
}

.btn-dark {
  color: #fff;
  background-color: #263238;
  border-color: #202a2f;
}

.btn-dark:hover {
  color: #fff;
  background-color: #171e21;
  border-color: #11171a;
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #263238;
  border-color: #263238;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #11171a;
  border-color: #0c1012;
}

.btn-purple {
  color: #fff;
  background-color: #7759de;
  border-color: #6d4cdb;
}

.btn-purple:hover {
  color: #fff;
  background-color: #5d39d8;
  border-color: #542ed6;
}

.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #7759de;
  border-color: #7759de;
}

.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active,
.show>.btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #542ed6;
  border-color: #4e29ce;
}

.btn-outline-primary {
  color: #2196f3;
  background-color: transparent;
  background-image: none;
  border-color: #2196f3;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #2196f3;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary {
  color: #748892;
  background-color: transparent;
  background-image: none;
  border-color: #748892;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #748892;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-outline-info {
  color: #00acc1;
  background-color: transparent;
  background-image: none;
  border-color: #00acc1;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00acc1;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9800;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-outline-light {
  color: #eceff1;
  background-color: transparent;
  background-image: none;
  border-color: #eceff1;
}

.btn-outline-light:hover {
  color: #37474f;
  background-color: #eceff1;
  border-color: #eceff1;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #eceff1;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #37474f;
  background-color: #eceff1;
  border-color: #eceff1;
}

.btn-outline-dark {
  color: #263238;
  background-color: transparent;
  background-image: none;
  border-color: #263238;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #263238;
  border-color: #263238;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #263238;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #263238;
  border-color: #263238;
}

.btn-outline-purple {
  color: #7759de;
  background-color: transparent;
  background-image: none;
  border-color: #7759de;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #7759de;
  border-color: #7759de;
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #7759de;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled).active,
.btn-outline-purple:not(:disabled):not(.disabled):active,
.show>.btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #7759de;
  border-color: #7759de;
}

.btn-glow-primary {
  box-shadow: 0 0 7px 0 rgba(33, 150, 243, 0.56),
    0 0 10px 2px rgba(33, 150, 243, 0.2);
}

.btn-glow-secondary {
  box-shadow: 0 0 7px 0 rgba(116, 136, 146, 0.56),
    0 0 10px 2px rgba(116, 136, 146, 0.2);
}

.btn-glow-success {
  box-shadow: 0 0 7px 0 rgba(76, 175, 80, 0.56),
    0 0 10px 2px rgba(76, 175, 80, 0.2);
}

.btn-glow-info {
  box-shadow: 0 0 7px 0 rgba(0, 172, 193, 0.56),
    0 0 10px 2px rgba(0, 172, 193, 0.2);
}

.btn-glow-warning {
  box-shadow: 0 0 7px 0 rgba(255, 152, 0, 0.56),
    0 0 10px 2px rgba(255, 152, 0, 0.2);
}

.btn-glow-danger {
  box-shadow: 0 0 7px 0 rgba(244, 67, 54, 0.56),
    0 0 10px 2px rgba(244, 67, 54, 0.2);
}

.btn-glow-light {
  box-shadow: 0 0 7px 0 rgba(236, 239, 241, 0.56),
    0 0 10px 2px rgba(236, 239, 241, 0.2);
}

.btn-glow-dark {
  box-shadow: 0 0 7px 0 rgba(38, 50, 56, 0.56),
    0 0 10px 2px rgba(38, 50, 56, 0.2);
}

.btn-glow-purple {
  box-shadow: 0 0 7px 0 rgba(119, 89, 222, 0.56),
    0 0 10px 2px rgba(119, 89, 222, 0.2);
}

.btn-gradient-primary {
  color: #fff;
  background: linear-gradient(to top, #2196f3, #51adf6, #2196f3);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #128ff2;
}

.btn-gradient-primary:hover {
  color: #fff;
  background-color: #0c83e2;
  border-color: #0c7cd5;
  background-position: top;
}

.btn-gradient-primary.disabled,
.btn-gradient-primary:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-gradient-primary:not(:disabled):not(.disabled).active,
.btn-gradient-primary:not(:disabled):not(.disabled):active,
.show>.btn-gradient-primary.dropdown-toggle {
  color: #fff;
  background-color: #0c7cd5;
  border-color: #0b75c9;
}

.btn-gradient-secondary {
  color: #fff;
  background: linear-gradient(to top, #748892, #91a0a8, #748892);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #6c808a;
}

.btn-gradient-secondary:hover {
  color: #fff;
  background-color: #62747d;
  border-color: #5d6e76;
  background-position: top;
}

.btn-gradient-secondary.disabled,
.btn-gradient-secondary:disabled {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-gradient-secondary:not(:disabled):not(.disabled).active,
.btn-gradient-secondary:not(:disabled):not(.disabled):active,
.show>.btn-gradient-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d6e76;
  border-color: #57676f;
}

.btn-gradient-success {
  color: #fff;
  background: linear-gradient(to top, #4caf50, #6ec071, #4caf50);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #47a44b;
}

.btn-gradient-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
  background-position: top;
}

.btn-gradient-success.disabled,
.btn-gradient-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-gradient-success:not(:disabled):not(.disabled).active,
.btn-gradient-success:not(:disabled):not(.disabled):active,
.show>.btn-gradient-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}

.btn-gradient-info {
  color: #fff;
  background: linear-gradient(to top, #00acc1, #00d9f4, #00acc1);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #009eb2;
}

.btn-gradient-info:hover {
  color: #fff;
  background-color: #008a9b;
  border-color: #007f8e;
  background-position: top;
}

.btn-gradient-info.disabled,
.btn-gradient-info:disabled {
  color: #fff;
  background-color: #00acc1;
  border-color: #00acc1;
}

.btn-gradient-info:not(:disabled):not(.disabled).active,
.btn-gradient-info:not(:disabled):not(.disabled):active,
.show>.btn-gradient-info.dropdown-toggle {
  color: #fff;
  background-color: #007f8e;
  border-color: #007381;
}

.btn-gradient-warning {
  color: #fff;
  background: linear-gradient(to top, #ff9800, #ffad33, #ff9800);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #f08f00;
}

.btn-gradient-warning:hover {
  color: #fff;
  background-color: #d98100;
  border-color: #cc7a00;
  background-position: top;
}

.btn-gradient-warning.disabled,
.btn-gradient-warning:disabled {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-gradient-warning:not(:disabled):not(.disabled).active,
.btn-gradient-warning:not(:disabled):not(.disabled):active,
.show>.btn-gradient-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc7a00;
  border-color: #bf7200;
}

.btn-gradient-danger {
  color: #fff;
  background: linear-gradient(to top, #f44336, #f77066, #f44336);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #f33527;
}

.btn-gradient-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
  background-position: top;
}

.btn-gradient-danger.disabled,
.btn-gradient-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-gradient-danger:not(:disabled):not(.disabled).active,
.btn-gradient-danger:not(:disabled):not(.disabled):active,
.show>.btn-gradient-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}

.btn-gradient-light {
  color: #37474f;
  background: linear-gradient(to top, #eceff1, white, #eceff1);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #e3e8eb;
}

.btn-gradient-light:hover {
  color: #37474f;
  background-color: #d6dce1;
  border-color: #cfd6db;
  background-position: top;
}

.btn-gradient-light.disabled,
.btn-gradient-light:disabled {
  color: #37474f;
  background-color: #eceff1;
  border-color: #eceff1;
}

.btn-gradient-light:not(:disabled):not(.disabled).active,
.btn-gradient-light:not(:disabled):not(.disabled):active,
.show>.btn-gradient-light.dropdown-toggle {
  color: #37474f;
  background-color: #cfd6db;
  border-color: #c7d0d6;
}

.btn-gradient-dark {
  color: #fff;
  background: linear-gradient(to top, #263238, #3b4d56, #263238);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #202a2f;
}

.btn-gradient-dark:hover {
  color: #fff;
  background-color: #171e21;
  border-color: #11171a;
  background-position: top;
}

.btn-gradient-dark.disabled,
.btn-gradient-dark:disabled {
  color: #fff;
  background-color: #263238;
  border-color: #263238;
}

.btn-gradient-dark:not(:disabled):not(.disabled).active,
.btn-gradient-dark:not(:disabled):not(.disabled):active,
.show>.btn-gradient-dark.dropdown-toggle {
  color: #fff;
  background-color: #11171a;
  border-color: #0c1012;
}

.btn-gradient-purple {
  color: #fff;
  background: linear-gradient(to top, #7759de, #9a84e6, #7759de);
  background-size: 100% 200%;
  background-position: bottom;
  border-color: #6d4cdb;
}

.btn-gradient-purple:hover {
  color: #fff;
  background-color: #5d39d8;
  border-color: #542ed6;
  background-position: top;
}

.btn-gradient-purple.disabled,
.btn-gradient-purple:disabled {
  color: #fff;
  background-color: #7759de;
  border-color: #7759de;
}

.btn-gradient-purple:not(:disabled):not(.disabled).active,
.btn-gradient-purple:not(:disabled):not(.disabled):active,
.show>.btn-gradient-purple.dropdown-toggle {
  color: #fff;
  background-color: #542ed6;
  border-color: #4e29ce;
}

.btn-facebook {
  background: #3c5a99;
  border-color: #3c5a99;
  color: #fff;
}

.btn-facebook:hover,
.btn-facebook:not(:disabled):not(.disabled).active,
.btn-facebook:not(:disabled):not(.disabled):active,
.show>.btn-facebook.dropdown-toggle {
  color: #fff;
}

.btn-twitter {
  background: #42c0fb;
  border-color: #42c0fb;
  color: #fff;
}

.btn-twitter:hover,
.btn-twitter:not(:disabled):not(.disabled).active,
.btn-twitter:not(:disabled):not(.disabled):active,
.show>.btn-twitter.dropdown-toggle {
  color: #fff;
}

.btn-dribbble {
  background: #ec4a89;
  border-color: #ec4a89;
  color: #fff;
}

.btn-dribbble:hover,
.btn-dribbble:not(:disabled):not(.disabled).active,
.btn-dribbble:not(:disabled):not(.disabled):active,
.show>.btn-dribbble.dropdown-toggle {
  color: #fff;
}

.btn-pinterest {
  background: #bf2131;
  border-color: #bf2131;
  color: #fff;
}

.btn-pinterest:hover,
.btn-pinterest:not(:disabled):not(.disabled).active,
.btn-pinterest:not(:disabled):not(.disabled):active,
.show>.btn-pinterest.dropdown-toggle {
  color: #fff;
}

.btn-youtube {
  background: #e0291d;
  border-color: #e0291d;
  color: #fff;
}

.btn-youtube:hover,
.btn-youtube:not(:disabled):not(.disabled).active,
.btn-youtube:not(:disabled):not(.disabled):active,
.show>.btn-youtube.dropdown-toggle {
  color: #fff;
}

.btn-googleplus {
  background: #c73e2e;
  border-color: #c73e2e;
  color: #fff;
}

.btn-googleplus:hover,
.btn-googleplus:not(:disabled):not(.disabled).active,
.btn-googleplus:not(:disabled):not(.disabled):active,
.show>.btn-googleplus.dropdown-toggle {
  color: #fff;
}

.btn-instagram {
  background: #aa7c62;
  border-color: #aa7c62;
  color: #fff;
}

.btn-instagram:hover,
.btn-instagram:not(:disabled):not(.disabled).active,
.btn-instagram:not(:disabled):not(.disabled):active,
.show>.btn-instagram.dropdown-toggle {
  color: #fff;
}

.btn-viber {
  background: #7b519d;
  border-color: #7b519d;
  color: #fff;
}

.btn-viber:hover,
.btn-viber:not(:disabled):not(.disabled).active,
.btn-viber:not(:disabled):not(.disabled):active,
.show>.btn-viber.dropdown-toggle {
  color: #fff;
}

.btn-behance {
  background: #0057ff;
  border-color: #0057ff;
  color: #fff;
}

.btn-behance:hover,
.btn-behance:not(:disabled):not(.disabled).active,
.btn-behance:not(:disabled):not(.disabled):active,
.show>.btn-behance.dropdown-toggle {
  color: #fff;
}

.btn-dropbox {
  background: #3380ff;
  border-color: #3380ff;
  color: #fff;
}

.btn-dropbox:hover,
.btn-dropbox:not(:disabled):not(.disabled).active,
.btn-dropbox:not(:disabled):not(.disabled):active,
.show>.btn-dropbox.dropdown-toggle {
  color: #fff;
}

.btn-linkedin {
  background: #0077b5;
  border-color: #0077b5;
  color: #fff;
}

.btn-linkedin:hover,
.btn-linkedin:not(:disabled):not(.disabled).active,
.btn-linkedin:not(:disabled):not(.disabled):active,
.show>.btn-linkedin.dropdown-toggle {
  color: #fff;
}

.swal-footer {
  text-align: center;
}

.alert {
  position: relative;
  padding: 15px 20px;
  border-radius: 2px;
}

.alert-link {
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.alert-link:hover {
  text-decoration: underline;
}

.alert-dismissible {
  padding-right: 40px;
}

.alert-dismissible .close {
  padding: 14px 10px;
}

.alert-primary {
  color: #2c70a4;
  border-color: #c1e2fc;
}

.alert-primary hr {
  border-top-color: #a9d7fb;
}

.alert-primary .alert-link {
  color: #21557c;
}

.alert-secondary {
  color: #576972;
  border-color: #d8dee0;
}

.alert-secondary hr {
  border-top-color: #cad2d5;
}

.alert-secondary .alert-link {
  color: #414e55;
}

.alert-success {
  color: #427d50;
  border-color: #cde9ce;
}

.alert-success hr {
  border-top-color: #bbe1bd;
}

.alert-success .alert-link {
  color: #305c3b;
}

.alert-info {
  color: #1a7c8a;
  border-color: #b8e8ee;
}

.alert-info hr {
  border-top-color: #a3e1e9;
}

.alert-info .alert-link {
  color: #12555f;
}

.alert-warning {
  color: #9f7126;
  border-color: #ffe2b8;
}

.alert-warning hr {
  border-top-color: #ffd89f;
}

.alert-warning .alert-link {
  color: #76541c;
}

.alert-danger {
  color: #994542;
  border-color: #fccac7;
}

.alert-danger hr {
  border-top-color: #fbb3af;
}

.alert-danger .alert-link {
  color: #753533;
}

.alert-light {
  color: #959ea3;
  border-color: #fafbfb;
}

.alert-light hr {
  border-top-color: #ecf0f0;
}

.alert-light .alert-link {
  color: #7a858b;
}

.alert-dark {
  color: #2e3c43;
  border-color: #c2c6c7;
}

.alert-dark hr {
  border-top-color: #b5babb;
}

.alert-dark .alert-link {
  color: #192125;
}

.alert-purple {
  color: #585099;
  border-color: #d9d1f6;
}

.alert-purple hr {
  border-top-color: #c7bcf2;
}

.alert-purple .alert-link {
  color: #453e78;
}

.breadcrumb {
  background-color: #eff3f6;
  border-radius: 0;
}

.breadcrumb-item+.breadcrumb-item {
  color: #2196f3;
}

.breadcrumb-item+.breadcrumb-item::before {}

.breadcrumb-item.active {
  color: #111;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 12px;
  font-size: 10px;
  background-color: #eff3f6;
  border-radius: 0;
  overflow: visible;
}

.progress-bar {
  background-color: #2196f3;
}

.progress-bar.progress-c-blue {
  background: #2196f3;
}

.progress-bar.progress-c-red {
  background: #f44336;
}

.progress-bar.progress-c-green {
  background: #4caf50;
}

.progress-bar.progress-c-yellow {
  background: #ff9800;
}

.progress-bar.progress-c-purple {
  background: #7759de;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 10px 24px;
}

.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  opacity: 0.7;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  border: none;
  color: #686c71;
}

.nav-tabs .nav-link:hover {
  color: #2196f3;
}

.nav-tabs .nav-link.disabled {
  opacity: 0.7;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  color: #111;
  box-shadow: 0 -1px 0 1px #e2e5e8;
}

.nav-pills {
  padding: 15px;
  background-color: #fff;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background: #2196f3;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content {
  padding: 35px 30px;
  background: #fff;
  box-shadow: 0 0 0 1px #e2e5e8;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.modal-content {
  border-radius: 2px;
}

.modal-content .modal-header {
  border-bottom: 1px solid #e2e5e8;
}

.modal-content .modal-footer {
  border-top: 1px solid #e2e5e8;
}

.modal-content .modal-footer .btn {
  margin-bottom: 0;
}

@media screen and (max-width: 480px) {
  .modal-footer {
    justify-content: center;
  }
}

.header-chat,
.header-user-list {
  height: 100%;
  width: 300px;
  position: fixed;
  top: 0;
  right: -300px;
  border-radius: 0;
  z-index: 1030;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.header-chat .main-friend-cont,
.header-user-list .main-friend-cont {
  height: calc(100vh - 60px);
  position: relative;
}

.header-chat .h-list-header+.h-list-body>.main-friend-cont,
.header-user-list .h-list-header+.h-list-body>.main-friend-cont {
  height: calc(100vh - 130px);
  position: relative;
}

.header-chat .h-list-header,
.header-user-list .h-list-header {
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
}

.header-chat.open,
.header-user-list.open {
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  right: 0;
}

.header-user-list .nav-tabs {
  border-bottom: 1px solid #f1f1f1;
}

.header-user-list .nav-tabs .nav-item {
  width: calc(100% / 3);
  text-align: center;
}

.header-user-list .nav-tabs .nav-item a {
  padding: 20px 0;
  position: relative;
  background-color: transparent;
  background-image: linear-gradient(#2196f3, #2196f3);
  background-size: 0% 2px;
  background-position: bottom;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.header-user-list .nav-tabs .nav-item a.active {
  box-shadow: none;
  background-size: 100% 2px;
  color: #2196f3;
}

.header-user-list .tab-content {
  padding: 0;
  box-shadow: none;
}

.header-user-list.open .h-close-text {
  position: absolute;
  top: 35px;
  left: -30px;
}

.header-user-list.open .h-close-text i {
  position: absolute;
  top: 55px;
  left: 7px;
  font-size: 20px;
  z-index: 1003;
  color: #fff;
}

.header-user-list.open .h-close-text:after {
  content: "";
  z-index: 1001;
  position: absolute;
  top: 45px;
  left: -2px;
  background: #2196f3;
  width: 32px;
  height: 40px;
  box-shadow: -5px 2px 15px -4px rgba(69, 90, 100, 0.5);
  border-radius: 20px 0 0 20px;
}

.header-user-list.open.msg-open:after {
  color: rgba(33, 150, 243, 0.1);
}

.h-list-header .form-control:active,
.h-list-header .form-control:focus,
.h-list-header .form-control:hover {
  box-shadow: none;
  outline: none;
}

.h-list-body {
  position: relative;
}

.h-list-body .userlist-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  position: relative;
}

.h-list-body .userlist-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background: #f3f4f9;
}

.h-list-body .userlist-box.active {
  background: #fbfdff;
}

.h-list-body .userlist-box .media-left {
  padding-right: 10px;
}

.h-list-body .userlist-box .media-object {
  width: 50px;
  display: inline-block;
}

.h-list-body .userlist-box .chat-header {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.h-list-body .userlist-box .chat-header small {
  margin-top: 5px;
  font-size: 90%;
}

.h-list-body .userlist-box .live-status {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 35px;
  right: 20px;
  border-radius: 100%;
  color: #fff;
  padding: 2px 0;
  text-align: center;
  background: #2196f3;
}

.header-chat .h-list-header {
  text-align: center;
  position: relative;
}

.header-chat .h-list-header h6 {
  margin: 5px 0;
}

.header-chat .h-list-header .h-back-user-list {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  font-size: 20px;
}

.header-chat .main-chat-cont {
  height: calc(100vh - 166px);
}

.header-chat .h-list-body {
  height: 100%;
  background: #e7f4fe;
}

.header-chat .h-list-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  padding: 20px 15px;
  z-index: 10;
  background: #e7f4fe;
}

.header-chat .h-list-footer .input-group {
  background: #fff;
  border: none;
  display: inline-flex;
  box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
  padding: 7px;
  border-radius: 20px;
  width: calc(100% - 40px);
}

.header-chat .h-list-footer .input-group .form-control,
.header-chat .h-list-footer .input-group .input-group-text {
  background: 0 0;
  border: none;
  border-radius: 0;
  padding: 0;
}

.header-chat .h-list-footer .input-group .btn-send:active,
.header-chat .h-list-footer .input-group .btn-send:focus,
.header-chat .h-list-footer .input-group .btn-send:hover,
.header-chat .h-list-footer .input-group .form-control:active,
.header-chat .h-list-footer .input-group .form-control:focus,
.header-chat .h-list-footer .input-group .form-control:hover {
  outline: none;
  box-shadow: none;
}

.header-chat .h-list-footer .input-group .btn-attach {
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}

.header-chat .h-list-footer .input-group .btn-attach>i {
  margin-right: 0;
}

.header-chat .h-list-footer .input-group .btn-send {
  border-radius: 50%;
  padding: 10px;
  margin-left: 5px;
  position: absolute;
  right: -45px;
  top: 2px;
  z-index: 99;
}

.header-chat .h-list-footer .input-group .btn-send i {
  margin-right: 0;
}

.header-chat .h-list-footer .input-group .btn-send .input-group-text {
  color: #fff;
}

.header-chat .h-list-footer .input-group .form-control {
  transition: all 0.3s ease-in-out;
  width: 0;
}

.h-list-body .chat-messages {
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.h-list-body .chat-messages .photo-table {
  padding-right: 15px;
}

.h-list-body .chat-messages .photo-table img {
  display: inline-block;
  width: 45px;
  margin-bottom: 5px;
}

.h-list-body .chat-messages .chat-menu-content>div,
.h-list-body .chat-messages .chat-menu-reply>div {
  position: relative;
  overflow: visible;
  display: inline-block;
}

.h-list-body .chat-messages .chat-menu-content>div .chat-cont,
.h-list-body .chat-messages .chat-menu-reply>div .chat-cont {
  padding: 8px 20px;
  display: inline-block;
}

.h-list-body .chat-messages .chat-menu-content .chat-time,
.h-list-body .chat-messages .chat-menu-reply .chat-time {
  margin: 5px 24px 0 0;
}

.h-list-body .chat-messages .chat-menu-reply {
  text-align: right;
}

.h-list-body .chat-messages .chat-menu-reply>div {
  margin-top: 10px;
}

.h-list-body .chat-messages .chat-menu-reply>div p {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 4px;
  margin-right: 25px;
  box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
}

.h-list-body .chat-messages .chat-menu-reply>div:before {
  content: "";
  z-index: 1001;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 6px solid transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 16px;
  right: 19px;
  box-shadow: 1px 5px 10px -3px rgba(62, 57, 107, 0.15);
}

.h-list-body .chat-messages .chat-menu-content>div {
  margin-top: 10px;
}

.h-list-body .chat-messages .chat-menu-content>div p {
  background: #2196f3;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 4px;
  box-shadow: 0 5px 15px 0 rgba(62, 57, 107, 0.15);
}

.h-list-body .chat-messages .chat-menu-content>div:before {
  content: "";
  z-index: 1001;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 6px solid transparent;
  border-left-color: #2196f3;
  border-top-color: #2196f3;
  position: absolute;
  top: 12px;
  left: -6px;
  box-shadow: -4px 0 8px -5px rgba(62, 57, 107, 0.3);
}

.msg-card .msg-user-list {
  height: calc(100vh - 300px);
}

.msg-card .msg-user-chat {
  background: #e7f4fe;
  height: calc(100vh - 330px);
  padding: 25px 5px;
}

.msg-card .msg-block>.row>div:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #f1f1f1;
  position: absolute;
  top: 0;
}

.msg-card .msg-block>.row>div:first-child:before {
  right: 0;
}

.msg-card .msg-block>.row>div:last-child:before {
  left: -1px;
}

.main-friend-chat {
  padding-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .msg-card .msg-block>.row>div:before {
    background: 0 0;
  }
}

pre[class*="language-"] code {
  font-size: 14px;
}

pre[class*="language-"] [class*="language-"],
pre[class*="language-"] .language-markup {
  background-color: transparent;
  display: block;
  padding: 10px 15px;
}

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre.line-numbers>code {
  position: relative;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  letter-spacing: -1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-numbers-rows>span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows>span:before {
  content: counter(linenumber);
  display: block;
  padding-right: 0.8em;
  text-align: right;
}

div.code-toolbar {
  position: relative;
}

div.code-toolbar>.toolbar {
  position: absolute;
  top: 0.3em;
  right: 0.2em;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

div.code-toolbar:hover>.toolbar {
  opacity: 1;
}

div.code-toolbar>.toolbar .toolbar-item {
  display: inline-block;
}

div.code-toolbar>.toolbar a {
  cursor: pointer;
}

div.code-toolbar>.toolbar button {
  border: 0;
  font: inherit;
  line-height: normal;
  overflow: visible;
}

div.code-toolbar>.toolbar a,
div.code-toolbar>.toolbar button,
div.code-toolbar>.toolbar span {
  color: #bbb;
  font-size: 0.8em;
  padding: 0 0.5em;
  background: #f5f2f0;
  background: rgba(224, 224, 224, 0.2);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
}

div.code-toolbar>.toolbar a:focus,
div.code-toolbar>.toolbar a:hover,
div.code-toolbar>.toolbar button:focus,
div.code-toolbar>.toolbar button:hover,
div.code-toolbar>.toolbar span:focus,
div.code-toolbar>.toolbar span:hover {
  color: inherit;
  text-decoration: none;
}

.tool-bottom .tool-item:first-child,
.tool-top .tool-item:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.tool-bottom .tool-item:last-child,
.tool-top .tool-item:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tool-left .tool-item:first-child,
.tool-left .tool-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.tool-left .tool-item:last-child,
.tool-left .tool-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.toolbar-primary .tool-item {
  background: #2196f3;
}

.toolbar-primary .tool-item.selected,
.toolbar-primary .tool-item:hover {
  background: #0c7cd5;
}

.toolbar-primary.tool-top .arrow {
  border-color: #2196f3 transparent transparent;
}

.toolbar-primary.tool-bottom .arrow {
  border-color: transparent transparent #2196f3;
}

.toolbar-primary.tool-left .arrow {
  border-color: transparent transparent transparent #2196f3;
}

.toolbar-primary.tool-right .arrow {
  border-color: transparent #2196f3 transparent transparent;
}

.btn-toolbar-primary.pressed {
  background-color: #2196f3;
}

.toolbar-warning .tool-item {
  background: #ff9800;
}

.toolbar-warning .tool-item.selected,
.toolbar-warning .tool-item:hover {
  background: #cc7a00;
}

.toolbar-warning.tool-top .arrow {
  border-color: #ff9800 transparent transparent;
}

.toolbar-warning.tool-bottom .arrow {
  border-color: transparent transparent #ff9800;
}

.toolbar-warning.tool-left .arrow {
  border-color: transparent transparent transparent #ff9800;
}

.toolbar-warning.tool-right .arrow {
  border-color: transparent #ff9800 transparent transparent;
}

.btn-toolbar-warning.pressed {
  background-color: #ff9800;
}

.toolbar-light .tool-item {
  background: #d6d6d6;
}

.toolbar-light .tool-item.selected,
.toolbar-light .tool-item:hover {
  background: #bdbdbd;
}

.toolbar-light.tool-top .arrow {
  border-color: #d6d6d6 transparent transparent;
}

.toolbar-light.tool-bottom .arrow {
  border-color: transparent transparent #d6d6d6;
}

.toolbar-light.tool-left .arrow {
  border-color: transparent transparent transparent #d6d6d6;
}

.toolbar-light.tool-right .arrow {
  border-color: transparent #d6d6d6 transparent transparent;
}

.btn-toolbar-light.pressed {
  background-color: #d6d6d6;
}

.toolbar-danger .tool-item {
  background: #f44336;
}

.toolbar-danger .tool-item.selected,
.toolbar-danger .tool-item:hover {
  background: #ea1c0d;
}

.toolbar-danger.tool-top .arrow {
  border-color: #f44336 transparent transparent;
}

.toolbar-danger.tool-bottom .arrow {
  border-color: transparent transparent #f44336;
}

.toolbar-danger.tool-left .arrow {
  border-color: transparent transparent transparent #f44336;
}

.toolbar-danger.tool-right .arrow {
  border-color: transparent #f44336 transparent transparent;
}

.btn-toolbar-danger.pressed {
  background-color: #f44336;
}

.toolbar-success .tool-item {
  background: #4caf50;
}

.toolbar-success .tool-item.selected,
.toolbar-success .tool-item:hover {
  background: #3d8b40;
}

.toolbar-success.tool-top .arrow {
  border-color: #4caf50 transparent transparent;
}

.toolbar-success.tool-bottom .arrow {
  border-color: transparent transparent #4caf50;
}

.toolbar-success.tool-left .arrow {
  border-color: transparent transparent transparent #4caf50;
}

.toolbar-success.tool-right .arrow {
  border-color: transparent #4caf50 transparent transparent;
}

.btn-toolbar-success.pressed {
  background-color: #4caf50;
}

.toolbar-dark .tool-item {
  background: #263238;
}

.toolbar-dark .tool-item.selected,
.toolbar-dark .tool-item:hover {
  background: #11171a;
}

.toolbar-dark.tool-top .arrow {
  border-color: #263238 transparent transparent;
}

.toolbar-dark.tool-bottom .arrow {
  border-color: transparent transparent #263238;
}

.toolbar-dark.tool-left .arrow {
  border-color: transparent transparent transparent #263238;
}

.toolbar-dark.tool-right .arrow {
  border-color: transparent #263238 transparent transparent;
}

.btn-toolbar-dark.pressed {
  background-color: #263238;
}

.toolbar-info .tool-item {
  background: #00acc1;
}

.toolbar-info .tool-item.selected,
.toolbar-info .tool-item:hover {
  background: #007f8e;
}

.toolbar-info.tool-top .arrow {
  border-color: #00acc1 transparent transparent;
}

.toolbar-info.tool-bottom .arrow {
  border-color: transparent transparent #00acc1;
}

.toolbar-info.tool-left .arrow {
  border-color: transparent transparent transparent #00acc1;
}

.toolbar-info.tool-right .arrow {
  border-color: transparent #00acc1 transparent transparent;
}

.btn-toolbar-info.pressed {
  background-color: #00acc1;
}

.switch input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch input[type="checkbox"]+.cr {
  position: relative;
  display: inline-block;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  top: 12px;
}

.switch input[type="checkbox"]+.cr:after,
.switch input[type="checkbox"]+.cr:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.switch input[type="checkbox"]+.cr:before {
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
}

.switch input[type="checkbox"]+.cr:after {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}

.switch input[type="checkbox"]:checked+.cr:before {
  background: #2196f3;
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}

.switch input[type="checkbox"]:checked+.cr:after {
  left: 16px;
}

.switch input[type="checkbox"]:disabled+label {
  opacity: 0.5;
  -webkit-filter: grayscale(0.4);
  filter: grayscale(0.4);
  cursor: not-allowed;
}

.switch.switch-primary input[type="checkbox"]:checked+.cr:before {
  background: #2196f3;
}

.switch.switch-danger input[type="checkbox"]:checked+.cr:before {
  background: #f44336;
}

.switch.switch-success input[type="checkbox"]:checked+.cr:before {
  background: #4caf50;
}

.switch.switch-warning input[type="checkbox"]:checked+.cr:before {
  background: #ff9800;
}

.switch.switch-info input[type="checkbox"]:checked+.cr:before {
  background: #00acc1;
}

.switch.switch-purple input[type="checkbox"]:checked+.cr:before {
  background: #7759de;
}

.wizard-container {
  z-index: 3;
}

.wizard-container .wizard-navigation {
  position: relative;
}

.wizard-card .moving-tab {
  position: absolute;
  text-align: center;
  padding: 16px 12px;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  top: -6px;
  left: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
}

.wizard-card[data-color="wizard-primary"] .moving-tab {
  background-color: #2196f3;
  box-shadow: 0 16px 26px -10px rgba(33, 150, 243, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(33, 150, 243, 0.2);
}

.wizard-card[data-color="wizard-danger"] .moving-tab {
  background-color: #f44336;
  box-shadow: 0 16px 26px -10px rgba(244, 67, 54, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}

.wizard-card[data-color="wizard-success"] .moving-tab {
  background-color: #4caf50;
  box-shadow: 0 16px 26px -10px rgba(76, 175, 80, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}

.wizard-card[data-color="wizard-warning"] .moving-tab {
  background-color: #ff9800;
  box-shadow: 0 16px 26px -10px rgba(255, 152, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}

.wizard-card[data-color="wizard-info"] .moving-tab {
  background-color: #00acc1;
  box-shadow: 0 16px 26px -10px rgba(0, 172, 193, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 172, 193, 0.2);
}

.wizard-card[data-color="wizard-purple"] .moving-tab {
  background-color: #7759de;
  box-shadow: 0 16px 26px -10px rgba(119, 89, 222, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(119, 89, 222, 0.2);
}

.wizard-card .wizard-footer .checkbox {
  margin-top: 16px;
}

.wizard-card .disabled {
  display: none;
}

.nav-pills>li+li {
  margin-left: 0;
}

.nav-pills>li>a {
  border: 0;
  border-radius: 0;
  line-height: 18px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555;
  position: relative;
  display: block;
  padding: 11px 15px;
}

.nav-pills>li.active>a {
  background-color: inherit;
}

.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  background-color: inherit;
}

.nav-pills>li>a:focus,
.nav-pills>li>a:hover {
  background-color: inherit;
}

.nav-pills>li i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}

.sw-theme-default {
  box-shadow: none;
}

.sw-theme-default>ul.step-anchor>li a,
.sw-theme-default>ul.step-anchor>li a>h6,
.sw-theme-default>ul.step-anchor>li a:hover {
  color: #e2e5e8;
}

.sw-theme-default>ul.step-anchor>li.done a>h6 {
  color: initial;
}

.sw-theme-default>ul.step-anchor>li.active a,
.sw-theme-default>ul.step-anchor>li.active a h6 {
  color: #2196f3;
}

.sw-theme-default>ul.step-anchor>li>a.nav-link:after {
  background: #2196f3;
}

.sw-theme-default>ul.step-anchor>li.done>a.nav-link:after {
  background: #e2e5e8;
}

.sw-theme-default .step-anchor,
.sw-theme-default .sw-container {
  background: #fff;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
}

.sw-theme-default .step-anchor {
  margin-bottom: 30px;
}

.sw-theme-default .step-anchor li>a {
  padding: 15px 25px;
}

.sw-theme-default .sw-container .step-content {
  padding: 35px 30px;
}

.sw-theme-default .sw-toolbar {
  padding: 10px;
  background: 0 0;
}

.sw-theme-default .step-content+.sw-toolbar {
  padding-top: 0;
}

.sw-theme-arrows .sw-container,
.sw-theme-circles .sw-container,
.sw-theme-dots .sw-container {
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
}

.sw-theme-arrows .sw-container .step-content,
.sw-theme-circles .sw-container .step-content,
.sw-theme-dots .sw-container .step-content {
  padding: 35px 30px;
}

.sw-theme-arrows .sw-toolbar-bottom,
.sw-theme-circles .sw-toolbar-bottom,
.sw-theme-dots .sw-toolbar-bottom {
  padding: 10px;
}

.sw-theme-arrows .step-anchor,
.sw-theme-circles .step-anchor,
.sw-theme-dots .step-anchor {
  margin-bottom: 30px;
}

.sw-theme-dots>ul.step-anchor>li>a:after {
  left: 42%;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .sw-theme-dots>ul.step-anchor>li>a:after {
    top: -42px;
    left: -40px;
  }

  .sw-theme-arrows>ul.step-anchor>li>a {
    padding-right: 15px;
  }
}

.sw-vertical-left,
.sw-vertical-right {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {

  .sw-vertical-left.sw-main,
  .sw-vertical-right.sw-main {
    flex-direction: row;
  }

  .sw-vertical-left .step-anchor,
  .sw-vertical-right .step-anchor {
    align-self: flex-start;
    flex-direction: column;
    min-width: 200px;
  }

  .sw-vertical-left .step-anchor li,
  .sw-vertical-right .step-anchor li {
    flex-grow: 0;
  }
}

@media (max-width: 767px) {

  .sw-vertical-left.sw-main,
  .sw-vertical-right.sw-main {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .sw-vertical-left {
    flex-direction: row-reverse;
  }

  .sw-vertical-left .sw-done-icon,
  .sw-vertical-left .sw-icon,
  .sw-vertical-left .sw-number {
    left: 2rem;
  }

  .sw-vertical-left .step-anchor {
    margin-right: 30px;
  }
}

@media (min-width: 768px) {
  .sw-vertical-right {
    flex-direction: row-reverse;
  }

  .sw-vertical-right .sw-done-icon,
  .sw-vertical-right .sw-icon,
  .sw-vertical-right .sw-number {
    right: 2rem;
  }

  .sw-vertical-right .step-anchor {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .sw-vertical-right .step-anchor {
    margin: 30px 0 0;
  }
}

.nvtooltip {
  position: fixed !important;
}

.peity-chart+.peity {
  width: 100%;
  height: 250px;
}

.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  margin-bottom: 20px;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box;
}

.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
  content: attr(data-label);
  background-color: #fff;
  z-index: 55;
}

.radial-bar>img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar>img {
  z-index: 102;
}

.radial-bar.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-primary.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #2196f3 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #2196f3 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-danger.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #f44336 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #f44336 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-success.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #4caf50 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #4caf50 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-warning.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #ff9800 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #ff9800 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-info.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #00acc1 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #00acc1 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-0 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(90deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-5 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(108deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-10 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(126deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-15 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(144deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-20 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(162deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-25 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(180deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-30 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(198deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-35 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(216deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-40 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(234deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-45 {
  background-image: linear-gradient(90deg,
      #d6d6d6 50%,
      transparent 50%,
      transparent),
    linear-gradient(252deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-50 {
  background-image: linear-gradient(270deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-55 {
  background-image: linear-gradient(288deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-60 {
  background-image: linear-gradient(306deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-65 {
  background-image: linear-gradient(324deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-70 {
  background-image: linear-gradient(342deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-75 {
  background-image: linear-gradient(360deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-80 {
  background-image: linear-gradient(378deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-85 {
  background-image: linear-gradient(396deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-90 {
  background-image: linear-gradient(414deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-95 {
  background-image: linear-gradient(432deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar.radial-bar-purple.radial-bar-100 {
  background-image: linear-gradient(450deg,
      #7759de 50%,
      transparent 50%,
      transparent),
    linear-gradient(270deg, #7759de 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}

.radial-bar-lg>img,
.radial-bar-lg:after {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.radial-bar-sm {
  width: 60px;
  height: 60px;
  font-size: 12px;
}

.radial-bar-sm>img,
.radial-bar-sm:after {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  line-height: 42px;
}

.radial-bar-xs {
  width: 35px;
  height: 35px;
  font-size: 10px;
}

.radial-bar-xs>img,
.radial-bar-xs:after {
  width: 25px;
  height: 25px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 25px;
}

.i-main .i-block {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #f1f1f1;
  position: relative;
  cursor: pointer;
}

.i-main .i-block i {
  font-size: 30px;
}

.i-main .i-block label {
  margin-bottom: 0;
  display: none;
}

.i-main .i-block span.ic-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}

.i-main .i-block .flag-icon-background {
  width: 40px;
  height: 40px;
}

.external-events .fc-event {
  color: #fff;
  cursor: move;
  padding: 5px 18px;
  margin-top: 5px;
  background: #2196f3;
  border-color: #2196f3;
}

.calendar a:not([href]):not([tabindex]),
.calendar a:not([href]):not([tabindex]):focus,
.calendar a:not([href]):not([tabindex]):hover {
  color: #fff;
}

.calendar .fc-event,
.calendar .fc-event:hover {
  color: #fff;
  cursor: move;
  padding: 5px 18px;
  margin-top: 2px;
  background: #2196f3;
  border-color: #2196f3;
}

.dropzone {
  min-height: 150px;
  border: 1px solid rgba(42, 42, 42, 0.05);
  background: rgba(204, 204, 204, 0.15);
  padding: 20px;
  border-radius: 5px;
  box-shadow: inset 0 0 5px 0 rgba(43, 43, 43, 0.1);
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message {
  cursor: pointer;
}

.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 4em 0;
  color: #888;
  font-size: 18px;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0.5rem;
  min-height: 100px;
  box-shadow: 0 13px 6px -8px rgba(0, 0, 0, 0.3), 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 5px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 5px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  display: block;
  padding: 4px;
  width: 100%;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 5px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #f44336;
  background: linear-gradient(to bottom, #f44336, #f44336);
  padding: 0.5em 1.2em;
  color: #fff;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #f44336;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.hd-body .excerpt {
  padding: 15px;
  background: #eff3f6;
  border: 1px solid #e2e5e8;
}

.hd-body .ticket-customer,
.hd-body .ticket-type-icon {
  display: block;
  text-transform: capitalize;
}

.hd-body:hover .hover-blk {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sm-view .card-body .excerpt,
.sm-view .card-body ul.list-inline {
  display: none;
}

.md-view .excerpt {
  display: none;
}

.md-view .col-auto ul li:nth-child(1),
.md-view .col-auto ul li:nth-child(3),
.sm-view .col-auto ul li:nth-child(1),
.sm-view .col-auto ul li:nth-child(3) {
  display: none;
}

.hover-blk {
  background: #fff;
  width: 270px;
  left: calc(100% + 10px);
  -webkit-transform: scale(0);
  transform: scale(0);
  z-index: 5;
  transition: all 0.1s ease-out;
}

.hover-blk .img-txt p {
  display: inline-block;
}

.topic-name h1 {
  float: left;
  font-weight: 400;
}

.topic-name .btn-star {
  float: right;
}

.dicon-blk {
  top: 0;
  right: 0;
}

.dicon-blk li {
  display: inline-block;
}

.hd-detail .col-right .edit-del {
  opacity: 0;
}

.hd-detail .col-right .edit-del i {
  opacity: 0.2;
}

.hd-detail .col-right .edit-del i:hover {
  opacity: 1;
}

.hd-detail:hover .edit-del {
  opacity: 1;
}

.hdd-user i {
  bottom: 0;
}

.ticket-block .hd-body {
  border-left: 3px solid #ccc;
}

.ticket-block .col.border-right {
  border-color: #ccc !important;
}

.ticket-block .ticket-type-icon {
  font-weight: 500;
  color: #111;
}

.ticket-block .excerpt h6 {
  color: #686c71;
  font-weight: 500;
}

.ticket-block .excerpt h6 a {
  color: #686c71;
}

.ticket-block .excerpt h6 a:hover {
  text-decoration: underline !important;
  color: #111;
}

.ticket-block.open-tic .hd-body {
  border-color: #ea394a;
}

.ticket-block.close-tic .hd-body {
  border-color: #5ac17f;
}

.right-col .card-footer label {
  font-weight: 500;
}

.q-view {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1030;
  top: 0;
  right: 0;
  visibility: hidden;
}

.q-view .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.q-view .content {
  width: 585px;
  height: 100vh;
  z-index: 5;
  position: absolute;
  top: 0;
  background-color: #fff;
  padding-top: 20px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
  right: -765px;
  transition: right 0.195s ease-in, visibility 0s linear 0.195s;
  min-height: 100vh;
  overflow: auto;
}

.q-view.active {
  visibility: visible;
}

.q-view.active .content {
  right: 0;
  transition: right 0.225s ease-out;
}

.select2-container--default {
  margin-bottom: 10px;
}

.select2-container--default .select2-selection {
  border-color: #e2e5e8;
}

.hdd-right-side {
  width: 495px;
}

.hdd-right-side .hdd-right-inner {
  position: fixed;
  width: 465px;
}

.span-content a {
  padding: 0 7px;
}

.file-btn {
  position: relative;
  overflow: hidden;
}

.file-btn input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}

@media (max-width: 992px) {
  .hd-detail .col-right .edit-del {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .hdd-right-side {
    width: 100%;
  }

  .hdd-right-side .hdd-right-inner {
    position: static;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .q-view .content {
    width: 450px;
  }
}

@media (max-width: 380px) {
  .q-view .content {
    width: 300px;
  }
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

.image,
.card1 {
  width: 200px !important;
}

.card-border-c-red {
  border-top: 4px solid #f44336 !important;
}

.sw-main {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  border-radius: 0.25rem !important;
}

.sw-theme-circles>ul.step-anchor>li.active>a {
  border-color: #5bc0de;
  color: #fff;
  background: #5bc0de;
}

.sw-theme-circles>ul.step-anchor>li>a {
  border: 2px solid #f5f5f5;
  background: #f5f5f5;

  width: 45px;
  height: 47px !important;
  text-align: center;
  padding: 9px 0;
  border-radius: 50%;
  -webkit-box-shadow: inset 0px 0px 0px 3px #fff !important;
  box-shadow: inset 0px 0px 0px 3px #fff !important;
  text-decoration: none;
  outline-style: none;
  z-index: 99;
  color: #bbb;
  background: #f5f5f5;
  line-height: 1;
}

.sw-theme-circles>ul.step-anchor>li {
  border: none;
  margin-left: 40px;
  z-index: 98;
  width: 10%;
}

.sw-theme-circles>ul.step-anchor>li>a>small {
  position: relative;
  bottom: -40px;
  color: #ccc;
}

.st_active {
  text-align: -webkit-center;
}

.sw-theme-arrows {
  border-radius: 5px;
  border: 1px solid #ddd;
}

.sw-theme-arrows>.sw-container {
  min-height: 200px;
}

.sw-theme-arrows .step-content {
  padding: 0 10px;
  border: 0 solid #d4d4d4;
  background-color: #fff;
  text-align: left;
}

.sw-theme-arrows .sw-toolbar {
  padding: 10px;
  margin-bottom: 0 !important;
}

.sw-theme-arrows>ul.step-anchor {
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 0;
  background: #f5f5f5;

  border-radius: 0;
  border-top-right-radius: 5px;
  list-style: none;
  overflow: hidden;
}

.sw-theme-arrows>ul.step-anchor li+li:before {
  padding: 0;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(1) {
  width: 16%;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(2) {
  width: 16%;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(3) {
  width: 16%;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(4) {
  width: 16%;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(5) {
  width: 16%;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(6) {
  width: 20%;
}

.sw-theme-arrows>ul.step-anchor>li:nth-child(7) {
  width: auto;
}

.sw-theme-arrows>ul.step-anchor>li>a,
.sw-theme-arrows>ul.step-anchor>li>a:hover {
  color: #bbb;
  text-decoration: none;
  padding: 10px 0 10px 45px;
  position: relative;
  display: block;
  border: 0 !important;
  border-radius: 0;
  outline-style: none;
  background: #f5f5f5;
}

.sw-theme-arrows>ul.step-anchor>li>a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;

  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.sw-theme-arrows>ul.step-anchor>li>a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.sw-theme-arrows>ul.step-anchor>li:first-child>a {
  padding-left: 15px;
}

.sw-theme-arrows>ul.step-anchor>li>a:hover {
  color: #bbb;
  text-decoration: none;
  outline-style: none;
  background: #f5f5f5;

  border-color: #f5f5f5;
}

.sw-theme-arrows>ul.step-anchor>li>a:hover:after {
  border-left-color: #f5f5f5;
}

.sw-theme-arrows>ul.step-anchor>li.clickable>a:hover {
  color: #4285f4 !important;
  background: #46b8da !important;
}

.sw-theme-arrows>ul.step-anchor>li.active>a {
  border-color: #5cb85c !important;
  color: #fff !important;
  background: #5cb85c !important;
  height: 65px !important;
  display: table !important;
  width: 100% !important;
}

.sw-theme-arrows>ul.step-anchor>li.active>a:after {
  border-left: 30px solid #5cb85c !important;
}

.sw-theme-arrows>ul.step-anchor>li.done>a {
  border-color: #b1dfbb !important;
  color: #fff !important;
  background: #b1dfbb !important;
}

.sw-theme-arrows>ul.step-anchor>li.done>a:after {
  border-left: 30px solid #b1dfbb;
}

.sw-theme-arrows>ul.step-anchor>li.danger>a {
  border-color: #d9534f !important;
  color: #fff !important;
  background: #d9534f !important;
}

.sw-theme-arrows>ul.step-anchor>li.danger>a:after {
  border-left: 30px solid #d9534f !important;
}

.sw-theme-arrows>ul.step-anchor>li.disabled>a,
.sw-theme-arrows>ul.step-anchor>li.disabled>a:hover {
  color: #eee !important;
}

@media screen and (max-width: 768px) {
  .sw-theme-arrows>ul.step-anchor {
    border: 0;
    background: #ddd !important;
  }

  .sw-theme-arrows>.nav-tabs>li {
    float: none !important;
    margin-bottom: 0;
  }

  .sw-theme-arrows>ul.step-anchor>li>a,
  .sw-theme-arrows>ul.step-anchor>li>a:hover {
    padding-left: 15px;
    margin-right: 0;
    margin-bottom: 1px;
  }

  .sw-theme-arrows>ul.step-anchor>li>a:after,
  .sw-theme-arrows>ul.step-anchor>li>a:before {
    display: none;
  }
}

.sw-theme-arrows::before {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #5cb85c;
}

.content {
  padding: 0 15px;
}

.main-search .dropdown-toggle {
  position: relative;
  width: 100px;
}

.main-search .pro-body {
  padding-left: 10px;
}

#simpletable_wrapper {
  display: grid;
}

#branchtable_wrapper {
  display: grid;
}

.pointer {
  cursor: pointer;
}

.nav-item a {
  text-decoration: none !important;
}

.react-pdf__Page__canvas {
  width: 500px !important;
}

.sw-theme-arrows>ul.step-anchor>li>a>h6,
.sw-theme-arrows>ul.step-anchor>li.active>a>h6 {
  vertical-align: middle !important;
  margin: 0;
  padding: 0;
  /* margin: 11px auto; */
}

/* .nav-tabs .nav-item {
  margin-bottom: -1px !important;
  width: 165px !important;
} */
.pt-20 {
  padding-top: 20px !important;
}

.spin-container {
  width: 100%;
  height: calc(100vh - 70px);
  position: absolute;
  background: #c5c5c5;
  opacity: 0.7;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgLoader {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url("../../assets/images/loader2.svg");
}

.sec_measurement {
  padding: 8px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.dropdown.bootstrap-select {
  width: 100% !important;
}

.dropdown-menu.show {
  max-height: none;
  overflow: hidden;
  min-height: 0;
  min-width: -webkit-fill-available;
  position: inherit;
  transform: none;
}

.form-document {
  max-height: 730px;
  overflow: scroll;
  margin-bottom: 16px;
}

.highlight-border {
  margin: 0 0 10px 0 !important;
  border: 2px solid #75787d;
}

.border {
  position: relative;
  padding: 5px;
  margin-right: 5px;
  border: 1px solid #ccc;
  display: inline-block;
  margin-bottom: 5px;
}

.border .btn-danger {
  position: absolute;
  top: 5px;
  right: 0;
  padding: 1px;
  width: 20px;
  height: 20px;
  line-height: normal;
  font-size: 14px;
  border-radius: 50%;
}

.border .btn-primary {
  position: absolute;
  top: 5px;
  right: 0;
  padding: 1px;
  width: 20px;
  height: 20px;
  line-height: normal;

  font-size: 14px;
  border-radius: 50%;
  margin-right: 9%;
}

.arrow {
  font-size: 50px;
  position: absolute;
  z-index: 9999;
  top: 15px;
  color: red;
}

.add_txtarea {
  background: none;
  padding: 10px;
  margin: 50px 0 0 15px;
  width: 100%;
}

.textbox {
  width: 100%;
  display: table-cell;
}

.save_btn {
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  padding: 0 10px;
}

.notify-count {
  position: absolute;
  border-radius: 50%;
  margin-top: 8px;
  margin-left: -7px;
  padding: 2px 4px;
}

.numberHighlight {
  border: 1px solid #000;
  border-radius: 50%;
  width: 35px;
  height: 25px;
  vertical-align: middle;
  text-align: center;
  font-weight: 600;
  margin-left: 38%;
}

.select2-container--open {
  z-index: 9999;
}

/* For pdf renderer */
.mgrpdf__wrapper {
  background: #525659 !important;
}

/* .card > div.collapse.show {background-color: red !important;} */
.file-status-header {
  vertical-align: middle;
  padding-top: 2%;
}

.file-search-button {
  text-align: right;
  margin-top: -3%;
}

.rtf-table thead tr {
  background-color: #b5b2b2 !important;
  color: #1d1c1c !important;
}

.rtf-table tbody tr:hover {
  color: #000000;
  background: #cacaca;
}

.rtf-table thead th:hover,
div.rft-pagination button:hover {
  background-color: #b5b2b2 !important;
  color: #fff !important;
}

div.rft-pagination button {
  border: 3px solid #b5b2b2;
  color: #b5b2b2 !important;
}

.addon-card {
  margin: 0%;
  padding: 1%;
}

.addon-accordion {
  background: grey;
  padding: inherit;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #ffc107;
}

input:focus+.slider {
  box-shadow: 0 0 1px #ffc107;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.template-select {
  width: 15em;
  float: right;
  margin: 0.1em 1em;
  z-index: 999;
  position: relative;
}

.all-document-section {
  padding: 20px;
  margin: 10px 0px;
}

/* .rft-gs-td {
	margin-left: -66em;
	width: 24em;
} */
._1OmBB th,
._1OmBB td {
  padding: 4px 8px;
}

.svg-small-icon {
  width: 22px;
  height: 22px;
}

/* Query Tree */
.query-title {
  padding-left: 4px;
  padding-top: 2px;

  font-size: 20px;
  line-height: 2;
}

.query-child {
  padding-left: 34px;
}

.query-child-wrapper {
  padding-left: 30px;
  position: relative;
}

.query-wrapper {
  padding: 8px 0px;
  position: relative;
}

.query-wrapper::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 28px;
  width: 24px;
  height: 100%;
  border-left: 2px dotted gray;
  border-top: 2px dotted gray;
}

.query-child-wrapper-open::before {
  content: "";
  position: absolute;
  left: 40px;
  top: 30px;
  height: 100%;
  border-left: 2px dotted gray;
}

.query-wrapper:last-child::before {
  border-left: 0px;
}

.query-text {
  padding: 0px 4px;
  margin: 0;
  font-size: 20px;
  line-height: 2;
}

.query-text:first-child {
  padding-left: 0px;
}

.dialog-modal {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.dialog-modal-body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: max-content;
  right: 0;
  margin: auto;
  max-width: 500px;
}

.query-folder-icon {
  margin-top: 10px;
}

#preview-document.open {
  display: block;
  background-color: #00000080;
}

.query-document {
  margin-top: 50px;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.query-upload {
  margin: 0px;
}

.query-table-row:hover {
  color: #000000 !important;
  background: #cacaca !important;
}

/* Policy */
.policy-input {
  width: 50% !important;
  margin-right: 20px;
}

.policy-input-wrapper {
  margin: 20px 0px;
}

.policymain-container {
  padding-top: 88px;
  margin-left: 264px;
}

.add-policy-button {
  margin-left: calc(50% + 20px);
}

.policy-button button {
  border-radius: 50%;
}

.policy-back-button {
  margin: 0px;
  padding: 0px !important;
}

.policy-header {
  padding: 12px 25px !important;
}

.policymain-container .rft-gs-td {
  margin-left: 0px;
}

.bank-select {
  margin-left: 20px;
  width: 50% !important;
}

.bank-policy-wrapper .rft-pagination {
  display: none;
}

.bank-policy-wrapper .rft-table-collapse {
  max-height: 500px;
  margin: 20px 0px;
}

.view-policy .rft-pagination {
  display: none !important;
}

.table-header th {
  background-color: #b5b2b2 !important;
  color: #1d1c1c !important;
  text-align: center;
  padding: 8px 0px;
}

.table-row {
  border-bottom: 1px solid #dddddd;
}

.table-row.even-row {
  background-color: #f3f3f3;
}

.table-row td {
  text-align: center;
  padding: 8px 0px;
}

.policy-table-header {
  width: 100%;
}

.table-wrapper {
  max-height: 221px;
  overflow-y: auto;
  margin-top: 24px;
}

.checkboxOutline {
  fill: gray;
  border-radius: 4px;
}

.checkboxOutline.check-selected {
  fill: #2196f3;
  background: #2196f3;
}

.checkboxOutline.cross-selected {
  fill: #f44336;
  background: #f44336;
}

.checkbox-status {
  position: absolute;
  fill: white;
  stroke-width: 1.5px;
  stroke: white;
}

.cursor-pointer {
  cursor: pointer;
}

.display-flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.text-align-center {
  text-align: center;
}

.document-copy-icon {
  margin-right: 0;
}

.document-content {
  display: flex;
  justify-content: space-between;
}

.copy-document-form {
  width: 45% !important;
}

.table-container th {
  padding: 12px;
  font-weight: bold;
  background-color: #b5b2b2;
}

.table-container td {
  padding: 4px 12px;
  max-width: 150px;
  font-size: 12px;
}

.index-table-cell {
  text-align: center !important;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}

.select-container {
  height: 32px;
  margin: 0px 8px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.upload-document-folder {
  display: flex;
  width: 100%;
}

.upload-document-filereader {
  display: flex;
  margin-top: auto;
  max-width: fit-content !important;
}

.upload-document-filereader .react-file-reader {
  width: 100%;
  height: 38px;
}

.upload-document-filereader button {
  margin-bottom: 0px;
  margin-right: 0px;
}

.upload-document-folder .deleteIcon {
  margin-top: auto !important;
}

.upload-document-folder .deleteIcon a {
  font-size: 24px;
  cursor: pointer;
}

.upload-document-wrapper {
  padding: 0px 8px 0px;
  margin: -5px 0px 0px !important;
}

.upload-document-button {
  margin: 0px 0px 0px;
}

.green {
  background-color: green !important;
}

.red {
  background-color: red !important;
}

.right-container {
  padding-top: 88px;
  margin-left: 264px;
}

.video-category-conatiner {
  margin: 24px;
  background-color: #ffffff;
}

.video-category-wrapper {
  padding: 24px 24px 8px;
  border-top: 1px solid #eeeeee;
}

.video-category {
  padding: 12px 24px !important;
  margin: 0px;
}

.video-category-conatiner td,
.video-category-conatiner th {
  text-align: center !important;
}

.video-action-button {
  margin: 0px !important;
}

.video-category-conatiner .table-container td {
  padding: 8px 12px;
}

.preview-container {
  width: 50%;
}

.file-not-available .react-file-reader {
  height: 38px;
}

.file-available .react-file-reader {
  height: "auto";
}

.video-select {
  margin-left: 20px;
  width: 50% !important;
}

.video-select div {
  z-index: 10;
}

.manage-video-wrapper {
  margin-bottom: 24px;
}

.add-video-modal {
  max-height: 80%;
  overflow-y: auto;
  background-color: white;
}

.pdf-icon {
  width: 32px !important;
  height: 32px !important;
  margin-right: 8px !important;
  fill: #115293 !important;
}

.rotate-icon {
  transform: rotate3d(-8, 40, 0, 180deg) !important;
}

.tools {
  position: absolute;
  z-index: 1;
}

#frame {
  overflow-x: hidden !important;
}

.react-transform-component {
  overflow: scroll !important;
}

/* query css */
.query-card .query-document {
  margin-top: 15px !important;
}

.query-card .query-document .card .card-body {
  padding: 0px 5px;
}

/* Q & A manage css */
#qa-manage .card-header .btn {
  float: right !important;
}

#qa-manage .card .card-body .qa-sec .qa {
  padding-bottom: 15px;
}

#qa-manage .card .card-body .qa-sec .qa .sno {
  max-width: 40px !important;
  padding: 0 0 0 15px !important;
}

#qa-manage .card-body .table-responsive {
  min-height: 75vh;
}

#qa-manage .card .card-body .fa-delete,
#qa-manage .card .card-body .fa-add {
  margin: auto;
}

#qa-manage .card .card-body .fa-trash,
#qa-manage .card .card-body .fa-plus-circle {
  font-size: 20px;
  cursor: pointer;
}

/* work file QA css*/
#work-file-qa .card .save-card {
  float: right;
  margin-top: 10px;
}

/* new dashboard css*/
.admin-dashboard .m-b-15 {
  margin-bottom: 15px !important;
}

.admin-dashboard .apexcharts-legend {
  display: none !important;
}

.admin-dashboard .card-body .ant-input {
  height: 2.4rem;
}

.admin-dashboard .card .card-body .login {
  background: #2e93fa !important;
}

.admin-dashboard .card .card-body .query {
  background: #66da26 !important;
}

.admin-dashboard .card .card-body .last-month {
  background: #546e7a !important;
}

.admin-dashboard .card .card-body #bank-files-table thead tr th {
  color: #686c71 !important;
  background: #fff !important;
  border-top: 0px !important;
}

.admin-dashboard .card .card-body #bank-files-table tbody tr th {
  font-weight: 400 !important;
}

.admin-dashboard .card .card-body .pos {
  color: greenyellow;
}

.admin-dashboard .card .card-body .neg {
  color: red;
}

/* spinner css */
.spinner-border {
  height: 1.3rem !important;
  width: 1.3rem !important;
  border: 0.16em solid currentColor !important;
  border-right-color: transparent !important;
}

/* work file edit css */
#work-file-details .mt-10 {
  margin-top: 10px;
}

#work-file-details .card-header .btn-primary {
  float: right !important;
}

#work-file-details .card-header .download {
  margin-left: 30%;
}

/* complaint box */
.complaint-box .status {
  border: 1px solid;
  border-radius: 15px;
  padding: 5px 10px;
}

.complaint-box .pending {
  border-color: red;
  background: red;
  color: #fff;
}

.complaint-box .working {
  border-color: orange;
  background: orange;
  color: #fff;
}

.complaint-box .completed {
  border-color: green;
  background: green;
  color: #fff;
}

.complaint-box .mb-10 {
  margin-bottom: 10px;
}

.complaint-box .rtf-table tbody td {
  padding: 0.75rem !important;
  font-size: 14px;
  font-weight: bold;
}

.complaint-box .rtf-table thead th {
  padding: 0.75rem !important;
  font-size: 13px;
}

/* verification list*/
.sop .collapse-section {
  margin-top: 15px;
}

.sop .collapse-section .card {
  border: 0px solid transparent !important;
  border: none;
}

.sop .collapse-section .card .card-header {
  padding: 10px 15px;
  background: #e3e3e3;
}

.sop .collapse-section .label {
  color: #333b4b;
  font-size: 12px;
  font-weight: 600;
}

.sop .collapse-section .justify-evenly {
  justify-content: space-evenly;
}

.sop .collapse-section .mb-10 {
  margin-bottom: 10px;
}

/* Hide arrow in number input */
/* Chrome, Safari, Edge, Opera */
.hide-number-arrow::-webkit-outer-spin-button,
.hide-number-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-number-arrow[type=number] {
  -moz-appearance: textfield;
}

.sop .card-header .btn-primary {
  float: right !important;
}

.sop .card-header .download {
  margin-left: 30%;
}

.raise-query-style .fa-icon {
  font-size: 20px;
}

/* verification eod*/
#verification-modal .ml-10 {
  margin-left: 10px;
}

/* reason modal*/
#reason-modal .mt-10 {
  margin-top: 10px;
}

.count-doc-verify .label,
.deed-section .label,
.ec-verify .label,
.general-verification .label,
.legal-heris-verify .label,
.missing-lod .label,
.survey-no-verify .label,
.verification-section .label {
  margin-bottom: 5px;
}

.general-verification .col-md-3,
.general-verification .col-md-6 {
  padding-right: 0;
  padding-left: 0;
}

.general-verification .col-md-3:nth-child(1),
.general-verification .col-md-3:nth-child(5) {
  padding-left: 15px;
}

.verify-container .accordion .card .card-header {
  background-color: #ffff8d;
}

.no-left-padding {
  padding-left: 0px !important;
}

.no-right-padding {
  padding-right: 0px !important;
}

table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc {
  background-image: none !important;
}

.toasts-container {
  display: block;
}

/* Flowchart */
.flowchart-tabs .tab-content {
  padding: 0px
}

.sales-deed .react-resizable-handle-e {
  right: -25px !important;
}

.toasts-container {
  /* transform: translateX(-50%); */
  height: 50px;
  position: fixed;
  overflow: hidden;
  z-index: 999999999999;
  max-height: calc(100vh - 10px);
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.toast {
  font-family: 'Arial';
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px 15px;
  white-space: pre-line;
  min-height: 50px;
  margin-bottom: 15px;
  border-radius: 5px;
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.noBoxShadow {
  box-shadow: none !important;
}

.textUnderline {
  text-decoration: underline;
}

.padding4 {
  padding: 4px !important;
  border: 1px solid #0c0c0c !important;
}

.brdrClr {
  border: 1px solid #0c0c0c !important;
}

.style-sidebar{
  overflow-y: scroll;
}
.style-sidebar::-webkit-scrollbar {
  display: none;
}


.sticky-table-container {
  position: relative;
  overflow: auto;
  height: 480px; /* Set the desired height for the table container */
}

.sticky-table-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Set the background color according to your design */
  z-index: 1;
}