.first-div {
  margin: 0px !important;
  padding: 0px !important;
  height: 100vh; /* 100% of the viewport height */
  /* display: flex !important;
    justify-content: center !important;
    place-items: center !important; */
}
.form-control::placeholder {
  font-size: 13px;
  color: #888;
  font-weight: bolder;
}

.background-trans {
  background: transparent !important;
}
.tets {
  height: 100vh !important;
}

.self-center {
  align-self: center !important;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    #46ec3f,
    #43d836,
    #33b60f,
    #0cad09
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #46ec3f, #43d836, #33b60f, #0cad09);
}

.form-align {
  margin-left: 185px;
}
.coll {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.p {
  margin-bottom: 0%;
}

.btn-login {
  background-color: transparent;
  color: white;
  width: "60px";
  height: "37px";
}

@media (max-width: 772px) {
  .form-align {
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}

.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      hsl(112, 41%, 35%) 15%,
      hsl(132, 41%, 30%) 35%,
      hsl(136, 41%, 20%) 75%,
      hsl(137, 40%, 19%) 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      hsl(129, 41%, 45%) 15%,
      hsl(136, 41%, 30%) 35%,
      hsl(144, 41%, 20%) 75%,
      hsl(132, 40%, 19%) 80%,
      transparent 100%
    );
}

/* #radius-shape-1 {
    height: 220px;
    width: 220px;
    top: -60px;
    left: -130px;
    background: radial-gradient(#006b0e, #1fff53);
    overflow: hidden;
  }
  
  #radius-shape-2 {
    border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
    bottom: -60px;
    right: -110px;
    width: 300px;
    height: 300px;
    background: radial-gradient(#006b0e, #1fff53);
    overflow: hidden;
  } */

.bg-glass {
  /* background-color: hsla(0, 0%, 100%, 0.9) !important; */
  background-color: white !important;
  backdrop-filter: saturate(200%) blur(25px);
}
@media only screen and (max-width: 767px) {
  .first-div {
    padding: 20px;
  }

  .col {
    width: 100%;
  }

  .bg-blue {
    background-color: #fff;
  }

  .img-fluid {
    width: 100%;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }

  .CardBody {
    margin-top: 1px;
  }

  .form-align {
    margin-top: 20px;
  }

  .col-md-4 {
    width: 100%;
    margin-bottom: 15px;
  }

  .eye-icon {
    right: 5px;
  }

  .btn-login {
    width: 100%;
  }
}
