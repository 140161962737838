.page-item.active .page-link {
    background-color: green !important;
    border-color: green !important;
}

.pagination-container {
    position: relative;
  }
  
  .pagination-controls {
    position: absolute;
    right: 0;
  }
  
  .go-to-page-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .pagination-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }