.card-wrapper {
  border-radius: 20px !important;
}

#grid-container {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  margin-top: 20px;
}

#grid-date {
  background-color: #AAECC6;
  border: 1px solid black; 
  box-sizing: border-box;
  height: 320px;
  width: 26%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}

#grid-date div {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}

#grid-column-1 {
  display: flex; 
  flex-direction: column; 
  width: 26%;
}

#grid-column-1 > div {
  background-color: #AAECC6;
  width: 293px;
  height: 40px;
  border: 1px solid black; 
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}

/* #grid-column-2 {
  display: grid;
  width: 100%;
  margin-top: 15px;
  grid-template-rows: repeat(9, 1fr);
}

#grid-column-2 > div {
  width: 400px;
  height: 40px;
  border: 1px solid black; 
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
} */

#grid-column-2 {
  display: flex;
}

.user-column {
  flex: 0 0 auto;
  width: 400px;
  height: 40px;
  border: 1px solid black;
  box-sizing: border-box;
}

.user-column div {
  width: 400px;
  height: 40px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}





