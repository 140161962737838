.main {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f7f9;
  color: #12344d;
  display: flex;
}

.container {
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
  border: 1px solid #e6e6e6;
  border-image: initial;
  border-radius: 11px;

}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  /* width: 85%; */
  height: 46px;
  margin-left: 0%;
}

.text-center bg-blue p-4 {
  /* background-color: #3a5bd6 !important; */
}

.bg-blue h1,
.bg-blue p {
  color: #fff !important;
}

.mt-5,
.my-5 {
  margin-top: 22rem !important;
}

.h1,
h1 {
  font-size: 1.625rem !important;
}

p {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  opacity: .8;
}

p {
  line-height: 1.6em;
  margin: 0 0 15px;
  font-weight: 400;
  font-size: 15px;
}

.bg-blue {
  /* background-color: #3d8130 !important; */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.label {
  margin-right: 68% !important;
}

.form-group {
  margin-bottom: 1.5rem;
}

.text-cta {
  font-size: 16px;
  border: 1px solid #fff;
  color: #6473e9;
  display: inline-block;
  font-weight: 400;
}

.cta-fullwidth {
  padding: 1rem 1.4rem 0.9rem;
  width: 85%;
}

.cta {
  letter-spacing: 0;
  font-size: 14px;
  background-color: #4f7f3b !important;
  border: 2px solid #4f7f3b !important;
  color: #fff !important;
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
  height: unset;
}

.letwords {
  margin-top: 14px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.h3,
h3 {
  font-size: 1.0625rem !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: .5px !important;
  color: #000 !important;
  display: block !important;
}

.gmailaccount-wrap button,
.ms-wrap button {
  background-color: #fff;
  padding: 9px 20px;
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  align-items: center;
  border-color: #5e72e4;
  border-width: 2px;
  margin-right: 20px;
  box-shadow: none !important;
  border-radius: 7px;
}

.btn i:not(:last-child),
.btn svg:not(:last-child) {
  margin-right: 0.5rem;
}

.gmailaccount-wrap button span,
.ms-wrap button span {
  margin-left: 5px;
}

.btn-secondary,
.btn-secondary:hover {
  color: #212529;
  background-color: #f7fafc;
  border-color: #f7fafc;
}

.text-cta,
small {
  font-weight: 600;
}

.text-cta {
  font-size: 16px;
  border: 1px solid #fff;
  color: #6473e9;
  display: inline-block;

}

a {
  color: #2f26d0;
}

a {
  color: #5e72e4;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a {
  color: #1675e0;
  color: var(--rs-text-link);
  text-decoration: none;
}

a {
  background-color: initial;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary {
  background-color: #365fed;
  border-color: #365fed;
}

.cta {
  letter-spacing: 0;
  font-size: 14px;
  background-color: #4f7f3b !important;
  border: 2px solid #4f7f3b !important;
  color: #fff !important;
  font-weight: 400;
  display: inline-block;
  border-radius: 4px;
  height: unset;
}

.btn {
  position: relative;
  text-transform: none;
  transition: all .15s ease;
  letter-spacing: .025em;
  font-size: .875rem;
  will-change: transform;
}

.btn-primary,
.btn-primary:hover {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-primary {
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}

.btn {
  margin-top: 2%;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  border-radius: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
  border-width: 0;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
  border-radius: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.input-group-alternative {
  border: 1px solid #dce1eb !important;
  box-shadow: none;
  background-color: #fff;
  border-radius: 3px;
}

.input-group-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .02);
  border: 0;
  transition: box-shadow .15s ease;
}

.input-group,
.input-group .form-control {
  box-shadow: none;
}

.input-group {
  border-radius: 0.375rem;
  transition: all .15s ease;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.forget {
  margin-left: 0%;
  float: left;
}

.input-group-text:focus {
  outline: none;
}

.maillogo {
  padding-left: 8px;
  padding-top: 5px;
}

.input-group-alternative .form-control {
  height: auto;
  font-size: 1rem;
}

.input-group-alternative .form-control:focus {
  box-shadow: none;
}

input:invalid~.invalid-feedback {
  display: block;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.8rem;
  display: none;
}

.fontawesome {
  margin: 0% 5% -14px !important;
  width: 16px !important;
}

.inputclass {
  padding-left: 72px !important;
  margin-top: -21px !important;
  display: flex !important;
  /* width: 181% !important; */
  height: 47px !important;
}

.inputclassone {
  margin-left: 18% !important;
  margin-top: -21px !important;
  display: flex !important;
  /* width: 193% !important; */
  height: 47px !important;
}

.image {
  width: 200px;
  height: 129px;
  margin-left: 15px;
  margin-top: -40px;
}

form {
  text-align: left;
}






/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Card styles */
.p-4 {
  padding: 20px;
}

.pl-6 {
  padding-left: 30px;
}

.pr-6 {
  padding-right: 30px;
}

.login-card-body {
  max-width: 519px;
  margin: 0 auto;
  padding: 75px 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Heading styles */
.login-heading {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  color:  #3d8130 !important;
  color: #3d8130 !important;
}


.fontawesome {
  margin-right: 10px;
}



/* Error styles */
.text-danger {
  color: #e74c3c;
  font-size: 14px;
}

/* Button styles */
.cta-fullwidth {
  width: 100%;
  margin-top: 20px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .login-card-body {
      max-width: 90%;
      padding: 20px;
  }

  .pl-6,
  .pr-6 {
      padding-left: 20px;
      padding-right: 20px;
  }

  .login-heading {
      font-size: 24px;
  }
}

@media (max-width: 576px) {
  .login-heading {
      font-size: 20px;
  }
}

.contantform {
  background-color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
}